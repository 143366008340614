import React, { Fragment } from 'react';
import { getPdfComprobanteVenta } from '../../../Services/reporteService';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList } from '@fortawesome/free-solid-svg-icons'

// service
import { getAllVentasMediospagosByIdVenta } from '../../../Services/ventaMedioPagoService';


// router
import { withRouter } from 'react-router-dom';
import ItemDetalleVenta from './ItemDetalleVenta';

import Swal from 'sweetalert2';

const handleImprimir = async (data) => {
    try {
        let itemsMedioPago = [];
        const medioPagos = (await getAllVentasMediospagosByIdVenta(data.idVenta)).data;
        itemsMedioPago = medioPagos.map(item => {
            return {
                articulo: {
                    codigo: '-',
                    nombre: `${item.medioPago.nombre} - DESCUENTO`
                },
                precio: item.descuento,
                cantidad: 1,
            }
        });
        let itemPdf = [...data.items, ...itemsMedioPago];
        let dataPdf = {
            ...data,
            items: itemPdf
        }
        const res =  await getPdfComprobanteVenta(dataPdf);
        const contenido = encodeURI(res);
        let pdfWindow = window.open("");    
        pdfWindow.document.write(
            `<iframe width='100%' height='100%' margin='0px' padding='0px' src='data:application/pdf;base64,${contenido}'>
            </iframe>`
        );        
    } catch (error) {
        Swal.fire({
            type: 'error',
            title: 'Error',
            text: 'Hubo un error vuelve a intentar!'
        });
    }
}



const ModalDetalleVenta = ({item}) => {

    const estado = (estado) => {
        if (estado === 'PAGADA') return <p className="text-success">PAGADA</p>;
        if (estado === 'PAGADA_PARCIALMENTE') return <p className="text-warning">PAGADA PARCIALMENTE</p>;
        if (estado === 'ADEUDADA') return <p className="text-primary">ADEUDADA</p>;
    }
 
    return (
        <Fragment> 

            <button type="button" className="btn text-info"
                data-toggle="modal" data-target={"#modalVentas"+item.idVenta}>
                <FontAwesomeIcon icon={faList}/> Detalle
            </button>

            <div className="modal fade" id={"modalVentas"+item.idVenta} tabIndex="-1" role="dialog" aria-labelledby="modalVentasTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <h5 className="modal-title" id="modalVentasTitle"><b>Detalle Venta</b></h5>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <span className="col-md"><b>Fecha:</b></span>
                                <span className="col-md"><b>Total:</b></span>
                                <span className="col-md"><b>Estado:</b></span>
                            </div>
                            <div className="row">
                                <span className="col-md">{item.fecha.split('T')[0]}</span>
                                <span className="col-md">${(item.total).toFixed(2)}</span>
                                <span className="col-md">{estado(item.estado)}</span>
                            </div>
                            <table className="table table-striped mt-3 text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Articulo</th>
                                        <th scope="col">Cantidad</th>
                                        <th scope="col">Precio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {item.items.map((item, index) => (
                                        
                                        <ItemDetalleVenta
                                            key={index}
                                            item = {item}
                                            index = {index}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                        <button type="button" className="btn btn-success" onClick={() => handleImprimir(item) } >Imprimir</button>
                            <button type="button" className="btn btn-primary" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    );

}

export default withRouter(ModalDetalleVenta);