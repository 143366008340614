import React, { Fragment, useEffect, useState } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'


// services
import { getAllClientesPagination } from '../../../Services/clienteService';

// router
import { withRouter } from 'react-router-dom';

// components
import ItemClienteModalVenta from './ItemClienteModalVenta';

// sweet alert
import Swal from 'sweetalert2';

const ClientesModalVenta = ({ setCliente, setTipoComprobante, setListaPrecio }) => {

    const [clientes, setClientes] = useState([]);
    const [isLoad, setIsLoad] = useState(true);

    // pagination
    const [page, setPage] = useState(0);
    const [totalRegister, setTotalRegister] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [query, setQuery] = useState("");


    useEffect(() => {
        if (isLoad) {
            let data = { query }
            getAllClientesPagination(0, data)
                .then(res => {
                    setClientes(res.data.content);
                    setTotalRegister(res.data.totalElements);
                    setTotalPage(res.data.totalPages);
                    setIsLoad(false);
                })
                .catch(err => console.log('error'));
            setIsLoad(false);
        } else {
            setIsLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);

    const nextPage = () => {
        if (page === totalPage -1) {
            Swal.fire({
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000
            })
            return;
        }
        let data = { query }
        getAllClientesPagination(page + 1, data)
            .then(res => {
                setPage(page + 1);
                setClientes(res.data.content);
                setTotalRegister(res.data.totalElements);
                setTotalPage(res.data.totalPages);
                setIsLoad(false);
            })
            .catch(err => console.log('error'));


    }

    const previousPage = () => {
        if (page === 0) {
            Swal.fire({
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000
            })
            return;
        }
        let data = { query }
        getAllClientesPagination(page - 1, data)
            .then(res => {
                setPage(page - 1);
                setClientes(res.data.content);
                setTotalRegister(res.data.totalElements);
                setTotalPage(res.data.totalPages);
                setIsLoad(false);
            })
            .catch(err => console.log('error'));

    }

    const handleBuscador = (e) => {
        setQuery(e.target.value);
        if (e.key === 'Enter') {
            // verificar si la query isEmpty hacer otra consulta
            let data = { query: e.target.value }
            getAllClientesPagination(0, data)
                .then(res => {
                    setPage(0);
                    setClientes(res.data.content);
                    setTotalRegister(res.data.totalElements);
                    setTotalPage(res.data.totalPages);
                    setIsLoad(false);
                })
                .catch(err => console.log('error'));

        }
    }


    return (
        <Fragment>

            <button type="button" className="input-group-text" id="inputGroupPrepend"
                data-toggle="modal" data-target="#exampleModalScrollableCliente">
                <FontAwesomeIcon icon={faSearch} />
            </button>

            <div className="modal fade" id="exampleModalScrollableCliente" tabIndex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalScrollableTitle">Clientes</h5>
                        </div>
                        <div className="modal-body">

                            <div className="d-flex bd-highlight">

                                <div className="p-2 flex-grow-1 bd-highlight">
                                    <div className="input-group-prepend input-group-lg">

                                        <input type="text"
                                            className="form-control form-lg"
                                            id="inputNombre"
                                            name="precio"
                                            placeholder="Buscar"
                                            onKeyPress={(e) => handleBuscador(e)}
                                        />
                                        <span className="input-group-text" id="inputGroupPrepend">
                                            <FontAwesomeIcon icon={faSearch} />
                                        </span>
                                    </div>
                                </div>
                               
                            </div>


                            <div className="d-flex flex-row bd-highlight">
                                <div className="p-2 bd-highlight">
                                    <button className="btn btn-outline-info"
                                        onClick={() => previousPage()} aria-label="Previous">
                                        <span aria-hidden="true">&laquo; Anterior</span>
                                    </button>
                                </div>
                                <div className="p-2 bd-highlight mt-1">
                                    Registros: {totalRegister} | Pagina actual: {page + 1} | Total páginas : {totalPage}
                                </div>
                                <div className="p-2 bd-highlight">
                                    <button className="btn btn-outline-info"
                                        onClick={() => nextPage()} aria-label="Next">
                                        <span aria-hidden="true">Siguiente &raquo;</span>
                                    </button>
                                </div>
                            </div>

                            <table className="table table-striped mt-3 text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">DNI</th>
                                        <th scope="col">Condicion Fiscal</th>
                                        <th scope="col">Seleccionar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clientes.map((cliente, index) => (
                                        <ItemClienteModalVenta
                                            key={parseInt(cliente.idCliente.persona.idPersona, 10)}
                                            setTipoComprobante={setTipoComprobante}
                                            setListaPrecio={setListaPrecio}
                                            setCliente={setCliente}
                                            cliente={cliente} />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    );

}

export default withRouter(ClientesModalVenta);