import React, { Fragment, useRef } from 'react';

// router
import { withRouter } from 'react-router-dom';

// redux
import { useDispatch } from 'react-redux';

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faMapMarkedAlt, faPhoneAlt, faEdit } from '@fortawesome/free-solid-svg-icons'

// services
import { editByIdSucursal } from '../../Services/sucursalService';

// actions
import { EditaSucursal } from '../../Actions/SucursalAction';

const EditSucursal = ({ history, location }) => {

    const nombreRef = useRef('');
    const telefonoRef = useRef('');
    const direccionRef = useRef('');

    const sucursal = location.state.sucursal;
    const dispatch = useDispatch();

    // useEffect(() => {
    //     if (isLoad) {
    //         getAllEmpleados()
    //             .then(res => {
    //                 dispatch(GetEmpleados(res));
    //                 setPropietario(sucursal.propietario);
    //                 setActivo(sucursal.activo);
    //                 setIsLoad(false);
    //             })
    //             .catch(error => console.log(error.response));
    //     } else {
    //         setIsLoad(false);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isLoad]);

    const editSucursalSubmit = (e) => {
        e.preventDefault();
        // validaciones

        // formamos la sucursal editada
        let sucursalUpdated = {
            idSucursal: sucursal.idSucursal,
            nombre: nombreRef.current.value,
            telefono: telefonoRef.current.value,
            direccion: direccionRef.current.value
        }

        editByIdSucursal(sucursalUpdated)
            .then(res => {
                dispatch(EditaSucursal(res));
                history.goBack();
            })
            .catch(error => console.log(error.response.data));

    }

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faBuilding}
                        title={'Sucursales'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={editSucursalSubmit}>
                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputNombre">Nombre</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faBuilding} />
                                    </span>
                                    <input type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        className="form-control"
                                        placeholder="Ej. Lacteos, Gaseosas..."
                                        ref={nombreRef}
                                        defaultValue={sucursal.nombre} />
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputNombre">Direccion</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faMapMarkedAlt} />
                                    </span>
                                    <input type="text"
                                        id="inputNombre"
                                        name="direccion"
                                        className="form-control"
                                        placeholder="Ej. Piedras 2120..."
                                        ref={direccionRef}
                                        defaultValue={sucursal.direccion} />
                                </div>
                            </div>

                        </div>

                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputCodigo">Telefono</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faPhoneAlt} />
                                    </span>
                                    <input type="text"
                                        className="form-control"
                                        id="inputCodigo"
                                        name="telefono"
                                        placeholder="Ej. 4324101"
                                        ref={telefonoRef}
                                        defaultValue={sucursal.telefono} />
                                </div>
                            </div>
                        </div>



                        <div className="text-center">
                            <button type="submit" className="btn btn-outline-danger">
                                <FontAwesomeIcon icon={faEdit} /> Editar Sucursal</button>
                        </div>


                    </form>
                </div>



            </div>
        </Fragment>
    );
};

export default withRouter(EditSucursal);