import axios from 'axios';
import {url} from './global';


// PAGINATION
export const getAllCuentasCorrientesPage = async (page, data) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.post(`${url}/cuentas-corrientes-proveedores/page/${page}`, data,{headers});
    return res;
}
// END PAGINATION

export const saveCuentasCorriente = async (cuenta) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/cuentas-corrientes-proveedores`,cuenta,{headers});
    return res;
}

export const editByIdCuentasCorriente = async (cuenta) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/cuentas-corrientes-p/${cuenta.idCuentaCorriente}`, cuenta,{headers});
    return res;
}

// SAVE PAGOS
export const savePago = async (pago) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/cuentas-corrientes-p-pago`,pago,{headers});
    return res;
}

// GET PAGOS BY COMPRA
export const getPagosByCompra = async (compra) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/pagos-compra/${compra}`,{headers});
    return res;
}