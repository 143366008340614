import {
    GET_UMEDIDAS, GET_UMEDIDA, ADD_UMEDIDA, EDIT_UMEDIDA, DELETE_UMEDIDA
} from './Types';

export const GetUnidadMedidas = (res) => {
    return{
        type: GET_UMEDIDAS,
        payload: res.data
    }
}

export const AddUnidadMedidas =  (res) => {
    return {
        type: ADD_UMEDIDA,
        payload: res.data
    }
}

export const EditaUnidadMedida = (res) => {

    return {
        type: EDIT_UMEDIDA,
        payload: res.data
    }
}


export const GetUnidadMedida = (res) => {
    return {
        type: GET_UMEDIDA,
        payload: res.data
    }
} 

export const DeleteUnidadMedida = (id) => {
    return {
        type: DELETE_UMEDIDA,
        payload: id
    }
}