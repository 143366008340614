import React from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

// routes
import { withRouter } from 'react-router-dom';

// services
//import { deleteByIdDepartamentos } from '../../Services/departamentoService';

// Sweet alert
//import Swal from 'sweetalert2';

const ItemDepartamento = ({ departamento, history, eliminarDepartamento, setIsLoad }) => {

    return (
        <tr key={parseInt(departamento.idDepartamento)}>
            <th scope="row">{departamento.idDepartamento}</th>
            <td>{departamento.nombre}</td>
            <td>{departamento.tasaIva} %</td>
            <td>
                <button className="btn text-danger"
                    onClick={() => { history.push(`/edit-departamento/${departamento.idDepartamento}`, {departamento : departamento} )}}>
                    <FontAwesomeIcon icon={faEdit} /> Editar
                </button>
            </td>
        </tr>
    );
}

export default withRouter(ItemDepartamento);