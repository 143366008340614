import axios from 'axios';
import {url} from './global';


export const getAllPedidos = async () => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/pedidos`,{headers});
    return res;
}

export const getAllPedidosBySucursal = async (sucursal, page) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/pedidos-sucursal/${sucursal.idSucursal}/${page}`,{headers});
    return res;
}

export const getByIdPedido = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/pedidos/${id}`,{headers});
    return res;
}

export const editByIdPedido = async (pedido) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/pedidos/${pedido.idPedido}`, pedido,{headers});
    return res;
}

export const deleteByIdPedido = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.delete(`${url}/pedidos/${id}`,{headers});
    return res;
}

export const savePedido = async (pedido) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/pedidos`,pedido,{headers});
    return res;
}
