import React from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

// routes
import { withRouter } from 'react-router-dom';

// Sweet alert
import Swal from 'sweetalert2';

// services
import { deleteByIdUnidadMedida } from '../../Services/unidadMedidaService';

const ItemUnidadMedida = ({ medida, history, setIsLoad, deleteUnidadMedida }) => {

    const activo = (estado) => {
        if (estado === true) return <p className="text-success">Activo</p>;
        else return <p className="text-primary">No Activo</p>;
    }

    
    const btnEliminarUnidadMedida = (id) => {
        Swal.fire({
            title: 'Estas seguro de eliminar?',
            text: "Una medida eliminada no se puede recuperar!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Eliminar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                // consulta a la api para eliminar
                deleteByIdUnidadMedida(id)
                    .then(res => {
                        Swal.fire(
                            'Eliminado!',
                            'La medida fue eliminada.',
                            'success'
                        );
                        // INVOCAMOS EL ACTION
                        deleteUnidadMedida(id);
                        setIsLoad(true);
                    })
                    .catch(error => {
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Hubo un error vuelve a intentar!'
                        });
                        console.log(error.response);
                    })
            }
        })
    }

    return (
        <tr>
            <th scope="row">{medida.idUnidadMedida}</th>
            <td>{medida.nombre}</td>
            <td>{medida.abreviacion}</td>
            <td>{activo(medida.activo)}</td>
            <td>
                <button className="btn text-danger"
                    onClick={() => { history.push(`/edit-unidad-medida/${medida.idUnidadMedida}`) }}>
                    <FontAwesomeIcon icon={faEdit} /> Editar
                </button>
                <button className="btn text-primary"
                    onClick={() => { btnEliminarUnidadMedida(medida.idUnidadMedida)}}>
                    <FontAwesomeIcon icon={faTrash} /> Eliminar
                </button>
            </td>
        </tr>
    );
}

export default withRouter(ItemUnidadMedida);