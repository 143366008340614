import axios from 'axios';
import {url} from './global';


export const getAllOfertasPage = async (page, data) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.post(`${url}/ofertas/page/${page}`, data, {headers});
    return res;
}

export const getOfertaByArticulo = async (codigo) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/ofertas/articulo/${codigo}`,{headers});
    return res;
}

export const getByIdOferta = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/ofertas/${id}`,{headers});
    return res;
}

export const editByIdOferta = async (oferta) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/ofertas/${oferta.idOferta}`, oferta,{headers});
    return res;
}

export const deleteByIdOferta = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.delete(`${url}/ofertas/${id}`,{headers});
    return res;
}

export const saveOferta = async (oferta) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/ofertas`,oferta,{headers});
    return res;
}
