import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { editByIdTarjetas } from '../../Services/tarjetaService';
import { EditaTarjeta } from '../../Actions/TarjetaAction'

import { useDispatch } from 'react-redux';

import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard, faPercent, faPercentage, faWallet, faCarBattery, faPlus } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';

// formik
import { useFormik } from 'formik';
import * as Yup from 'yup';

const EditTarjeta = ({history, location}) => {

    const tarjeta = location.state.tarjeta;
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues:{
            nombre: tarjeta.nombre,
            cuotasSinInteres: tarjeta.cuotasSinInteres,
            cuotasMaximaFinanciacion: tarjeta.cuotasMaximaFinanciacion,
            tasa: tarjeta.tasa,
            recargo: tarjeta.recargo,
            descuento: tarjeta.descuento
        },
        validationSchema:Yup.object({
            nombre: Yup.string().required("Obligatorio."),
            cuotasSinInteres: Yup.number().required("Obligatorio.").min(1, "El numero debe ser positivo."),
            cuotasMaximaFinanciacion: Yup.number().required("Obligatorio.").min(1, "El numero debe ser positivo."),
            tasa: Yup.number().required("Obligatorio.").min(0, "El numero debe ser positivo."),
            recargo: Yup.number().required("Obligatorio.").min(0, "El numero debe ser positivo."),
            descuento: Yup.number().required("Obligatorio.").min(0, "El numero debe ser positivo.")
        }),
        onSubmit: values => editTarjetaSubmit(values)
    })

    const editTarjetaSubmit = (values) => {

        const tarjetaToUpdate = {
            ...values,
            idTarjeta: tarjeta.idTarjeta
        }
        editByIdTarjetas(tarjetaToUpdate)
            .then(res => {
                Swal.fire({
                    text: 'Tarjeta editada correctamente',
                    type: 'success',
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(EditaTarjeta(res));
                history.goBack();
            })
            .catch(error => {
                Swal.fire({
                    text: 'Error al modificar la tarjeta, verifique los datos',
                    type: 'error',
                    showConfirmButton: false,
                    timer: 2000
                });
                this.setState({ error: true });
            });
    }

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faCreditCard}
                        title={'Editar tarjeta'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputNombre">Nombre</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faCreditCard} />
                                    </span>
                                    <input type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        className="form-control"
                                        value={formik.values.nombre}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.nombre && formik.errors.nombre ?
                                    <small className="form-text text-danger">
                                        {formik.errors.nombre}
                                    </small>
                                    : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputTasa">Tasa</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faWallet} />
                                    </span>
                                    <input type="text"
                                        id="inputTasa"
                                        name="tasa"
                                        className="form-control"
                                        value={formik.values.tasa}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.tasa && formik.errors.tasa ?
                                    <small className="form-text text-danger">
                                        {formik.errors.tasa}
                                    </small>
                                    : null}
                            </div>

                        </div>

                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputCuotasSinInteres">Cuotas sin interes</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faPercent} />
                                    </span>
                                    <input type="text"
                                        id="inputCuotasSinInteres"
                                        name="cuotasSinInteres"
                                        className="form-control"
                                        value={formik.values.cuotasSinInteres}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.cuotasSinInteres && formik.errors.cuotasSinInteres ?
                                    <small className="form-text text-danger">
                                        {formik.errors.cuotasSinInteres}
                                    </small>
                                    : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputCuotasMaximaFinanciacion">Cuotas Maxima Financiacion</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faPercentage} />
                                    </span>
                                    <input type="text"
                                        className="form-control"
                                        id="inputCuotasMaximaFinanciacion"
                                        name="cuotasMaximaFinanciacion"
                                        value={formik.values.cuotasMaximaFinanciacion}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.cuotasMaximaFinanciacion && formik.errors.cuotasMaximaFinanciacion ?
                                    <small className="form-text text-danger">
                                        {formik.errors.cuotasMaximaFinanciacion}
                                    </small>
                                    : null}
                            </div>
                        </div>

                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputRecargoDescuento">Recargo</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faCarBattery} />
                                    </span>
                                    <input type="text"
                                        className="form-control"
                                        id="inputRecargoDescuento"
                                        name="recargo"
                                        value={formik.values.recargo}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.recargo && formik.errors.recargo ?
                                    <small className="form-text text-danger">
                                        {formik.errors.recargo}
                                    </small>
                                    : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputRecargoDescuento">Descuento</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faCarBattery} />
                                    </span>
                                    <input type="text"
                                        className="form-control"
                                        id="inputRecargoDescuento"
                                        name="descuento"
                                        value={formik.values.descuento}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.descuento && formik.errors.descuento ?
                                    <small className="form-text text-danger">
                                        {formik.errors.descuento}
                                    </small>
                                    : null}
                            </div>
                        </div>


                        <div className="text-center">
                            <button type="submit" className="btn btn-outline-success">
                                <FontAwesomeIcon icon={faPlus} /> Editar tarjeta</button>
                        </div>


                    </form>
                </div>



            </div>
        </Fragment>
    );
}

export default withRouter(EditTarjeta);