import { GET_EMPLEADOS, GET_EMPLEADO, ADD_EMPLEADO, EDIT_EMPLEADO, DELETE_EMPLEADO } from './Types';

export const GetEmpleados = (res) => {
    return{
        type: GET_EMPLEADOS,
        payload: res.data
    }
}

export const AddEmpleado =  (res) => {
    return {
        type: ADD_EMPLEADO,
        payload: res.data
    }
}

export const EditaEmpleado = (res) => {

    return {
        type: EDIT_EMPLEADO,
        payload: res.data
    }
}


export const GetEmpleado = (res) => {
    return {
        type: GET_EMPLEADO,
        payload: res.data
    }
} 

export const DeleteEmpleado = (id) => {
    return {
        type: DELETE_EMPLEADO,
        payload: id
    }
}