import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus,faTruckMoving, faPhone, faAddressBook, faDesktop } from '@fortawesome/free-solid-svg-icons'

import { AddProveedor } from '../../Actions/ProveedorAction'
import { saveProveedor } from '../../Services/proveedorService';

//formik
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Sweet alert
import Swal from 'sweetalert2';

const AddProveedores = ({ history }) => {

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            nombre: '',
            telefono: '',
            direccion: '',
            url: ''
        },
        validationSchema: Yup.object({
            nombre: Yup.string().required("Obligatorio")
        }),
        onSubmit: values => addProveedor(values)
    })

    const addProveedor = (values) => {        
        let proveedorNew = {
            ...values
        }

        saveProveedor(proveedorNew)
            .then(res => {
                dispatch(AddProveedor(res));
                history.goBack();
            })
            .catch(error => {
                Swal.fire({
                    text: "Faltan datos o algunos son incorrectos.",
                    type: 'error',
                    showConfirmButton: false,
                    timer: 1500
                });
                console.log(error);
            });
    }

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faTruckMoving}
                        title={'Proveedores'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputNombre">Nombre</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faTruckMoving} />
                                    </span>
                                    <input type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        className="form-control"
                                        placeholder="Ej. Carnes Tucumán..."
                                        value={formik.values.nombre}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.nombre && formik.errors.nombre ?
                                    <small className="form-text text-danger">
                                        {formik.errors.nombre}
                                    </small>
                                    : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputTelefono">Telefono</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faPhone} />
                                    </span>
                                    <input type="text"
                                        className="form-control"
                                        id="inputTelefono"
                                        name="telefono"
                                        placeholder="Código + número sin 15"
                                        value={formik.values.telefono}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputDireccion">Direccion</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faAddressBook} />
                                    </span>
                                    <input type="text"
                                        id="inputDireccion"
                                        name="direccion"
                                        className="form-control"
                                        placeholder="Ej. San Martín 1234"
                                        value={formik.values.direccion}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputUrl">Url</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faDesktop} />
                                    </span>
                                    <input type="text"
                                        className="form-control"
                                        id="inputUrl"
                                        name="url"
                                        placeholder="Ej. www.ejemplo.com.ar"
                                        value={formik.values.url}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="text-center">
                            <button type="submit" className="btn btn-outline-success">
                                <FontAwesomeIcon icon={faPlus} /> Crear Proveedor</button>
                        </div>

                    </form>
                </div>
            </div>
        </Fragment>
    );
}

export default withRouter(AddProveedores);