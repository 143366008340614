import { GET_CUENTAS_CORRIENTES, ADD_CUENTA_CORRIENTE, EDIT_CUENTA_CORRIENTE, GET_CUENTA_CORRIENTE, DELETE_CUENTA_CORRIENTE } from './Types';

export const GetCuentaCorrientes = (res) => {
    return ({
        type: GET_CUENTAS_CORRIENTES,
        payload: res.data
    })
}

export const AddCuentaCorriente = (res) => {
    return {
        type: ADD_CUENTA_CORRIENTE,
        payload: res.data
    }
}

export const EditarCuentaCorriente = (res) => {

    return {
        type: EDIT_CUENTA_CORRIENTE,
        payload: res.data
    }
}


export const GetCuentaCorriente = (res) => {
    return {
        type: GET_CUENTA_CORRIENTE,
        payload: res.data
    }
}

export const DeleteCuentaCorriente = (id) => {
    return {
        type: DELETE_CUENTA_CORRIENTE,
        payload: id
    }
}