import React from 'react';

// router
import { withRouter } from 'react-router-dom';

const ItemDetallePedido = ({ item, index }) => {


    return (
        <tr key={parseInt(item.articulo.idArticulo)}> 
            <th scope="row">{index + 1}</th>
            <td>{item.articulo.nombre}</td>
            <td>{item.cantidad}</td>
        </tr>
    );
}

export default withRouter(ItemDetallePedido);