import React, { Fragment, useEffect, useState } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo';
import ItemFamilia from './ItemFamilia';


// route
import { withRouter } from 'react-router-dom';

// services
import { getAllFamilias } from '../../Services/familiaService';


const Familias = ({ history }) => {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    let rol = token.authorities[0];

    const [isLoad, setIsLoad] = useState(true);
    const [familias, setFamilias] = useState([]);
    

    useEffect(() => {
        if (rol !== 'ROLE_ADMIN') history.goBack();
        if (isLoad) {
            getAllFamilias()
                .then(res => {
                    setFamilias(res.data);
                    setIsLoad(false);
                })
                .catch(err => console.log('error'));
        }else{
            setIsLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);


    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faBars}
                        title={'Familias'} />
                </div>

                <div className="bg-gris p-3">
                    
                <div className="d-flex bd-highlight">
                        <div className="p-2 bd-highlight">
                            <button className="btn btn-outline-success btn-lg"
                                onClick={() => { history.push('/add-familia') }}>
                                <FontAwesomeIcon icon={faPlus} /> Crear
                            </button>
                        </div>
                        <div className="p-2 flex-grow-1 bd-highlight">
                            <div className="input-group-prepend input-group-lg">
                               
                                <input type="text"
                                    className="form-control form-lg"
                                    id="inputNombre"
                                    name="precio"
                                    placeholder="Buscar"
                                />
                                 <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faSearch} />
                                </span>
                            </div>
                        </div>
                    </div>

                    <table className="table table-striped mt-3 text-center">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Ganancia</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>

                            {familias.map((familia) => (
                                <ItemFamilia
                                    key={parseInt(familia.idFamilia)}
                                    familia={familia}
                                    setIsLoad={setIsLoad}/>
                            ))}

                        </tbody>
                    </table>

                </div>

            </div>
        </Fragment>
    );
}

export default withRouter(Familias);