import React, { Fragment, Component } from 'react';

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo'


// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopyright, faEdit } from '@fortawesome/free-solid-svg-icons'

// router
import { connect } from 'react-redux';

// actions
import { EditaMarca, GetMarca } from '../../Actions/MarcaAction'

// services
import { getByIdMarcas, editByIdMarcas } from '../../Services/marcaService';

class EditMarca extends Component {

    state = {
        nombre: '',
        error: false
    }

    componentDidMount() {
        const { id } = this.props.match.params;

        getByIdMarcas(id)
            .then(res => {
                this.props.getMarca(res);
                const { nombre, activo } = this.props.marca;
                this.setState({ nombre, activo });
            })
            .catch(err => console.log(err));

    }

    handleMarca = e => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    editarMarcaSubmit = (e) => {
        e.preventDefault();

        // destructuring
        const { nombre, activo } = this.state;
        const { id } = this.props.match.params;

        // validations
        if (nombre === '') {
            this.setState({ error: true });
            return;
        }

        // creo el departamento actualizado
        const marcaUpdated = {
            idMarca: parseInt(id, 10), nombre, activo
        }

        editByIdMarcas(marcaUpdated)
            .then(res => {
                this.props.editarMarca(res);
                this.setState({ error: false, nombre: '', activo: '' });
                this.props.history.goBack();
            })
            .catch(error => {
                console.log(error.response);
                this.setState({ error: true });
            });

    }

    render() {

        const { nombre } = this.state

        return (
            <Fragment>
                <div className="container-fluid mt-3">
                    <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                        <BotonBack />
                        <Subtitulo
                            icon={faCopyright}
                            title={'Marcas'} />
                    </div>

                    <div className="bg-gris p-4">
                        <form onSubmit={this.editarMarcaSubmit}>
                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label htmlFor="inputNombre">Nombre</label>
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroupPrepend">
                                            <FontAwesomeIcon icon={faCopyright} />
                                        </span>
                                        <input type="text"
                                            id="inputNombre"
                                            name="nombre"
                                            className="form-control"
                                            placeholder="Ej. Lacteos, Gaseosas..."
                                            defaultValue={nombre}
                                            onChange={this.handleMarca} />
                                    </div>
                                </div>
                               
                                
                                
                            </div>


                            <div className="text-center">
                                <button type="submit" className="btn btn-outline-danger">
                                    <FontAwesomeIcon icon={faEdit} /> Editar Marca</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    marca: state.marcas.marca
})

const mapDispatchToProps = dispatch => {
    return {
        getMarca: (id) => dispatch(GetMarca(id)),
        editarMarca: (marca) => dispatch(EditaMarca(marca))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMarca);