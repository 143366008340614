import axios from 'axios';
import { url } from './global';

export const getAllCompras = async (page, data) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.post(`${url}/compras/page/${page}`, data, { headers });
    return res;
}

export const getAllComprasBySucursal = async (page, data) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.post(`${url}/compras/sucursal/${page}`, data, { headers });
    return res;
}

export const saveCompra = async (data) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.post(`${url}/compras`, data, { headers });
    return res;
}

export const getAllComprasByProveedor = async (proveedor) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/compras/proveedor/${proveedor}`, { headers });
    return res;
}

// REPORTES COMPRAS
export const getComprasReportes = async (sucursal, fechad, fechah) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/compras/sucursal/${sucursal}/reportes/${fechad}/${fechah}`, { headers });
    return res;
}

export const getComprasReportesByProveedor = async (sucursal, proveedor, fechad, fechah) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/compras/proveedor/${proveedor}/sucursal/${sucursal}/reportes/${fechad}/${fechah}`, { headers });
    return res;
}