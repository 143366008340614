import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo'

import { editByIdCliente } from '../../Services/clienteService';
import { EditaCliente } from '../../Actions/clientesAction';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBirthdayCake, faMapMarkedAlt, faIdCardAlt,faIdCard, faMobileAlt, faAt, 
    faUserEdit, faUserCircle, faAward, faEdit, faPercent} from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';

// formik
import { useFormik } from 'formik';
import * as Yup from 'yup';


const EditCliente = ({ history, location }) => {

    const [isLoad, setIsLoad] = useState(true);
    const dispatch = useDispatch();
    const cliente = location.state.cliente;

    useEffect(() => {
        if (isLoad) {
            setIsLoad(false);
        } else {
            setIsLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formik = useFormik({
        initialValues: {
            nombre: cliente.idCliente.persona.nombre,
            apellido: cliente.idCliente.persona.apellido,
            correo: cliente.idCliente.persona.correo,
            telefono: cliente.idCliente.persona.telefono,
            dni: cliente.idCliente.persona.dni,
            cuilCuit: cliente.idCliente.persona.cuilCuit,
            direccion: cliente.idCliente.persona.direccion,
            fechaNacimiento: cliente.idCliente.persona.fechaNacimiento,
            descuento: cliente.descuento,
            puntaje: cliente.puntaje
        },
        validationSchema: Yup.object({
            nombre: Yup.string()
                .required("Obligatorio."),
            correo: Yup.string().email("Formato invalido."),
            dni: Yup.number()
                .required("Obligatorio."),
            descuento: Yup.number()
                .required("Obligatorio.")
                .min(0, "El número debe ser positivo.")
                .max(100, "El número no puede ser mayor a 100.")
        }),
        onSubmit: values => EditarClienteSubmit(values)
    })


    const EditarClienteSubmit = ({nombre,apellido,correo,telefono,dni,direccion,cuilCuit,
        fechaNacimiento,descuento,puntaje}) => {

        //Editamos primero la persona
        let persona = {
            idPersona: cliente.idCliente.persona.idPersona, nombre, apellido, correo, telefono, dni, direccion, cuilCuit, fechaNacimiento
        }

        //Ahora editamos el cliente
        let clienteUpdate = {
            idCliente: { persona: persona },
            puntaje: puntaje,
            descuento: descuento
        }
        editByIdCliente(clienteUpdate)
            .then(res => {
                dispatch(EditaCliente(res));
                history.goBack();
            })
            .catch(error => {
                Swal.fire({
                    text: 'Error al actualizar el cliente, verifique los datos.',
                    type: 'error',
                    showConfirmButton: false,
                    timer: 1500
                });
            });
    }

    return (

        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faUserCircle}
                        title={'Clientes'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputNombre">Nombre *</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faUserCircle} />
                                    </span>
                                    <input type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        className="form-control"
                                        placeholder="Ej. Juan Carlos"
                                        value={formik.values.nombre}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} 
                                    />
                                </div>
                                {formik.touched.nombre && formik.errors.nombre ?
                                    <small className="form-text text-danger">
                                        {formik.errors.nombre}
                                    </small>
                                    : null
                                }
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputApellido">Apellido</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faUserEdit} />
                                    </span>
                                    <input type="text"
                                        className="form-control"
                                        id="inputApellido"
                                        name="apellido"
                                        placeholder="Ej. Gutierrez"
                                        value={formik.values.apellido}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} 
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputCorreo">Correo Electronico</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faAt} />
                                    </span>
                                    <input type="text"
                                        id="inputCorreo"
                                        name="correo"
                                        className="form-control"
                                        placeholder="Ej. gutierrez@ejemplo.com"
                                        value={formik.values.correo}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} 
                                    />
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputTelefono">Telefono</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faMobileAlt} />
                                    </span>
                                    <input type="text"
                                        className="form-control"
                                        id="inputTelefono"
                                        name="telefono"
                                        placeholder="Ej. +543814123456"
                                        value={formik.values.telefono}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} 
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputDni">DNI *</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faIdCard} />
                                    </span>
                                    <input type="text"
                                        id="inputDni"
                                        name="dni"
                                        className="form-control"
                                        placeholder="Ej. 99999999"
                                        value={formik.values.dni}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} 
                                    />
                                </div>
                                {formik.touched.dni && formik.errors.dni ?
                                    <small className="form-text text-danger">
                                        {formik.errors.dni}
                                    </small>
                                    : null
                                }
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputCuilCuit">CUIL/CUIT</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faIdCardAlt} />
                                    </span>
                                    <input type="text"
                                        className="form-control"
                                        id="inputCuilCuit"
                                        name="cuilCuit"
                                        placeholder="Ej. 20999999990"
                                        value={formik.values.cuilCuit}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} 
                                        />
                                </div>
                            </div>
                        </div>


                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputDireccion">Direccion</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faMapMarkedAlt} />
                                    </span>
                                    <input type="text"
                                        id="inputDireccion"
                                        name="direccion"
                                        className="form-control"
                                        placeholder="Ej. Pje. La Paz 123"
                                        value={formik.values.direccion}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} 
                                        />
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputFechaNacimiento">Fecha Nacimiento</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faBirthdayCake} />
                                    </span>
                                    <input type="date"
                                        className="form-control"
                                        id="inputFechaNacimiento"
                                        name="fechaNacimiento"
                                        value={formik.values.fechaNacimiento ? formik.values.fechaNacimiento.split('T')[0] : null}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} 
                                        />
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputpuntaje">Puntaje *</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faAward} />
                                    </span>
                                    <input type="text"
                                        id="inputpuntaje"
                                        name="puntaje"
                                        className="form-control"
                                        value={formik.values.puntaje}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} 
                                        />
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputDescuento">Descuento *</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faPercent} />
                                    </span>
                                    <input
                                        type="number"
                                        id="inputDescuento"
                                        name="descuento"
                                        className="form-control"
                                        value={formik.values.descuento}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        />
                                </div>
                            </div>
                        </div>

                        <div className="text-center">
                            <button type="submit" className="btn btn-outline-danger">
                                <FontAwesomeIcon icon={faEdit} /> Editar Cliente</button>
                        </div>

                    </form>
                </div>
            </div>
        </Fragment>
    )

}

export default withRouter(EditCliente);