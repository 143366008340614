import React, { Fragment, useState } from 'react';

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo'

// redux
import { useDispatch } from 'react-redux';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faBuilding, faMapMarkedAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'

// router
import { withRouter } from 'react-router-dom';

// actions
import { AddSucursal } from '../../Actions/SucursalAction';

// services
import { saveSucursal } from '../../Services/sucursalService';

function AddSucursales({ history }) {

    const [nombre, setNombre] = useState('');
    const [telefono, setTelefono] = useState(0);
    const [direccion, setDireccion] = useState(0);

    const dispatch = useDispatch();

    // useEffect(() => {
    //     if (isLoad) {
           
    //     } else {
    //         setIsLoad(false);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isLoad]);


    const addSucursalSubmit = (e) => {
        e.preventDefault();
        // validaciones

        // creamos la sucursal
        let sucursal = {
            nombre, telefono, direccion
        }

        saveSucursal(sucursal)
            .then(res => {
                dispatch(AddSucursal(res));
                history.goBack();
            })
            .catch(error => console.log(error.response.data));
    }

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faBuilding}
                        title={'Sucursales'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={addSucursalSubmit}>
                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputNombre">Nombre</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faBuilding} />
                                    </span>
                                    <input type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        className="form-control"
                                        placeholder="Ej. Lacteos, Gaseosas..."
                                        onChange={e => setNombre(e.target.value)} />
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputNombre">Direccion</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faMapMarkedAlt} />
                                    </span>
                                    <input type="text"
                                        id="inputNombre"
                                        name="direccion"
                                        className="form-control"
                                        placeholder="Ej. Piedras 2120..."
                                        onChange={e => setDireccion(e.target.value)} />
                                </div>
                            </div>

                        </div>

                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputCodigo">Telefono</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faPhoneAlt} />
                                    </span>
                                    <input type="text"
                                        className="form-control"
                                        id="inputCodigo"
                                        name="telefono"
                                        placeholder="Ej. 4324101"
                                        onChange={e => setTelefono(e.target.value)} />
                                </div>
                            </div>
                        </div>


                        <div className="text-center">
                            <button type="submit" className="btn btn-outline-success">
                                <FontAwesomeIcon icon={faPlus} /> Crear sucursal</button>
                        </div>


                    </form>
                </div>



            </div>
        </Fragment>
    );
}

export default withRouter(AddSucursales);