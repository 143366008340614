import React from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPercentage, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

// router
import { withRouter } from 'react-router-dom';


const ItemVenta = ({articulo, index, borrar}) => {

    return (
        <tr>
            <th scope="row"><h4>{articulo.codigo}</h4></th>
            <td><h4>{articulo.articulo}</h4> </td>
            <td>
                <div className="input-group form-row justify-content-center">

                    <input type="text" className="form-control col-md-3"
                        style={{ textAlign: "center", fontSize: 13, fontWeight: "bold" }}
                        value={articulo.cantidad} disabled />

                </div>
            </td>
            <td><h4>${articulo.precio}</h4></td>
            <td>
                <div className="input-group form-row justify-content-center">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <FontAwesomeIcon icon={faPercentage} />
                        </span>
                    </div>
                    <input type="text" className="form-control col-md-3"
                        style={{ textAlign: "center", fontSize: 13, fontWeight: "bold" }}
                        disabled defaultValue={articulo.iva} />

                </div>
            </td>
            <td>
                <div className="input-group form-row justify-content-center">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <FontAwesomeIcon icon={faPercentage} />
                        </span>
                    </div>
                    <input type="text" className="form-control col-md-3"
                        style={{ textAlign: "center", fontSize: 13, fontWeight: "bold" }}
                        disabled defaultValue={articulo.descuento} />

                </div>
            </td>
            <td>
                <button className="btn btn-outline-primary"
                    onClick={() => borrar(articulo,index)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                </button>
            </td>
        </tr>
    );

}

export default withRouter(ItemVenta);