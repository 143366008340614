import React, { Fragment, useEffect, useState } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faCashRegister, faList, faSearch } from '@fortawesome/free-solid-svg-icons'

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo';
import ItemCajaReporte from './ItemCajaReporte';

// route
import { withRouter } from 'react-router-dom';

// services
import { findAllCajasPageBySucursal } from '../../Services/cajaService';
import { getAllSucursales } from '../../Services/sucursalService'

// alert swal
import Swal from 'sweetalert2'

const Cajas = ({ history }) => {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    let rol = token.authorities[0];

    const [isLoad, setIsLoad] = useState(true);
    const [cajas, setCajas] = useState([]);
    const [sucursales, setSucursales] = useState([]);
    const [sucursal, setSucursal] = useState(token.empleado.sucursal.idSucursal);

    // PAGINATION
    const [page, setPage] = useState(0);
    const [totalRegister, setTotalRegister] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [query, setQuery] = useState("");


    useEffect(() => {
        if (rol !== 'ROLE_ADMIN') history.goBack();
        if (isLoad) {
            let data = {
                query, sucursal
            }
            findAllCajasPageBySucursal(0, data)
                .then(res => {
                    setCajas(res.data.content);
                    setTotalRegister(res.data.totalElements);
                    setTotalPage(res.data.totalPages);
                    setIsLoad(false);
                })
                .catch(error => {
                    console.log(error.response);
                });

            getAllSucursales()
                .then(res => setSucursales(res.data))
                .catch(err => console.log(err));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);

    const nextPage = () => {

        if (page === totalPage) {
            Swal.fire({
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000
            })
            return;
        }
        let data = {
            query, sucursal
        }
        findAllCajasPageBySucursal(page + 1, data)
            .then(res => {
                setPage(page + 1);
                setCajas(res.data.content);
                setTotalRegister(res.data.totalElements);
                setTotalPage(res.data.totalPages);
                setIsLoad(false);
            })
            .catch(err => console.log('error'));


    }

    const previousPage = () => {

        if (page === 0) {
            Swal.fire({
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000
            })
            return;
        }
        let data = {
            query, sucursal
        }
        findAllCajasPageBySucursal(page - 1, data)
            .then(res => {
                setPage(page - 1);
                setCajas(res.data.content);
                setTotalRegister(res.data.totalElements);
                setTotalPage(res.data.totalPages);
                setIsLoad(false);
            })
            .catch(err => console.log('error'));
    }

    const handleBuscador = (e) => {
        console.log(e.target.value);
        setQuery(e.target.value);
        if (e.key === 'Enter') {
            // verificar si la query isEmpty hacer otra consulta
            let data = {
                query: e.target.value, sucursal
            }
            findAllCajasPageBySucursal(0, data)
                .then(res => {
                    setCajas(res.data.content);
                    setTotalRegister(res.data.totalElements);
                    setTotalPage(res.data.totalPages);
                    setPage(0)
                    setIsLoad(false);
                    console.log(res, data);
                })
                .catch(err => console.log('error'));
        }
    }

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faCashRegister}
                        title={'Cajas'} />
                </div>

                <div className="d-flex bd-highlight">

                    <div className="p-2 flex-grow-1 bd-highlight">
                        <div className="input-group-prepend input-group-lg">

                            <span className="input-group-text" id="inputGroupPrepend">
                                <FontAwesomeIcon icon={faSearch} />
                            </span>

                            <input type="text"
                                className="form-control form-lg"
                                id="inputNombre"
                                name="precio"
                                placeholder="Buscar"
                                onKeyPress={(e) => { handleBuscador(e) }}
                            />

                        </div>
                    </div>

                    {/* <div className="p-2 bd-highlight">
                        <button className="btn btn-outline-success btn-lg"
                            onClick={() => handleImprimir()}
                        >
                            <FontAwesomeIcon icon={faPrint} /> Imprimir
                            </button>
                    </div> */}
                    {(rol === 'ROLE_ADMIN') ?
                        <div className="d-flex bd-highlight">
                            <div className="p-2 bd-highlight">
                                <button className="btn btn-outline-warning btn-lg"
                                    onClick={() => { history.push('/cajas-informes') }}>
                                    <FontAwesomeIcon icon={faList} /> Informes
                                </button>
                            </div>
                            <div className="p-2 bd-highlight">
                                <div className="dropdown">
                                    <button className="btn btn-outline-info btn-lg dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <FontAwesomeIcon icon={faBuilding} /> Sucursales
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        {sucursales.map(sucursal => {
                                            return <button key={sucursal.idSucursal} className="dropdown-item"
                                                onClick={() => setSucursal(sucursal.idSucursal)}>
                                                {sucursal.nombre}
                                            </button>;
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                    }
                </div>

                <div className="bg-gris p-3">

                    <div className="d-flex flex-row bd-highlight">
                        <div className="p-2 bd-highlight">
                            <button className="btn btn-outline-info"
                                onClick={() => previousPage()} aria-label="Previous">
                                <span aria-hidden="true">&laquo; Anterior</span>
                            </button>
                        </div>
                        <div className="p-2 bd-highlight mt-1">
                            Registros: {totalRegister} | Pagina actual: {page + 1} | Total páginas : {totalPage}
                        </div>
                        <div className="p-2 bd-highlight">
                            <button className="btn btn-outline-info"
                                onClick={() => nextPage()} aria-label="Next">
                                <span aria-hidden="true">Siguiente &raquo;</span>
                            </button>
                        </div>


                    </div>

                    <table className="table table-striped mt-3 text-center">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Punto de Venta</th>
                                <th scope="col">Fecha Apertura</th>
                                <th scope="col">Fecha Cierre</th>
                                <th scope="col">Monto Inicial</th>
                                <th scope="col">Monto Final</th>
                                <th scope="col">Estado</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>

                            {cajas.map((caja) => (
                                <ItemCajaReporte
                                    key={parseInt(caja.idCaja)}
                                    item={caja}
                                    setIsLoad={setIsLoad} />
                            ))}

                        </tbody>
                    </table>

                </div>

            </div>
        </Fragment>
    );
}

export default withRouter(Cajas);