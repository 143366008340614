import React, { Fragment, useRef, useEffect, useState } from 'react';

// router
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faClipboardList, faUserCircle, faDollarSign, faCalendarMinus, faMinusCircle, faPlusCircle, faCalendarPlus, faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons'
import { editByIdCuentasCorriente } from '../../Services/cuentaCorrienteClienteService';
import { EditarCuentaCorriente } from '../../Actions/CuentaCorrienteAction';

const EditCuentaCorriente = ({ history, location }) => {

    const montoMaximoRef = useRef('');
    const cantidadCuotasMaximasRef = useRef('');
    const debeRef = useRef('');
    const haberRef = useRef('');
    const fechaUltimoPagoRef = useRef('');
    const fechaProximoPagoRef = useRef('');

    const [isLoad, setIsLoad] = useState(true);
    const [cliente, setCliente] = useState('');
    const [activa, setActiva] = useState();

    const cuentaCorriente = location.state.cuentaCorriente;

    const dispatch = useDispatch();

    useEffect(() => {
        if (isLoad) {
            setCliente(cuentaCorriente.cliente);
            setActiva(cuentaCorriente.activa);
        } else {
            setIsLoad(false);
        }        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);

    const editSucursalSubmit = (e) => {
        e.preventDefault();
        // validaciones

        // formamos la sucursal editada
        let cuentaCorrienteUpdated = {
            idCuentaCorriente: parseInt(cuentaCorriente.idCuentaCorriente, 10),
            cliente: cliente,
            montoMaximo: montoMaximoRef.current.value,
            cantidadCuotasMaximas: cantidadCuotasMaximasRef.current.value,
            debe: debeRef.current.value,
            haber: haberRef.current.value,
            fechaUltimoPago: fechaUltimoPagoRef.current.value,
            fechaProximoPago: fechaProximoPagoRef.current.value,
            activa
        }        

        editByIdCuentasCorriente(cuentaCorrienteUpdated)
            .then(res => {
                dispatch(EditarCuentaCorriente(res));
                history.goBack();
            })
            .catch(error => console.log(error.response.data));

    }

    const isDateNull = (fecha) => {
        if (fecha === null) return null;
        else {
            let fechaFinal = fecha.split('T')[0];
            return fechaFinal;
        }

    }

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faClipboardList}
                        title={'Cuentas Corrientes'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={editSucursalSubmit}>
                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputCliente">Cliente</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faUserCircle} />
                                    </span>
                                    <input type="text"
                                        id="inputClinete"
                                        name="cliente"
                                        className="form-control"
                                        defaultValue={(cuentaCorriente.cliente.idCliente.persona.apellido + ', ' + cuentaCorriente.cliente.idCliente.persona.nombre).toLocaleUpperCase()}
                                        disabled />
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputMontoMaximo">Monto Maximo</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faDollarSign} />
                                    </span>
                                    <input type="text"
                                        id="inputMontoMaximo"
                                        name="montoMaximo"
                                        className="form-control"
                                        placeholder="Ej. Piedras 2120..."
                                        ref={montoMaximoRef}
                                        defaultValue={cuentaCorriente.montoMaximo} />
                                </div>
                            </div>
                        </div>

                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputDebe">Debe</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faMinusCircle} />
                                    </span>
                                    <input type="text"
                                        id="inputDebe"
                                        name="debe"
                                        className="form-control"
                                        ref={debeRef}
                                        defaultValue={cuentaCorriente.debe} />
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputHaber">Favor</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </span>
                                    <input type="text"
                                        className="form-control"
                                        id="inputHaber"
                                        name="haber"
                                        ref={haberRef}
                                        defaultValue={cuentaCorriente.haber} />
                                </div>
                            </div>
                        </div>

                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputUltimoPago">Fecha Ultimo Pago</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faCalendarMinus} />
                                    </span>
                                    <input type="date"
                                        id="inputUltimoPago"
                                        name="ultimoPago"
                                        className="form-control"
                                        ref={fechaUltimoPagoRef}
                                        defaultValue={isDateNull(cuentaCorriente.fechaUltimoPago)} />
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputProximoPago">Fecha Proximo Pago</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faCalendarPlus} />
                                    </span>
                                    <input type="date"
                                        className="form-control"
                                        id="inputProximoPago"
                                        name="proximoPago"
                                        ref={fechaProximoPagoRef}
                                        defaultValue={isDateNull(cuentaCorriente.fechaProximoPago)} />
                                </div>
                            </div>
                        </div>


                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputMaximoCuotas">Cantidad Maximo Cuotas</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faMoneyCheckAlt} />
                                    </span>
                                    <input type="text"
                                        className="form-control"
                                        id="inputMaximoCuotas"
                                        name="maximoCuotas"
                                        ref={cantidadCuotasMaximasRef}
                                        defaultValue={cuentaCorriente.cantidadCuotasMaximas} />
                                </div>
                            </div>

                            
                        </div>


                        <div className="text-center">
                            <button type="submit" className="btn btn-outline-danger">
                                <FontAwesomeIcon icon={faEdit} /> Editar Cuenta Corriente</button>
                        </div>


                    </form>
                </div>



            </div>
        </Fragment>
    );
};

export default withRouter(EditCuentaCorriente);