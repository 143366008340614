import { GET_PROVEEDORES, ADD_PROVEEDOR, EDIT_PROVEEDOR, DELETE_PROVEEDOR, GET_PROVEEDOR } from './Types';

export const GetProveedores = (res) => {
    return{
        type: GET_PROVEEDORES,
        payload: res.data
    }
}

export const AddProveedor =  (res) => {
    return {
        type: ADD_PROVEEDOR,
        payload: res.data
    }
}

export const EditaProveedor = (res) => {

    return {
        type: EDIT_PROVEEDOR,
        payload: res.data
    }
}


export const GetProveedor = (res) => {
    return {
        type: GET_PROVEEDOR,
        payload: res.data
    }
} 

export const DeleteProveedor = (id) => {
    return {
        type: DELETE_PROVEEDOR,
        payload: id
    }
}