import React from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faCheck } from '@fortawesome/free-solid-svg-icons'

// router
import { withRouter } from 'react-router-dom';

const ItemEmpleadoModal = ({ empleado, setEmpleado }) => {

    const handlEmpleado = empleado => {
        setEmpleado(empleado);
    }

    return (
        <tr>
            <th scope="row">{ empleado.idEmpleado.persona.idPersona }</th>
            <td>{empleado.idEmpleado.persona.nombre + ', ' + empleado.idEmpleado.persona.apellido}</td>
            <td>{empleado.idEmpleado.persona.dni}</td>
            <td>{empleado.cargo.nombre}</td>
            <td>
                <button className="btn text-danger" data-dismiss="modal"
                        onClick={ () =>{ handlEmpleado(empleado) }}>
                    <FontAwesomeIcon icon={faCheck}/>
                </button>
            </td>
        </tr>
    );
}

export default withRouter(ItemEmpleadoModal);