import axios from 'axios';
import {url} from './global';


export const getAllProveedores = async () => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/proveedores`,{headers});
    return res;
}

export const getByIdProveedor = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/proveedores/${id}`,{headers});
    return res;
}

export const editByIdProveedor = async (proveedor) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/proveedores/${proveedor.idProveedor}`, proveedor,{headers});
    return res;
}

export const deleteByIdProveedor = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.delete(`${url}/proveedores/${id}`,{headers});
    return res;
}

export const saveProveedor = async (proveedor) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/proveedores`,proveedor,{headers});
    return res;
}
