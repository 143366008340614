import axios from 'axios';
import {url} from './global';


export const save = async (puntoVenta) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.post(`${url}/puntosdeventa`, puntoVenta,{headers});
    return res;
}

export const findAllBySucursal = async (sucursal) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/puntosdeventa/sucursal/${sucursal}`,{headers});
    return res;
}