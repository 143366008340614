import React, { Fragment, useEffect, useState } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopyright, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo';
import ItemMarca from './ItemMarca';

// redux
import { useDispatch } from 'react-redux'

// actions
import { GetMarcas, DeleteMarca } from '../../Actions/MarcaAction';

// route
import { withRouter } from 'react-router-dom';

// services
import { getAllMarcas, getAllMarcasByNombre } from '../../Services/marcaService';


const Marcas = ({ history }) => {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    let rol = token.authorities[0];

    const [isLoad, setIsLoad] = useState(true);
    const [marcas, setMarcas] = useState([]);
    const dispatch = useDispatch();
    const deleteMarca = (id) => dispatch(DeleteMarca(id));


    useEffect(() => {
        if (rol !== 'ROLE_ADMIN') history.goBack();
        if (isLoad) {
            getAllMarcas()
                .then(res => {
                    setMarcas(res.data);
                    setIsLoad(false);
                })
                .catch(err => console.log('error'));
        } else {
            setIsLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);

    const onChangeMarcas = e => {
        let valor = e.target.value;
        if(valor === '') {
            getAllMarcas()
                .then(res => dispatch(GetMarcas(res)))
                .catch(error => console.log(error.response));
        }else{
            // find like dni
            getAllMarcasByNombre(valor)
            .then(res => dispatch(GetMarcas(res)))
            .catch(error => console.log(error.response));
        }
    }

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faCopyright}
                        title={'Marcas'} />
                </div>

                <div className="bg-gris p-3">

                    <div className="d-flex bd-highlight">
                        <div className="p-2 bd-highlight">
                            <button className="btn btn-outline-success btn-lg"
                                onClick={() => { history.push('/add-marca') }}>
                                <FontAwesomeIcon icon={faPlus} /> Crear
                            </button>
                        </div>
                        <div className="p-2 flex-grow-1 bd-highlight">
                            <div className="input-group-prepend input-group-lg">

                                <input type="text"
                                    className="form-control form-lg"
                                    id="inputNombre"
                                    name="precio"
                                    placeholder="Buscar"
                                    onChange={e => onChangeMarcas(e)}
                                />
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faSearch} />
                                </span>
                            </div>
                        </div>
                    </div>

                    <table className="table table-striped mt-3 text-center">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>

                            {marcas.map((marca) => (
                                <ItemMarca
                                    key={parseInt(marca.idMarca)}
                                    marca={marca}
                                    deleteMarca={deleteMarca}
                                    setIsLoad={setIsLoad} />
                            ))}

                        </tbody>
                    </table>

                </div>

            </div>
        </Fragment>
    );
}

export default withRouter(Marcas);