import React from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

// modals
import ModalHistorialStock from './ModalHistorialStock/ModalHistorialStock';

// routes
import { withRouter } from 'react-router-dom';

// Sweer Alert
import Swal from 'sweetalert2';

const ItemStockSucursal = ({ articulo, history }) => {


    const condicionStock = (stock) => {
        if (parseFloat(stock) >= parseFloat(articulo.cantidad)) return <p className="text-success">{articulo.cantidad}</p>;
        else return <p className="text-warning">{Number.parseFloat(articulo.cantidad).toFixed(2)}</p>;
    }

    const EditarStock = () => {
        if ((articulo.articulo.compuesto).toString() === "true") {
            Swal.fire({
                title: 'No se pueden modificar articulos compuestos, su stock depende de otros',
                type: 'info',
                showConfirmButton: false,
                timer: 2000
            });
            return;
        }
        history.push(`/edit-stock-sucursal/${articulo.articulo.idArticulo}`, { stockSucursal: articulo })
    }

    return (
        <tr>
            <th scope="row">{articulo.articulo.idArticulo}</th>
            <td>{articulo.articulo.nombre}</td>
            <td>{Number(articulo.precioFinal).toFixed(2)}</td>
            <td>{(articulo.articulo.pesable)? 'SI' : 'NO'}</td>
            <td>{articulo.articulo.pesoPromedio}</td>
            <td>{condicionStock(articulo.cantidad)}</td>
            <td>{(articulo.articulo.pesable)?
                Number.parseFloat(articulo.cantidad / 1000).toFixed(2):
                '-'}
            </td>
            <td>{Number.parseFloat(articulo.fechaModificacion) ? articulo.fechaModificacion.split('T')[0] : '-'}</td>
            <td>
                <ModalHistorialStock item={articulo}/>
                <button className="btn text-danger" title="Modificar"
                    onClick={() => {EditarStock()}}>
                    <FontAwesomeIcon icon={faEdit} /> Editar
                </button>    
            </td>
        </tr>
    );
}

export default withRouter(ItemStockSucursal);