import { GET_CARGOS, GET_CARGO, ADD_CARGO, EDIT_CARGO, DELETE_CARGO } from '../Actions/Types';

// cada reducer tiene su propio state
const initialState = {
    cargos: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        
        case GET_CARGOS:
            return {
                ...state,
                cargos: action.payload
            }
        case GET_CARGO:
            return {
                ...state,
                cargo: action.payload
            }
        case ADD_CARGO:
            return {
                ...state,
                cargos: [...state.cargos, action.payload]
            }
        case EDIT_CARGO:
            return {
                ...state,
                cargos: (state.cargos.map(
                    cargo => cargo.idCargo === action.payload.idCargo
                        ? (cargo = action.payload)
                        : cargo
                ))
            }
        case DELETE_CARGO:
            return {
                ...state,
                cargos: state.cargos.filter(cargo => cargo.idCargo !== action.payload)
            }
        default:
            return state;
    }
}
