import { GET_SUCURSALES, ADD_SUCURSAL, EDIT_SUCURSAL, GET_SUCURSAL, DELETE_SUCURSAL } from './Types';

export const GetSucursales = (res) => {
    return{
        type: GET_SUCURSALES,
        payload: res.data
    }
}

export const AddSucursal =  (res) => {
    return {
        type: ADD_SUCURSAL,
        payload: res.data
    }
}

export const EditaSucursal = (res) => {

    return {
        type: EDIT_SUCURSAL,
        payload: res.data
    }
}


export const GetSucursal = (res) => {
    return {
        type: GET_SUCURSAL,
        payload: res.data
    }
} 

export const DeleteSucursal = (id) => {
    return {
        type: DELETE_SUCURSAL,
        payload: id
    }
}