import React, { Fragment, useState, useEffect } from 'react';

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faUser, faUserCircle, faUserTie, faUserSecret }
 from '@fortawesome/free-solid-svg-icons'

// router
import { withRouter } from 'react-router-dom';

// services
import { register } from '../../Services/usuarioService';
import { getAllRoles } from '../../Services/roleService';

// modals
import EmpleadoModal from './ModalEmpleado/EmpleadoModal';
import Swal from 'sweetalert2';

// Formik
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';

function AddUsuario({ history }) {

    const [isLoad, setIsLoad] = useState(true);
    const [roles, setRoles] = useState([]);
    const [empleado, setEmpleado] = useState({idEmpleado: { persona: {dni: ''}}});
    
    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
            passwordConfirm: '',
            role: '',
            //empleado: ''
        },
        validationSchema: Yup.object({
            username: Yup.string().required("Obligatorio").min(4),
            password: Yup.string().required("Obligatorio").min(4),
            passwordConfirm: Yup.string().when("password", {
                is: val => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                  [Yup.ref("password")],
                  "Las contraseñas no son iguales"
                )}).required("Obligatorio"),
            role: Yup.object().required("Obligatorio"),
            //empleado: Yup.object().required("Obligatorio")
        }),
        onSubmit: values => addUsuarioSubmit(values)
    })

    useEffect(() => {
        if (isLoad) {
            getAllRoles()
                .then((res) => {setRoles(res.data);})
                .catch((error) => console.log(error));
            setIsLoad(false);
        } else {
            setIsLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);


    const addUsuarioSubmit = ({username,password,role}) => {
        // validaciones

        // creamos el usuario
        let usuarioRole = {
            id: {
                usuario:{
                    usuario: empleado.idEmpleado.persona,
                    username: username,
                    password: password
                },
                role: role
            }
        }

        register(usuarioRole)
            .then(res => {
                history.goBack();
            })
            .catch(error => {
                console.log(error);
                Swal.fire({
                    text: 'El empleado ya posee un usuario o faltan datos.',
                    type: 'error',
                    showConfirmButton: false,
                    timer: 1500
                });
            });

    }

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faUser}
                        title={'Crear usuario'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputEmpleado">Empleado</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faUserTie} />
                                    </span>
                                    <EmpleadoModal
                                        setEmpleado={setEmpleado}
                                    />
                                    <input type="text"
                                        id="inputEmpleado"
                                        name="empleado"
                                        className="form-control"
                                        placeholder="Haga clic en la lupa para seleccionar un empleado."
                                        value={empleado.idEmpleado.persona.dni + ' - ' + empleado.idEmpleado.persona.nombre + ' ' + empleado.idEmpleado.persona.apellido}
//                                        value={empleado.idEmpleado.persona.dni}
                                        disabled />
                                </div>
                                {/* {formik.touched.empleado && formik.errors.empleado ?
                                    <small className="form-text text-danger">
                                        {formik.errors.empleado}
                                    </small>
                                    : null} */}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputUsername">Nombre de usuario</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faUserCircle} />
                                    </span>
                                    <input type="text"
                                        id="inputUsername"
                                        name="username"
                                        className="form-control"
                                        value={formik.values.username}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.username && formik.errors.username ?
                                    <small className="form-text text-danger">
                                        {formik.errors.username}
                                    </small>
                                    : null}
                            </div>
                        </div>

                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputPass">Contraseña</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faUserSecret} />
                                    </span>
                                    <input type="password"
                                        className="form-control"
                                        id="inputPass"
                                        name="password"
                                        placeholder="********"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.password && formik.errors.password ?
                                    <small className="form-text text-danger">
                                        {formik.errors.password}
                                    </small>
                                    : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputPass">Confirmar contraseña</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faUserSecret} />
                                    </span>
                                    <input type="password"
                                        className="form-control"
                                        id="inputPasswordConfirm"
                                        name="passwordConfirm"
                                        value={formik.values.passwordConfirm}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.passwordConfirm && formik.errors.passwordConfirm ?
                                    <small className="form-text text-danger">
                                        {formik.errors.passwordConfirm}
                                    </small>
                                    : null}
                            </div>

                        </div>

                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputRole">Rol</label>
                                <div>
                                    <Select
                                        id="inputRole"
                                        placeholder={"Seleccionar un rol"}
                                        options={roles}
                                        onChange={value => formik.setFieldValue('role',value)}
                                        getOptionValue={(values) => values.idRole}
                                        getOptionLabel={(values) => values.nombre}>
                                    </Select>
                                </div>
                                {formik.touched.role && formik.errors.role ?
                                    <small className="form-text text-danger">
                                        {formik.errors.role}
                                    </small>
                                    : null}
                            </div>

                        </div>


                        <div className="text-center">
                            <button type="submit" className="btn btn-outline-success">
                                <FontAwesomeIcon icon={faPlus} /> Crear usuario</button>
                        </div>


                    </form>
                </div>



            </div>
        </Fragment>
    );

}

export default withRouter(AddUsuario);