import React from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

// routes
import { withRouter } from 'react-router-dom';

// Sweet alert
//import Swal from 'sweetalert2';

// services
//import { deleteByIdSucursal } from '../../Services/sucursalService';

const ItemSucursal = ({ sucursal, history, setIsLoad, deleteSucursal }) => {

    // const EliminarSucursalSubmit = (id) => {
    //     Swal.fire({
    //         title: 'Estas seguro de eliminar?',
    //         text: "Una sucursal eliminada no se puede recuperar!",
    //         type: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Si, Eliminar!',
    //         cancelButtonText: 'Cancelar'
    //     }).then((result) => {
    //         if (result.value) {
    //             // consulta a la api para eliminar
    //             deleteByIdSucursal(id)
    //                 .then(res => {
    //                     Swal.fire(
    //                         'Eliminado!',
    //                         'La sucursal fue eliminada.',
    //                         'success'
    //                     );
    //                     // INVOCAMOS EL ACTION
    //                     deleteSucursal(id);
    //                     setIsLoad(true);
    //                 })
    //                 .catch(error => {
    //                     Swal.fire({
    //                         type: 'error',
    //                         title: 'Error',
    //                         text: 'Hubo un error vuelve a intentar!'
    //                     });
    //                     console.log(error.response);
    //                 })
    //         }
    //     })
    // }

    return (
        <tr>
            <th scope="row">{sucursal.idSucursal}</th>
            <td>{sucursal.nombre}</td>
            <td>{sucursal.direccion}</td>
            <td>{sucursal.telefono}</td>
            <td>
                <button className="btn text-danger"
                    onClick={() => { history.push(`/edit-sucursal/${sucursal.idSucursal}`,{sucursal:sucursal}) }}>
                    <FontAwesomeIcon icon={faEdit} /> Editar
                </button>
                {/* <button className="btn text-primary"
                    onClick={() => EliminarSucursalSubmit(sucursal.idSucursal)}>
                    <FontAwesomeIcon icon={faTrash} /> Eliminar
                </button> */}
            </td>
        </tr>
    );
}

export default withRouter(ItemSucursal);