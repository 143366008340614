import React, { Fragment, useEffect } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList } from '@fortawesome/free-solid-svg-icons'


// router
import { withRouter } from 'react-router-dom';
import ItemDetallePedido from './ItemDetallePedido';

const ModalDetallePedido = ({item}) => {

    const activo = (estado) => {
        if (estado === 'ORDENADO') return <p className="text-warning">ORDENADO</p>;
        if (estado === 'ENVIADO') return <p className="text-info">ENVIADO</p>;
        if (estado === 'RECIBIDO') return <p className="text-success">RECIBIDO</p>;
    }

    useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
 
    return (
        <Fragment>

            <button type="button" className="btn text-info"
                data-toggle="modal" data-target={"#modalPedidos"+item.idPedido}>
                <FontAwesomeIcon icon={faList}/> Detalle
            </button>

            <div className="modal fade" id={"modalPedidos"+item.idPedido} tabIndex="-1" role="dialog" aria-labelledby="modalPedidosTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <h5 className="modal-title" id="modalPedidosTitle"><b>Detalle Pedido</b></h5>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <p className="col-md"><b>Fecha Envio:</b></p>
                                <p className="col-md"><b>Fecha Entrega:</b></p>
                                <p className="col-md"><b>Estado:</b></p>
                            </div>
                            <div className="row">
                                <p className="col-md">{item.fechaEnvio.split('T')[0]}</p>
                                <p className="col-md">{(item.fechaEntrega)?item.fechaEntrega.split('T')[0]:'-'}</p>
                                <p className="col-md">{activo(item.estado)}</p>
                            </div>
                            <table className="table table-striped mt-3 text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Articulo</th>
                                        <th scope="col">Cantidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {item.items.map((item, index) => (
                                        
                                        <ItemDetallePedido
                                            item = {item}
                                            index = {index}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    );

}

export default withRouter(ModalDetallePedido);