import React from 'react';

// icons
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faMoneyBill } from '@fortawesome/free-solid-svg-icons'

// routes
import { withRouter } from 'react-router-dom';

// modals
import ModalDetalleCompra from './ModalDetalleCompra/ModalDetalleCompra';
import ModalDetallePago from './ModalDetallePagos/ModalDetallePago';


// services
//import { editByIdventa } from '../../Services/ventaService';


// Sweet alert
//import Swal from 'sweetalert2';

const ItemCompras = ({ compra, setIsLoad }) => {

    //let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    //let rol = token.authorities[0];


    const estado = (estado) => {
        if (estado === 'ADEUDADA') return <p className="text-primary">ADEUDADA</p>;
        if (estado === 'PAGADA_PARCIALMENTE') return <p className="text-warning">PAGADA PARCIALMENTE</p>;
        if (estado === 'PAGADA') return <p className="text-success">PAGADA</p>;
    }

    /*const HandleEstadoventa = (venta, estado) => {
        venta.estado = estado;
        editByIdventa(venta)
            .then(res => {
                // INVOCAMOS EL ACTION
                editaventa(res);
                setIsLoad(true);
            })
            .catch(res => {
                console.log(res.response);
                Swal.fire({
                    title: res.response.data.mensaje,
                    type: 'error',
                    showConfirmButton: false,
                    timer: 2000
                })
                
            });
        console.log(venta);
    }*/

    return (
        <tr>
            <th scope="row">{compra.idCompra}</th>
            <td>{compra.proveedor.nombre}</td>
            <td>${(compra.total).toFixed(2)}</td>
            <td>{estado(compra.estado)}</td>
            <td>{(compra.fecha).split('T')[0]}</td>
            <td>{compra.sucursal.nombre}</td>
            <td>{compra.tipoComprobante.nombre}</td>
            <td>{compra.numeroComprobante}</td>
            <td>
                <ModalDetallePago item={compra}/>
                <ModalDetalleCompra item={compra} />
            </td>
        </tr>
    );
}

export default withRouter(ItemCompras);