import axios from 'axios';
import {url} from './global';

export const savePresupuesto = async (presupuesto) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/presupuestos`,presupuesto,{headers});
    return res;
}

export const getPresupuestoPage = async (page, presupuesto) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/presupuestos/page/${page}`,presupuesto,{headers});
    return res;
}