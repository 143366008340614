import axios from 'axios';
import {url} from './global';

export const getAllArticulos = async () => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/articulos`,{headers});
    return res;
}

// PAGINATION
export const getAllArticulosPage = async (page, data) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.post(`${url}/articulos/page/${page}`, data,{headers});
    return res;
}
// END PAGINATION

export const getByIdArticulos = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/articulos-cod/${id}`,{headers});
    return res;
}

export const getAllArticulosByNombre = async (valor) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/articulos-like/${valor}`,{headers});
    return res;
}

export const editByIdArticulos = async (articulo) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/articulos/${articulo.idArticulo}`, articulo,{headers})
    return res;
}

export const deleteByIdArticulos = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.delete(`${url}/articulos/${id}`,{headers});
    return res;
}

export const saveArticulos = async (articulo) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/articulos`,articulo,{headers});
    return res;
}

export const saveArticulosCompuestos = async (articulo) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/articulos-compuestos`,articulo,{headers});
    return res;
}

// IMPORT ARTICULOS
export const saveArticulosImportar = async (data, sucursal) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "multipart/file",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/importar-articulos/${sucursal}`, data,{headers});
    return res;
}




