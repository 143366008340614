import React from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faMoneyBill } from '@fortawesome/free-solid-svg-icons'

// routes
import { withRouter } from 'react-router-dom';

// Sweet alert
// import Swal from 'sweetalert2';

// services
// import { deleteByIdCuentasCorriente } from '../../Services/cuentaCorrienteClienteService';

const ItemCuentaCorriente = ({ cuentaCorriente, history, setIsLoad, deleteCuentaCorriente }) => {


    const isDateNull = (fecha) => {
        if(fecha === null) return null;
        else {
            let fechaFinal = fecha.split('T')[0];
            return fechaFinal;
        }
            
    }

    // const eliminarCuentaCorrienteSubmit = (id) => {
    //     Swal.fire({
    //         title: 'Estas seguro de eliminar?',
    //         text: "Una cuentaCorriente eliminada no se puede recuperar!",
    //         type: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Si, Eliminar!',
    //         cancelButtonText: 'Cancelar'
    //     }).then((result) => {
    //         if (result.value) {
    //             // consulta a la api para eliminar
    //             deleteByIdCuentasCorriente(id)
    //                 .then(res => {
    //                     Swal.fire(
    //                         'Eliminado!',
    //                         'La cuenta corriente fue eliminada.',
    //                         'success'
    //                     );
    //                     // INVOCAMOS EL ACTION
    //                     deleteCuentaCorriente(id);
    //                     setIsLoad(true);
    //                 })
    //                 .catch(error => {
    //                     Swal.fire({
    //                         type: 'error',
    //                         title: 'Error',
    //                         text: 'Hubo un error vuelve a intentar!'
    //                     });
    //                     console.log(error.response);
    //                 })
    //         }
    //     })
    // }

    return (
        <tr key={parseInt(cuentaCorriente.idCuentaCorriente)}>
            <th scope="row">{cuentaCorriente.idCuentaCorriente}</th>
            <td>{cuentaCorriente.cliente.idCliente.persona.nombre + ', ' + cuentaCorriente.cliente.idCliente.persona.apellido }</td>
            <td>{cuentaCorriente.cliente.idCliente.persona.dni}</td>
            <td>${cuentaCorriente.debe}</td>
            <td>${cuentaCorriente.haber}</td>
            <td>${cuentaCorriente.montoMaximo}</td>
            <td>{cuentaCorriente.cantidadCuotasMaximas }</td>
            <td>{isDateNull(cuentaCorriente.fechaUltimoPago)}</td>
            <td>{isDateNull(cuentaCorriente.fechaProximoPago)}</td>
            <td>
                <button className="btn text-success"
                    onClick={() => { history.push(`/ingreso-pago-cliente`,{cuentaCorriente: cuentaCorriente}) }}>
                    <FontAwesomeIcon icon={faMoneyBill} /> Pagos
                </button>
                <button className="btn text-danger"
                    onClick={() => { history.push(`/edit-cuenta-corriente/${cuentaCorriente.idCuentaCorriente}`,{cuentaCorriente:cuentaCorriente}) }}>
                    <FontAwesomeIcon icon={faEdit} /> Editar
                </button>
                {/* <button className="btn text-primary"
                    onClick={() => eliminarCuentaCorrienteSubmit(cuentaCorriente.idCuentaCorriente)}>
                    <FontAwesomeIcon icon={faTrash} /> Eliminar
                </button> */}
            </td>
        </tr>
    );
}

export default withRouter(ItemCuentaCorriente);