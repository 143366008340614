import React from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

// services
import { deleteItemCaja } from '../../Services/cajaService';

// Sweet alert
import Swal from 'sweetalert2';

// router
import { withRouter } from 'react-router-dom';

const ItemCaja = ({ item, history, caja, setIsLoad }) => {


    const deleteItemGasto = (item) => {
        Swal.fire({
            title: 'Estas seguro de eliminar?',
            text: "Un item eliminado no se puede recuperar!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Eliminar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {

                let cajaNueva = {
                    idCaja: caja.idCaja,
                    detalles: [item],
                    abierta: true
                }

                deleteItemCaja(cajaNueva)
                    .then(res => {
                        Swal.fire(
                            'Eliminado!',
                            'El item fue eliminado.',
                            'success'
                        );
                        setIsLoad(true);
                    })
                    .catch(error => {
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Hubo un error vuelve a intentar!'
                        });
                    })
            }
        })
    }

    const motivo = (motivo) => {
        if (motivo === 'CONSUMO_PERSONAL') return 'VIATICOS DE PERSONAL';
        if (motivo === 'PAGO_PERSONAL') return 'PAGOS A PERSONAL';
        if (motivo === 'GASTO_LIBRERIA') return 'GASTOS DE LIBRERIA';
        if (motivo === 'ADELANTO_PLATA') return 'ADELANTO DE EFECTIVO A CENTRAL';
    }

    return (
        <tr>
            <th scope="row">{item.id}</th>
            <td>{motivo(item.motivoDetalleCaja)}</td>
            <td>{item.descripcion}</td>
            <td>${(item.monto).toFixed(2)}</td>
            <td>
                <button className="btn text-primary"
                    onClick={() => deleteItemGasto(item)}>
                    <FontAwesomeIcon icon={faTrash} /> Eliminar
                </button>
            </td>
        </tr>
    );
}

export default withRouter(ItemCaja);