import { GET_OFERTAS, GET_OFERTA, ADD_OFERTA, EDIT_OFERTA, DELETE_OFERTA } from '../Actions/Types';

// cada reducer tiene su propio state
const initialState = {
    ofertas: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_OFERTAS:
            return {
                ...state,
                ofertas: action.payload
            }
        case GET_OFERTA:
            return {
                ...state,
                oferta: action.payload
            }
        case ADD_OFERTA:
            return {
                ...state,
                ofertas: [...state.ofertas, action.payload]
            }
        case EDIT_OFERTA:
            return {
                ...state,
                ofertas: (state.ofertas.map(
                    oferta => oferta.idOferta === action.payload.idOferta
                        ? (oferta = action.payload)
                        : oferta
                ))
            }
        case DELETE_OFERTA:
            return {
                ...state,
                ofertas: state.ofertas.filter(oferta => oferta.idOferta !== action.payload)
            }
        default:
            return state;
    }
}
