import axios from 'axios';
import {url} from './global';

export const getAllDepartamentos = async () => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/departamentos`,{headers});
    return res;
}

export const getByIdDepartamentos = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/departamentos/${id}`,{headers});
    return res;
}

export const editByIdDepartamentos = async (departamento) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/departamentos/${departamento.idDepartamento}`, departamento,{headers});
    return res;
}

export const deleteByIdDepartamentos = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.delete(`${url}/departamentos/${id}`,{headers});
    return res;
}

export const saveDepartamentos = async (departamento) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/departamentos`,departamento,{headers});
    return res;
}
