import React, { Fragment, useEffect, useState, useRef } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMoneyBillWave, faList, faBookOpen, faDollarSign, faTrash, faCashRegister, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo';
import ItemCaja from './ItemCaja'

// route
import { withRouter } from 'react-router-dom';

// services
import { update, getCajaActivaByPtoVenta } from '../../Services/cajaService';
import { signOut } from '../../Services/usuarioService';

// alert swal
import Swal from 'sweetalert2'

const EditCaja = ({ history }) => {

    //let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));

    const [cierreCajaUI, setCierreCajaUI] = useState(false);

    const [cajaActual, setCajaActual] = useState({});
    const [detallesCaja, setDetallesCaja] = useState([]);

    const [motivo, setMotivo] = useState(null);
    const [descripcion, setDescripcion] = useState('');
    const [monto, setMonto] = useState(0);

    const [gastosProvisorios, setGastosProvisorios] = useState([]);

    const [isLoad, setIsLoad] = useState(true);

    let selectMotivoRef = useRef(null)

    useEffect(() => {
        let ptoVenta = JSON.parse(sessionStorage.getItem('ptoventa'));
        if (isLoad) {
            getCajaActivaByPtoVenta(ptoVenta.idPuntoDeVenta)
                .then(res => {
                    setCajaActual(res.data);
                    setDetallesCaja(res.data.detalles);
                    setIsLoad(false);
                })
                .catch(error => {
                    console.log(error.response);
                    setIsLoad(false);
                });

        } else {
            setIsLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);

    const handleGastoProvisorio = () => {

        // validations
        if (motivo === null) {
            Swal.fire({
                text: "Debe elegir un motivo",
                type: 'warning',
                showConfirmButton: false,
                timer: 1500
            });
            return;
        }

        if (descripcion.length <= 0) {
            Swal.fire({
                text: "La descripción no puede estar vacía.",
                type: 'warning',
                showConfirmButton: false,
                timer: 1500
            });
            return;
        }

        if (monto <= 0) {
            Swal.fire({
                text: "El monto no puede ser menor a cero.",
                type: 'warning',
                showConfirmButton: false,
                timer: 1500
            });
            return;
        }

        let gastoProvisorio = {
            motivoDetalleCaja: motivo, descripcion, monto
        }

        setGastosProvisorios([...gastosProvisorios, gastoProvisorio]);
        setMotivo(null);
        setDescripcion('');
        setMonto(0);
        selectMotivoRef.current.focus()
    }

    const submitChangeCaja = () => {

        if (gastosProvisorios.length <= 0) {
            Swal.fire({
                text: "Debe ingresar gastos.",
                type: 'warning',
                showConfirmButton: false,
                timer: 1500
            });
            return;
        }

        let cajaNueva = {
            idCaja: cajaActual.idCaja,
            detalles: gastosProvisorios,
            abierta: true
        }

        update(cajaNueva)
            .then(res => {                
                let ptoVenta = JSON.parse(sessionStorage.getItem('ptoventa'));
                getCajaActivaByPtoVenta(ptoVenta.idPuntoDeVenta)
                    .then(async res => {
                        
                        await setCajaActual(res.data);
                        await setDetallesCaja(res.data.detalles);
                        setGastosProvisorios([]);
                        setIsLoad(false);
                    })
                    .catch(error => {
                        console.log(error.response);
                        setIsLoad(false);
                    });
            })
            .catch(error => {
                Swal.fire({
                    type: 'warning',
                    title: 'Error',
                    text: 'No se pudo añadir los gastos!',
                    showConfirmButton: false,
                    timer: 2000
                });
            })

    }

    const motivoState = (motivo) => {
        if (motivo === 'CONSUMO_PERSONAL') return 'VIATICOS DE PERSONAL';
        if (motivo === 'PAGO_PERSONAL') return 'PAGOS A PERSONAL';
        if (motivo === 'GASTO_LIBRERIA') return 'GASTOS DE LIBRERIA';
        if (motivo === 'ADELANTO_PLATA') return 'ADELANTO DE EFECTIVO A CENTRAL';
    }

    const deleteGastosProvisorios = (monto) => {

        let gastosFilter = gastosProvisorios.filter(function (gp) {
            return parseFloat(gp.monto) !== parseFloat(monto);
        });

        setGastosProvisorios(gastosFilter);
    }

    const cierreCaja = () => {
        Swal.fire({
            title: 'Estas seguro desea cerra la caja?',
            text: "Una caja cerrada no se puede volver a abrir!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                // consulta a la api para eliminar
                let cajaCerrada = {
                    idCaja: cajaActual.idCaja,
                    detalles: detallesCaja,
                    abierta: false
                }
                
                update(cajaCerrada)
                    .then(res => {
                        Swal.fire({
                            type: 'success',
                            title: 'Caja Cerrada',
                            text: 'La caja se cerro exitosamente!',
                            showConfirmButton: false,
                            timer: 2000
                        });
                        signOut();
                        history.push('/');

                    })
                    .catch(error => {
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Hubo un error vuelve a intentar!',
                            showConfirmButton: false,
                            timer: 1500
                        });
                        console.log(error.response);
                    })
            }
        })
    }

    const handleFinalItems = (e, item) => {
        detallesCaja.map((dc) => {
            if (parseInt(dc.id) === parseInt(item.id)) {
                dc[e.target.name] = e.target.value
                return dc
            } else {
                return dc
            }
        });
    }



    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faMoneyBillWave}
                        title={'Caja'} />
                </div>

                {(!cierreCajaUI) ?
                    <div className="bg-gris p-3">

                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="inputMotivos">Motivos</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faList} />
                                    </span>
                                    <select id="inputMotivo" className="custom-select" name="motivo"
                                        ref={selectMotivoRef} value={motivo} onChange={(e) => setMotivo(e.target.value)}>
                                        <option value='null'>-- Seleccione un motivo --</option>
                                        <option value='PAGO_PERSONAL'>PAGO A PERSONAL</option>
                                        <option value='CONSUMO_PERSONAL'>CONSUMO VIATICOS DE PERSONAL</option>
                                        <option value='GASTO_LIBRERIA'>GASTOS DE LIBRERIA</option>
                                        <option value='ADELANTO_PLATA'>ADELANTO DE EFECTIVO A CENTRAL</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputdescripcion">descripcion</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faBookOpen} />
                                    </span>
                                    <input type="text"
                                        className="form-control"
                                        id="inputdescripcion"
                                        name="descripcion"
                                        value={descripcion}
                                        placeholder="ingrese detalles del gasto"
                                        onChange={e => setDescripcion(e.target.value)} />
                                </div>

                            </div>
                            
                            <div className="form-group col-md-4">
                                <label htmlFor="inputMonto">Monto</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faDollarSign} />
                                    </span>
                                    <input type="number"
                                        step="any"
                                        className="form-control"
                                        id="inputMonto"
                                        name="monto"
                                        value={monto}
                                        placeholder="ingrese un monto en pesos"
                                        onChange={e => setMonto(e.target.value)} />
                                </div>

                            </div>



                        </div>
                        <div className="form-row justify-content-center">

                            <button className="btn btn-outline-info m-1"
                                onClick={() => handleGastoProvisorio()}>
                                Añadir gasto <FontAwesomeIcon icon={faPlus} />
                            </button>

                            <button className="btn btn-outline-success m-1"
                                onClick={() => submitChangeCaja()}>
                                Guardar cambios <FontAwesomeIcon icon={faPlus} />
                            </button>

                            <button className="btn btn-outline-primary m-1"
                                onClick={() => setCierreCajaUI(true)}>
                                Cierre caja <FontAwesomeIcon icon={faCashRegister} />
                            </button>
                        </div>

                        <div className="row border m-2 p-2">
                            {gastosProvisorios.map((gp) => {

                                return (
                                    <div class="card bg-light m-2 justify-content-center " >
                                        <div class="card-header">{motivoState(gp.motivoDetalleCaja)}</div>
                                        <div class="card-body">
                                            <h5 class="card-title">Monto: ${gp.monto}</h5>
                                            <p class="card-text">Descripcion: {gp.descripcion}</p>
                                            <button className="btn btn-primary"
                                                onClick={() => deleteGastosProvisorios(gp.monto)}>
                                                Borrar <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>

                        <div className="row">
                            <h4>Gastos de Caja</h4>
                            <table className="table table-striped mt-3 text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Motivo</th>
                                        <th scope="col">Descripcion</th>
                                        <th scope="col">Monto</th>
                                        <th scope="col">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {detallesCaja.map((item) => {
                                        if (item.motivoDetalleCaja === 'EFECTIVO' || item.motivoDetalleCaja === 'TARJETA') {
                                            return null;
                                        } else {
                                            return (
                                                <ItemCaja
                                                    key={parseInt(item.id, 10)}
                                                    setIsLoad={setIsLoad}
                                                    caja={cajaActual}
                                                    item={item} />
                                            );
                                        }

                                    })}
                                </tbody>
                            </table>

                        </div>


                    </div>
                    :

                    <div className="bg-gris p-3">

                        <div className="row justify-content-between">
                            <button className="col-2 btn btn-outline-info ml-2"
                                onClick={() => setCierreCajaUI(false)}>
                                <FontAwesomeIcon icon={faArrowLeft} /> Volver
                            </button>
                            <h3 className="col text-center">Cierre de Caja</h3>
                            <button className="col-2 btn btn-primary mr-2"
                                onClick={() => cierreCaja()}>
                                <FontAwesomeIcon icon={faCashRegister} /> Cerrar Caja
                            </button>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-6">
                                <h4>Efectivo</h4>
                                <table className="table table-striped mt-3 text-center">
                                    <thead>
                                        <tr>
                                            <th scope="col">Descripcion</th>
                                            <th scope="col">Cant. de billetes</th>
                                            <th scope="col">Monto</th>
                                            <th scope="col">Observacion</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {detallesCaja.map((item) => {
                                            console.log(item.motivoDetalleCaja);
                                            if (item.motivoDetalleCaja === 'EFECTIVO') {
                                                return (
                                                    <tr>
                                                        <td>{item.descripcion}</td>
                                                        <td>
                                                            <input type="number"
                                                                className="form-control"
                                                                id={"cantidadBilletes" + item.id}
                                                                name="cantidadBilletes"
                                                                defaultValue={item.cantidadBilletes}
                                                                onChange={e => handleFinalItems(e, item)} />
                                                        </td>
                                                        <td>
                                                            <input type="number"
                                                                step="any"
                                                                className="form-control"
                                                                id={"monto" + item.id}
                                                                name="monto"
                                                                defaultValue={item.monto}
                                                                onChange={e => handleFinalItems(e, item)} />
                                                        </td>
                                                        <td>
                                                            <input type="text"
                                                                step="any"
                                                                className="form-control"
                                                                id={"observacion" + item.id}
                                                                name="observacion"
                                                                defaultValue={item.observacion}
                                                                onChange={e => handleFinalItems(e, item)} />
                                                        </td>
                                                    </tr>
                                                );
                                            } else {
                                                return null
                                            }

                                        })}
                                    </tbody>
                                </table>

                            </div>
                            <div className="col-6">
                                <h4>Tarjeta</h4>
                                <table className="table table-striped mt-3 text-center">
                                    <thead>
                                        <tr>
                                            <th scope="col">Descripcion</th>
                                            <th scope="col">Cant. de cupones</th>
                                            <th scope="col">Monto</th>
                                            <th scope="col">Observacion</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {detallesCaja.map((item) => {
                                            if (item.motivoDetalleCaja === 'TARJETA') {
                                                return (
                                                    <tr>
                                                        <td>{item.descripcion}</td>
                                                        <td>
                                                            <input type="number"
                                                                className="form-control"
                                                                id={"cantidadCupones" + item.id}
                                                                name="cantidadCupones"
                                                                defaultValue={item.cantidadCupones}
                                                                onChange={e => handleFinalItems(e, item)} />
                                                        </td>
                                                        <td>
                                                            <input type="number"
                                                                step="any"
                                                                className="form-control"
                                                                id={"monto" + item.id}
                                                                name="monto"
                                                                defaultValue={item.monto}
                                                                onChange={e => handleFinalItems(e, item)} />
                                                        </td>
                                                        <td>
                                                            <input type="text"
                                                                step="any"
                                                                className="form-control"
                                                                id={"observacion" + item.id}
                                                                name="observacion"
                                                                defaultValue={item.observacion}
                                                                onChange={e => handleFinalItems(e, item)} />
                                                        </td>
                                                    </tr>
                                                );
                                            } else {
                                                return null
                                            }

                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>



                    </div>
                }


            </div>
        </Fragment>
    );
}

export default withRouter(EditCaja);