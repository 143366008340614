import axios from 'axios';
import {url} from './global';


export const getAllUnidadMedidas = async () => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/unidades-medidas`,{headers});
    return res;
}

export const getByIdUnidadMedida = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/unidades-medidas/${id}`,{headers});
    return res;
}

export const editByIdUnidadMedida = async (unidadMedida) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/unidades-medidas/${unidadMedida.idUnidadMedida}`, unidadMedida,{headers});
    return res;
}

export const deleteByIdUnidadMedida = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.delete(`${url}/unidades-medidas/${id}`,{headers});
    return res;
}

export const saveUnidadMedida = async (unidadMedida) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/unidades-medidas`,unidadMedida,{headers});
    return res;
}
