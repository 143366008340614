import React, { Fragment, useEffect, useState } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSearch, faTruckMoving } from '@fortawesome/free-solid-svg-icons'

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo';

// redux
import { useSelector, useDispatch } from 'react-redux'

// actions
import { GetProveedores, DeleteProveedor } from '../../Actions/ProveedorAction';

// route
import { withRouter } from 'react-router-dom';

// services
import { getAllProveedores } from '../../Services/proveedorService';

// components
import ItemProveedor from './ItemProveedor';


const Proveedor = ({ history }) => {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    let rol = token.authorities[0];

    const [isLoad, setIsLoad] = useState(true);
    const proveedores = useSelector(state => state.proveedores.proveedores);
    const dispatch = useDispatch();
    const deleteProveedor = (id) => dispatch(DeleteProveedor(id));

    useEffect(() => {   
        if (rol !== 'ROLE_ADMIN') history.goBack();
        if (isLoad) {
            getAllProveedores()
                .then(res => {
                    dispatch(GetProveedores(res));
                    setIsLoad(false);
                })
                .catch(err => console.log('error'));
        } else {
            setIsLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);

    const onChangeProveedor = e => {
        let valor = e.target.value;
        if(valor === '') {
            /* getAllMarcas()
                .then(res => dispatch(GetMarcas(res)))
                .catch(error => console.log(error.response)); */
        }else{
            // find like dni
            /* getAllMarcasByNombre(valor)
            .then(res => dispatch(GetMarcas(res)))
            .catch(error => console.log(error.response));
            console.log(valor); */
        }
    }

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faTruckMoving}
                        title={'Proveedores'} />
                </div>

                <div className="bg-gris p-3">

                    <div className="d-flex bd-highlight">
                        <div className="p-2 bd-highlight">
                            <button className="btn btn-outline-success btn-lg"
                                onClick={() => { history.push('/add-proveedor') }}>
                                <FontAwesomeIcon icon={faPlus} /> Crear
                            </button>
                        </div>

                        <div className="p-2 flex-grow-1 bd-highlight">
                            <div className="input-group-prepend input-group-lg">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faSearch} />
                                </span>

                                <input type="text"
                                    className="form-control form-lg"
                                    id="inputNombre"
                                    name="precio"
                                    placeholder="Buscar"
                                    onChange={e => onChangeProveedor(e)}
                                />
                            </div>
                        </div>
                    </div>

                    <table className="table table-striped mt-3 text-center">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Telefono</th>
                                <th scope="col">Direccion</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>

                            {proveedores.map((proveedor) => (
                                <ItemProveedor
                                    key={parseInt(proveedor.idProveedor)}
                                    proveedor={proveedor}
                                    deleteProveedor={deleteProveedor}
                                    setIsLoad={setIsLoad} />
                            ))}

                        </tbody>
                    </table>

                </div>

            </div>
        </Fragment>
    );
}

export default withRouter(Proveedor);