import React from 'react';

// router
import { withRouter } from 'react-router-dom';

const ItemDetalleMedioPago = ({ item }) => {

    return (
        <tr>
            <th scope="row">{item.id}</th>
            <td>{item.medioPago.nombre}</td>
            <td>${(item.monto).toFixed(2)}</td>
            <td>${item.recargo}</td>
            <td>${item.descuento}</td>
        </tr>
    );
}

export default withRouter(ItemDetalleMedioPago);