import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo'

import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBirthdayCake, faMapMarkedAlt, faIdCardAlt,
    faIdCard, faMobileAlt, faAt, faUserEdit, faUserCircle, faEdit, faUserTie
} from '@fortawesome/free-solid-svg-icons'

import { EditaEmpleado } from '../../Actions/EmpleadoAction';
import { editByIdEmpleado } from '../../Services/empleadoService';
import { getAllCargos } from '../../Services/cargoService';

//Formik
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';

const EditEmpleado = ({ history, location }) => {

    const empleado = location.state.empleado;
    
    const [isLoad, setIsLoad] = useState(true);
    const [cargos, setCargos] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isLoad) {
            getAllCargos()
                .then(res => {
                    setCargos(res.data);
                    setIsLoad(false);
                })
                .catch(error => console.log(error.response));
            setIsLoad(false);
        } else {
            setIsLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formik = useFormik({
        initialValues: {
            nombre: empleado.idEmpleado.persona.nombre,
            apellido: empleado.idEmpleado.persona.apellido,
            dni: empleado.idEmpleado.persona.dni,
            cuilCuit: empleado.idEmpleado.persona.cuilCuit,
            fechaNacimiento: empleado.idEmpleado.persona.fechaNacimiento,
            correo: empleado.idEmpleado.persona.correo,
            telefono: empleado.idEmpleado.persona.telefono,
            direccion: empleado.idEmpleado.persona.direccion,
            cargo: empleado.cargo
        },
        validationSchema: Yup.object({
            nombre: Yup.string()
                .required("Obligatorio."),
            dni: Yup.number()
                .required("Obligatorio."),
            cargo: Yup.object().required("Obligatorio")
        }),
        onSubmit: values => editEmpleadoSubmit(values)
    })

    const editEmpleadoSubmit = ({nombre,apellido,correo,telefono,dni,direccion,cuilCuit,fechaNacimiento,cargo}) => {
        //Persona
        let persona = {
            idPersona: empleado.idEmpleado.persona.idPersona,
            nombre,
            apellido,
            dni,
            cuilCuit,
            fechaNacimiento,
            correo,
            telefono,
            direccion
        }
        
        // Cliente
        let empleadoUpdated = {
            idEmpleado: { persona: persona },
            cargo: cargo
        }
        
        // edito el cliente
        editByIdEmpleado(empleadoUpdated)
            .then(res => {
                dispatch(EditaEmpleado(res));
                history.goBack();
            })
            .catch(error => {
                Swal.fire({
                    text: 'Error al actualizar el empleado, verifique los datos.',
                    type: 'error',
                    showConfirmButton: false,
                    timer: 1500
                });
            });

    }

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faUserTie}
                        title={'Empleados'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputNombre">Nombre *</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faUserCircle} />
                                    </span>
                                    <input type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        className="form-control"
                                        placeholder="Ej. Jose Manuel"
                                        value={formik.values.nombre}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}   
                                    />
                                </div>
                                {formik.touched.nombre && formik.errors.nombre ?
                                    <small className="form-text text-danger">
                                        {formik.errors.nombre}
                                    </small>
                                    : null
                                }
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputApellido">Apellido</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faUserEdit} />
                                    </span>
                                    <input type="text"
                                        className="form-control"
                                        id="inputApellido"
                                        name="apellido"
                                        placeholder="Ej Gutierrez..."
                                        value={formik.values.apellido}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}   
                                    />
                                </div>
                            </div>
                            
                        </div>

                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputDni">DNI *</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faIdCard} />
                                    </span>
                                    <input type="text"
                                        id="inputDni"
                                        name="dni"
                                        className="form-control"
                                        placeholder="Ej. 20123456"
                                        value={formik.values.dni}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}   
                                    />
                                </div>
                                {formik.touched.dni && formik.errors.dni ?
                                    <small className="form-text text-danger">
                                        {formik.errors.dni}
                                    </small>
                                    : null
                                }
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputCuilCuit">CUIL/CUIT</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faIdCardAlt} />
                                    </span>
                                    <input type="text"
                                        className="form-control"
                                        id="inputCuilCuit"
                                        name="cuilCuit"
                                        placeholder="Ej. 20201234562"
                                        value={formik.values.cuilCuit}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}  
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputFechaNacimiento">Fecha Nacimiento</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faBirthdayCake} />
                                    </span>
                                    <input type="date"
                                        className="form-control"
                                        id="inputFechaNacimiento"
                                        name="fechaNacimiento"
                                        value={formik.values.fechaNacimiento ? formik.values.fechaNacimiento.split('T')[0] : null}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}  
                                    />
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputCorreo">Correo Electronico</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faAt} />
                                    </span>
                                    <input type="text"
                                        id="inputCorreo"
                                        name="correo"
                                        className="form-control"
                                        placeholder="Ej. perez@email.com"
                                        value={formik.values.correo}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}   
                                    />
                                </div>
                            </div>

                        </div>


                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputTelefono">Telefono</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faMobileAlt} />
                                    </span>
                                    <input type="text"
                                        className="form-control"
                                        id="inputTelefono"
                                        name="telefono"
                                        placeholder="Ej. +54381234098"
                                        value={formik.values.telefono}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}   
                                    />
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputDireccion">Direccion</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faMapMarkedAlt} />
                                    </span>
                                    <input type="text"
                                        id="inputDireccion"
                                        name="direccion"
                                        className="form-control"
                                        placeholder="Ej. Pje. La Paz 123"
                                        value={formik.values.direccion}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}  
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputCargo">Cargos</label>
                                <div>
                                    <Select
                                        placeholder={"Seleccionar un cargo"}
                                        options={cargos}
                                        defaultValue={formik.values.cargo}
                                        onChange={value=>formik.setFieldValue('cargo',value)}
                                        getOptionValue={(values) => values.idCargo}
                                        getOptionLabel={(values) => values.nombre}
                                    >
                                    </Select>
                                </div>
                                {formik.touched.cargo && formik.errors.cargo ?
                                    <small className="form-text text-danger">
                                        {formik.errors.cargo}
                                    </small>
                                : null}
                            </div>

                        </div>

                        <div className="text-center">
                            <button type="submit" className="btn btn-outline-success">
                                <FontAwesomeIcon icon={faEdit} /> Editar Empleado</button>
                        </div>

                    </form>
                </div>

            </div>
        </Fragment>
    );
}

export default withRouter(EditEmpleado);