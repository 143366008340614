import React, { Fragment, Component } from 'react';

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo'


// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBalanceScale, faEdit, faSpellCheck, faUnlockAlt } from '@fortawesome/free-solid-svg-icons'

// router
import { connect } from 'react-redux';

// actions
import { EditaUnidadMedida, GetUnidadMedida } from '../../Actions/UnidadMedidaAction'

// services
import { getByIdUnidadMedida, editByIdUnidadMedida } from '../../Services/unidadMedidaService';

class EditUnidadMedida extends Component {

    state = {
        nombre: '',
        abreviacion: '',
        activo: '',
        error: false
    }

    componentDidMount() {
        const { id } = this.props.match.params;

        getByIdUnidadMedida(id)
            .then(res => {
                this.props.getUnidadMedida(res);
                const { nombre, abreviacion, activo } = this.props.medida;
                this.setState({ nombre, abreviacion, activo });
            })
            .catch(err => console.log(err));

    }

    handleMedida = e => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    editarUnidadMedidaSubmit = (e) => {
        e.preventDefault();

        // destructuring
        const { nombre, abreviacion, activo } = this.state;
        const { id } = this.props.match.params;

        // validations
        if (nombre === '' || abreviacion === '') {
            this.setState({ error: true });
            return;
        }

        // creo el departamento actualizado
        const medidaUpdated = {
            idUnidadMedida: parseInt(id, 10), nombre, abreviacion, activo
        }

        editByIdUnidadMedida(medidaUpdated)
            .then(res => {
                this.props.editarUnidadMedida(res);
                this.setState({ error: false, nombre: '', abreviacion: '', activo: '' });
                this.props.history.goBack();
            })
            .catch(error => {
                console.log(error.response.data);
                this.setState({ error: true });
            });

    }

    render() {

        const { nombre, abreviacion, activo } = this.state

        return (
            <Fragment>
                <div className="container-fluid mt-3">
                    <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                        <BotonBack />
                        <Subtitulo
                            icon={faBalanceScale}
                            title={'Unidad de Medidas'} />
                    </div>

                    <div className="bg-gris p-4">
                        <form onSubmit={this.editarUnidadMedidaSubmit}>
                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label htmlFor="inputNombre">Nombre</label>
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroupPrepend">
                                            <FontAwesomeIcon icon={faBalanceScale} />
                                        </span>
                                        <input type="text"
                                            id="inputNombre"
                                            name="nombre"
                                            className="form-control"
                                            placeholder="Ej. Lacteos, Gaseosas..."
                                            defaultValue={nombre}
                                            onChange={this.handleMedida} />
                                    </div>
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="inputAbreviacion">Abreviacion</label>
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroupPrepend">
                                            <FontAwesomeIcon icon={faSpellCheck} />
                                        </span>
                                        <input type="text"
                                            className="form-control"
                                            id="inputAbreviacion"
                                            name="abreviacion"
                                            placeholder="Ej. kg, lts..."
                                            defaultValue={abreviacion}
                                            onChange={this.handleMedida} />
                                    </div>
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="inputActivo">Estado</label>
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroupPrepend">
                                            <FontAwesomeIcon icon={faUnlockAlt} />
                                        </span>
                                        <select id="inputActivo" className="custom-select" name="activo"
                                            value={activo} onChange={this.handleMedida}>
                                            <option value={true}>Activo</option>
                                            <option value={false}>No Activo</option>
                                        </select>
                                    </div>
                                </div>
                            </div>


                            <div className="text-center">
                                <button type="submit" className="btn btn-outline-danger">
                                    <FontAwesomeIcon icon={faEdit} /> Editar Medida</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    medida: state.medidas.medida
})

const mapDispatchToProps = dispatch => {
    return {
        getUnidadMedida: (id) => dispatch(GetUnidadMedida(id)),
        editarUnidadMedida: (medida) => dispatch(EditaUnidadMedida(medida))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUnidadMedida);