import React, { Fragment, useState, useRef, useEffect } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faTrashAlt, faList, faCheck} from '@fortawesome/free-solid-svg-icons'

// component
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo';
import ModalArticuloPedido from './ModalArticuloPedido/ModalArticuloPedido';


// route
import { withRouter } from 'react-router-dom';

// css
import './PedidoSucursal.css';


// services
import { getByIdArticulos } from '../../Services/articuloService';
import { savePedido } from '../../Services/pedidoService';
import { getAllSucursales} from '../../Services/sucursalService';
import { getArticuloByIdStockSucursalAndIdArticulo } from '../../Services/stockSucursalService';



// Sweet alert
import Swal from 'sweetalert2';


const AddPedidoSucursal = ({history}) => {

    let token = sessionStorage.getItem('token');
    let datos = JSON.parse(atob(token.split('.')[1]));

    // datos de factura
    const [sucursales, setSucursales] = useState([]);
    const [sucursal, setSucursal] = useState({nombre: ''});
    const [sucursalDestino, setSucursalDestino] = useState();
    const [itemsPedido, setItemsPedido] = useState([]);

    const [items, setItems] = useState([]);
    const [codigoArticulo, setCodigoArticulo] = useState();
    const [cantidadArticulo, setcantidadArticulo] = useState('');

    const inputTotalRef = useRef(null);
    const fecha = new Date().toISOString().split("T")[0];
    const [isLoad, setIsLoad] = useState(true);


    useEffect(() => {
        if (isLoad) {
            inputTotalRef.current.focus();


            setSucursal(datos.empleado.sucursal);

            // cargo sucursales
            getAllSucursales()
            .then(async res => {
                setSucursales(res.data);
            }) 
            .catch(error => console.log(error));

        } else {
            setIsLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);

    // manejo de input cuando agregan articulos
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            // codigo y cantidad

            // validar que el articulo no existe en los detalles
            let isPresent = false;
            items.forEach(item => {
                if (parseInt(item.idArticulo, 10) === parseInt(codigoArticulo, 10) ||
                        parseInt(item.codigo, 10) === parseInt(codigoArticulo, 10)) isPresent = true;
            });

            // si ya se encuentra presento salgo de la funcion
            if (isPresent) {
                Swal.fire({
                    title: 'El articulo ya se ingreso en esta venta',
                    type: 'info',
                    showConfirmButton: false,
                    timer: 1000
                })
                return;
            }

            getByIdArticulos(codigoArticulo)
                .then( async data => {
                    // datos para calcular precio articulo
                    let articulo = data.data;

                    let stock = await getArticuloByIdStockSucursalAndIdArticulo(sucursal.idSucursal, articulo.idArticulo);
                    // verifico que la cantidad a enviar sea menor al stock
                    if(parseFloat(cantidadArticulo) > parseFloat(stock.data.cantidad)) {
                        Swal.fire({
                            title: 'No hay suficiente stock para la cantidad que solicita',
                            type: 'info',
                            showConfirmButton: false,
                            timer: 1000
                        })
                        return;
                    }

                    

                    // items para mostra detalle en pantalla
                    let item = {
                        idArticulo: articulo.idArticulo,
                        codigo: articulo.codigo,
                        articulo: articulo.nombre,
                        marca: articulo.marca.nombre,
                        cantidad: parseFloat(cantidadArticulo, 10),
                    }

                    // items para guardar en la base de datos
                    let itemPedido = {
                        articulo: articulo,
                        cantidad: item.cantidad,
                        estado: 'SIN_ESTADO',
                        observaciones: 'SIN OBSERVACIONES'
                    }

                    // ingresamos items que se guardaran en la bd
                    setItemsPedido([...itemsPedido, itemPedido]);
                    // ingresamos items de  que se mostraran en pantalla
                    setItems([...items, item]);

                    setCodigoArticulo('');
                    setcantidadArticulo('');
                    inputTotalRef.current.focus();
                })
                .catch(error => console.log(error));
        }

    }

    // elimina items de la lista de articulos
    const deleteItem = (articulo) => {
        let itemsDelete = items.filter((item) => {
            return parseInt(item.idArticulo, 10) !== parseInt(articulo.idArticulo, 10);
        });

        let itemsPedidoDelete = itemsPedido.filter((item) => {
            return parseInt(item.articulo.idArticulo, 10) !== parseInt(articulo.idArticulo, 10);
        });

        setItemsPedido(itemsPedidoDelete);
        setItems(itemsDelete);
    }

    // cancela la venta por completo y hace un reset
    const cancelarVenta = () => {
        setItems([]);
        setItemsPedido([]);
        inputTotalRef.current.focus();
    }

    const finalizarVenta = () => {

        // suma de puntos para ingresar al cliente
        let sucursalFilter = sucursales.filter(function (suc) {
            return parseInt(suc.idSucursal, 10) === parseInt(sucursalDestino, 10);
        });
    
        // completamos los datos de la venta
        let pedido = {
           sucursalSalida: sucursal, sucursalDestino: sucursalFilter[0],
           empleadoSalida: datos.empleado,
           estado : 'ENVIADO', items: itemsPedido
        }

        // ingresamos el pedido en la bd
        savePedido(pedido)
                .then(res => {
                    history.goBack();
                    Swal.fire({
                        title: 'La transaccion fue creada exitosamente',
                        type: 'success',
                        showConfirmButton: false,
                        timer: 2000
                    })
                })
                .catch(res => {
                    Swal.fire({
                        title: 'Error',
                        text: res.response.data.mensaje,
                        type: 'warning',
                        showConfirmButton: false,
                        timer: 2000
                    })
                }); 


    }

    return (

        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faList}
                        title={'Transferencias'} />
                </div>

                <div className="bg-gris centrado">

                    <div>
                        <div className="form-row justify-content-between">
                            <div className="form-group col-md-4 text-left">
                                <label htmlFor="inputNumero">Sucursal Salida</label>
                                <div className="input-group-prepend">
                                    <input type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        className="form-control"
                                        defaultValue={sucursal.nombre}
                                        disabled
                                        placeholder="Nombre de Sucursal" />

                                </div>
                            </div>

                            <div className="form-group col-md-4 text-left">
                                <label htmlFor="inputNumero">Sucursal Destino</label>
                                <div className="input-group-prepend">
                                <select id="inputCondicionFiscal" className="custom-select" name="condicionFiscal"
                                        onChange={(e) => setSucursalDestino(e.target.value)}>
                                        <option value={null}>-- Seleccione una sucursal --</option>
                                        {sucursales.map( (s) => {
                                            return (parseInt(s.idSucursal) !== parseInt(sucursal.idSucursal))? 
                                                <option key={parseInt(s.idSucursal)} value={s.idSucursal}>{s.nombre}</option>
                                                : null;
                                        })}
                                    </select>

                                </div>
                            </div>
                
                            <div className="form-group col-md-4 text-left">
                                <label htmlFor="inputFecha">Fecha Salida</label>
                                <div className="input-group-prepend">

                                    <input type="date"
                                        className="form-control"
                                        id="inputFecha"
                                        value={fecha}
                                        disabled
                                        name="fecha" />
                                </div>
                            </div>
                        </div>


                        <div className="d-flex bd-highlight">
                                <div className="p-2 bd-highlight">
                                    <ModalArticuloPedido
                                        setCodigoArticulo={setCodigoArticulo}
                                    />
                                </div>
                                <div className="p-2 flex-grow-1 bd-highlight">
                                    <input className="form-control form-control-lg"
                                        style={{ height: 45 }}
                                        type="text"
                                        placeholder="Ingrese un articulo"
                                        value={codigoArticulo}
                                        ref={inputTotalRef}
                                        onChange={(e) => setCodigoArticulo(e.target.value)}
                                        onKeyPress={(e) => handleKeyPress(e)} />
                                </div>
                                <div className="p-2 flex-grow-1 bd-highlight">
                                    <input className="form-control form-control-lg"
                                        style={{ height: 45 }}
                                        type="text"
                                        placeholder="cantidad"
                                        value={cantidadArticulo}
                                        onChange={(e) => setcantidadArticulo(e.target.value)}
                                        onKeyPress={(e) => handleKeyPress(e)} />
                                </div>
                                
                            </div>

                        <table className="table">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Producto</th>
                                    <th scope="col">Marca</th>
                                    <th scope="col">Cant.</th>
                                    <th scope="col">Eliminar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(items) ?
                                    items.map((item) => {
                                        return (
                                            <tr key={parseInt(item.idArticulo,10)}>
                                                <th scope="row"><h4>{item.idArticulo}</h4></th>
                                                <td><h4>{item.articulo}</h4> </td>
                                                <td><h4>{item.marca}</h4> </td>
                                                <td>
                                                    <div className="input-group form-row justify-content-center">

                                                        <input type="text" className="form-control col-md-3"
                                                            style={{ textAlign: "center", fontSize: 13, fontWeight: "bold" }}
                                                            defaultValue={item.cantidad} disabled />

                                                    </div>
                                                </td>
                                                <td>
                                                    <button className="btn btn-outline-primary"
                                                        onClick={() => deleteItem(item)}>
                                                        <FontAwesomeIcon icon={faTrashAlt} />
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })
                                    :
                                    null
                                }


                            </tbody>
                        </table>

                        <div className="row justify-content-center">
                            <button className="btn btn-outline-success btn-lg col-md-4 mr-1"
                                onClick={() => finalizarVenta()}>
                                Crear transferencia <FontAwesomeIcon icon={faCheck} />
                            </button>
                            <button className="btn btn-outline-primary btn-lg col-md-4"
                                onClick={() => cancelarVenta()}>
                                Borrar <FontAwesomeIcon icon={faTimesCircle} />
                            </button>
                        </div>
                    </div>



                </div>

            </div>
        </Fragment>
    );

}

export default withRouter(AddPedidoSucursal);