import { GET_PERSONAS, GET_PERSONA, ADD_PERSONA, EDIT_PERSONA, DELETE_PERSONA } from '../Actions/Types';

// cada reducer tiene su propio state
const initialState = {
    personas: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PERSONAS:
            return {
                ...state,
                personas: action.payload
            }
        case GET_PERSONA:
            return {
                ...state,
                persona: action.payload
            }
        case ADD_PERSONA:
            return {
                ...state,
                personas: [...state.personas, action.payload]
            }
        case EDIT_PERSONA:
            return {
                ...state,
                personas: (state.personas.map(
                    persona => persona.idPersona === action.payload.idPersona
                        ? (persona = action.payload)
                        : persona
                ))
            }
        case DELETE_PERSONA:
            return {
                ...state,
                personas: state.personas.filter(persona => persona.idPersona !== action.payload)
            }
        default:
            return state;
    }
}
