import axios from 'axios';
import {url} from './global';

export const getAllFamilias = async () => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/familias`,{headers});
    return res;
}

export const getAllFamiliasByNombre = async (nombre) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/familias-like/${nombre}`,{headers});
    return res;
}

export const getByIdFamilias = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/familias/${id}`,{headers});
    return res;
}

export const editByIdFamilias = async (familia) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/familias/${familia.idFamilia}`, familia,{headers});
    return res;
}

export const deleteByIdFamilias = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.delete(`${url}/familias/${id}`,{headers});
    return res;
}

export const saveFamilias = async (familia) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/familias`,familia,{headers});
    return res;
}
