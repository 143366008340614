import {
    GET_LISTAS_PRECIOS, GET_LISTA_PRECIO, ADD_LISTA_PRECIO, EDIT_LISTA_PRECIO, DELETE_LISTA_PRECIO
} from '../Actions/Types';

// cada reducer tiene su propio state
const initialState = {
    listasPrecios: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_LISTAS_PRECIOS:
            return {
                ...state,
                listasPrecios: action.payload
            }
        case GET_LISTA_PRECIO:
            return {
                ...state,
                listaPrecio: action.payload
            }
        case ADD_LISTA_PRECIO:
            return {
                ...state,
                listasPrecios: [...state.listasPrecios, action.payload]
            }
        case EDIT_LISTA_PRECIO:
            return {
                ...state,
                listasPrecios: (state.listasPrecios.map(
                    listaPrecio => listaPrecio.idListaPrecio === action.payload.idListaPrecio
                        ? (listaPrecio = action.payload)
                        : listaPrecio
                ))
            }
        case DELETE_LISTA_PRECIO:
            return {
                ...state,
                listasPrecios: state.listasPrecios.filter(listaPrecio => listaPrecio.idListaPrecio !== action.payload)
            }
        default:
            return state;
    }
}
