import React, { Fragment, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { sendPresupuestoPorMail } from '../../../Services/reporteService';
import Swal from 'sweetalert2';




export default function ModalEnviarPresupuesto(presupuesto) {

    const [destinatario, setDestinatario] = useState('');
    const [asunto, setAsunto] = useState('El Nogal - presupuesto');
    const [mensaje, setMensaje] = useState('Se adjunta el prespuesto solicitado. Saludos.');
    useEffect(() => {
        const correoCliente = presupuesto.presupuesto.cliente.idCliente.persona.correo || '';
        setDestinatario(correoCliente);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);    
    
    const handleEnviarPresupuestoPorCorreo = async (presupuesto) => {
        try {
            const emailData = {
                to: destinatario,
                subject: asunto,
                body: mensaje,
            }
            const respuesta = await sendPresupuestoPorMail(presupuesto, emailData);
            if(respuesta.status === 200){
                Swal.fire({
                    title: respuesta.data.message,
                    type: 'info',
                    showConfirmButton: false,
                    timer: 1500
                });
            }else{
                Swal.fire({
                    title: 'Se ha producido un error',
                    text: respuesta.data.message,
                    type: 'error',
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        } catch (error) {
            console.log(error);
            Swal.fire({
                title: 'Se ha producido un error',
                type: 'error',
                showConfirmButton: false,
                timer: 1500
            });
        }
    }

    return (
        <Fragment>
            <button type="button" className="btn text-danger"
                data-toggle="modal" data-target={"#modalPresupuesto" + presupuesto.idPresupuesto}>
                <FontAwesomeIcon icon={faMailBulk} /> Enviar
            </button>


            <div className="modal fade" id={"modalPresupuesto" + presupuesto.idPresupuesto} tabIndex="-1" role="dialog" aria-labelledby="modalPresupuestoTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <h5 className="modal-title" id="modalPresupuestoTitle"><b>Detalle Presupuesto</b></h5>
                        </div>
                        <div className="modal-body">
                            <div className="form-row text-left">
                                <div className="form-group col-10 offset-md-1" >
                                    <label htmlFor="inputCorreo" className="control-label text-left">Destinatario</label>
                                    <div className="input-group-prepend">
                                        <input 
                                            type="email"
                                            className="form-control"
                                            placeholder="Ej. nombre@email.com"
                                            onChange={e => setDestinatario (e.target.value)}
                                            value={destinatario}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-row text-left">
                                <div className="form-group col-10 offset-md-1" >
                                    <label htmlFor="inputCorreo" className="control-label text-left">Asunto</label>
                                    <div className="input-group-prepend">
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={e => setAsunto(e.target.value)}    
                                            value={asunto}                                        
                                            />
                                    </div>
                                </div>
                            </div>

                            <div className="form-row text-left">
                                <div className="form-group col-10 offset-md-1" >
                                    <label htmlFor="inputCorreo" className="control-label text-left">Mensaje</label>
                                    <div className="input-group-prepend">
                                        <textarea
                                            rows="5"
                                            className="form-control"
                                            onChange={e => setMensaje(e.target.value)}
                                            value={mensaje}                                          
                                        />
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-success"
                                data-dismiss="modal"
                                onClick={() => handleEnviarPresupuestoPorCorreo(presupuesto)}
                            >
                                Enviar por Mail
                            </button>
                            <button type="button" className="btn btn-primary" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}
