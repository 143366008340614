import axios from 'axios';
import {url} from './global';

// GET ALL CLIENTES PAGINATION
export const getAllClientesPagination = async (page, data) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.post(`${url}/clientes/page/${page}`, data,{headers});
    return res;
}
// END

export const getAllClientes = async () => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/clientes`,{headers});
    return res;
}


export const getByIdCliente = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/clientes/${id}`,{headers});
    return res;
}

export const getClienteByDni = async (dni) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/clientes-dni/${dni}`,{headers});
    return res;
}

export const editByIdCliente = async (cliente) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/clientes/${cliente.idCliente.persona.idPersona}`, cliente,{headers});
    return res;
}

export const deleteByIdCliente = async (id) => {
    
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.delete(`${url}/clientes/${id}`,{headers});
    return res;
}

export const saveCliente = async (cliente) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/clientes`,cliente,{headers});
    return res;
}
