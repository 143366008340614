import axios from 'axios';
import { url } from './global';


export const getAllCargos = async() => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/cargos`,{headers});
    return res;
}

export const getByIdCargo = async(id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/cargos/${id}`,{headers});
    return res;
}

export const editByIdCargo = async(cargo) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/cargos/${cargo.idCargo}`, cargo,{headers});
    return res;
}

export const deleteByIdCargo = async(id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.delete(`${url}/cargos/${id}`,{headers});
    return res;
}

export const saveCargo = async(cargo) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/cargos`, cargo,{headers});
    return res;
}