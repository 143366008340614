import React, { Fragment } from 'react';

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo'

// redux
import { useDispatch } from 'react-redux';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCreditCard, faCarBattery, faPercent, faPercentage, faWallet } from '@fortawesome/free-solid-svg-icons'

// router
import { withRouter } from 'react-router-dom';

// actions
import { AddTarjeta } from '../../Actions/TarjetaAction'

// services
import { saveTarjetas } from '../../Services/tarjetaService';

// Sweet alert
import Swal from 'sweetalert2';

// formik
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';

function AddTarjetas({ history }) {

    const dispatch = useDispatch();
    const tiposTarjetas = ([{key: 0, value: 'CREDITO'},{key: 1, value: 'DEBITO'}])
    const formik = useFormik({
        initialValues:{
            nombre: '',
            tipoTarjeta: '',
            cuotasSinInteres: 1,
            cuotasMaximaFinanciacion: 1,
            tasa: 0,
            recargo: 0,
            descuento: 0
        },
        validationSchema:Yup.object({
            nombre: Yup.string().required("Obligatorio."),
            tipoTarjeta: Yup.object().required("Obligatorio."),
            cuotasSinInteres: Yup.number().required("Obligatorio.").min(1, "El numero debe ser positivo."),
            cuotasMaximaFinanciacion: Yup.number().required("Obligatorio.").min(1, "El numero debe ser positivo."),
            tasa: Yup.number().required("Obligatorio.").min(0, "El numero debe ser positivo."),
            recargo: Yup.number().required("Obligatorio.").min(0, "El numero debe ser positivo."),
            descuento: Yup.number().required("Obligatorio.").min(0, "El numero debe ser positivo.")
        }),
        onSubmit: values => addTarjetaSubmit(values)
    })

    const addTarjetaSubmit = (values) => {

        // creamos el departamento
        let tarjeta = {
            ...values, tipoTarjeta: values.tipoTarjeta.key
        }
        saveTarjetas(tarjeta)
            .then(res => {
                Swal.fire({
                    text: 'Tarjeta creada correctamente.',
                    type: 'success',
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(AddTarjeta(res));
                history.goBack();
            })
            .catch(error => {
                Swal.fire({
                    text: 'No se pudo crear la tarjeta. Verificar datos.',
                    type: 'error',
                    showConfirmButton: false,
                    timer: 2000
                });
            });
    }

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faCreditCard}
                        title={'Tarjetas'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputNombre">Nombre</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faCreditCard} />
                                    </span>
                                    <input type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        className="form-control"
                                        value={formik.values.nombre}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.nombre && formik.errors.nombre ?
                                    <small className="form-text text-danger">
                                        {formik.errors.nombre}
                                    </small>
                                    : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputCodigo">Tipo Tarjeta</label>
                                <div>
                                    <Select 
                                        placeholder={"Seleccionar un tipo de tarjeta"}
                                        options={tiposTarjetas}
                                        onChange={value => formik.setFieldValue('tipoTarjeta',value)}
                                        getOptionValue={(values) => values.key}
                                        getOptionLabel={(values) => values.value}>
                                    </Select>
                                </div>
                                {formik.touched.tipoTarjeta && formik.errors.tipoTarjeta ?
                                    <small className="form-text text-danger">
                                        {formik.errors.tipoTarjeta}
                                    </small>
                                    : null}
                            </div>
                        </div>

                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputCuotasSinInteres">Cuotas sin interes</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faPercent} />
                                    </span>
                                    <input type="text"
                                        id="inputCuotasSinInteres"
                                        name="cuotasSinInteres"
                                        className="form-control"
                                        value={formik.values.cuotasSinInteres}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.cuotasSinInteres && formik.errors.cuotasSinInteres ?
                                    <small className="form-text text-danger">
                                        {formik.errors.cuotasSinInteres}
                                    </small>
                                    : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputCuotasMaximaFinanciacion">Cuotas Maxima Financiacion</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faPercentage} />
                                    </span>
                                    <input type="text"
                                        className="form-control"
                                        id="inputCuotasMaximaFinanciacion"
                                        name="cuotasMaximaFinanciacion"
                                        value={formik.values.cuotasMaximaFinanciacion}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.cuotasMaximaFinanciacion && formik.errors.cuotasMaximaFinanciacion ?
                                    <small className="form-text text-danger">
                                        {formik.errors.cuotasMaximaFinanciacion}
                                    </small>
                                    : null}
                            </div>
                        </div>

                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputTasa">Tasa</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faWallet} />
                                    </span>
                                    <input type="text"
                                        id="inputTasa"
                                        name="tasa"
                                        className="form-control"
                                        value={formik.values.tasa}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.tasa && formik.errors.tasa ?
                                    <small className="form-text text-danger">
                                        {formik.errors.tasa}
                                    </small>
                                    : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputRecargoDescuento">Recargo</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faCarBattery} />
                                    </span>
                                    <input type="text"
                                        className="form-control"
                                        id="inputRecargoDescuento"
                                        name="recargo"
                                        value={formik.values.recargo}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.recargo && formik.errors.recargo ?
                                    <small className="form-text text-danger">
                                        {formik.errors.recargo}
                                    </small>
                                    : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputRecargoDescuento">Descuento</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faCarBattery} />
                                    </span>
                                    <input type="text"
                                        className="form-control"
                                        id="inputRecargoDescuento"
                                        name="descuento"
                                        value={formik.values.descuento}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.descuento && formik.errors.descuento ?
                                    <small className="form-text text-danger">
                                        {formik.errors.descuento}
                                    </small>
                                    : null}
                            </div>
                        </div>


                        <div className="text-center">
                            <button type="submit" className="btn btn-outline-success">
                                <FontAwesomeIcon icon={faPlus} /> Crear Tarjeta</button>
                        </div>


                    </form>
                </div>



            </div>
        </Fragment>
    );
}

export default withRouter(AddTarjetas);