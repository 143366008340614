import React, { Fragment, useEffect, useState } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBalanceScale, faPlus } from '@fortawesome/free-solid-svg-icons'

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo';
import ItemUnidadMedida from './ItemUnidadMedida';

// redux
import { useSelector, useDispatch } from 'react-redux'

// actions
import { GetUnidadMedidas, DeleteUnidadMedida } from '../../Actions/UnidadMedidaAction';

// route
import { withRouter } from 'react-router-dom';

// services
import { getAllUnidadMedidas  } from '../../Services/unidadMedidaService';


const UnidadMedidas = ({ history }) => {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    let rol = token.authorities[0];

    const [isLoad, setIsLoad] = useState(true);
    const medidas = useSelector(state => state.medidas.medidas);
    const dispatch = useDispatch();
    const deleteUnidadMedida = (id) => dispatch(DeleteUnidadMedida(id));
    

    useEffect(() => {
        if (rol !== 'ROLE_ADMIN') history.goBack();
        if (isLoad) {
            getAllUnidadMedidas()
                .then(res => {
                    dispatch(GetUnidadMedidas(res));
                    setIsLoad(false);
                })
                .catch(err => console.log('error'));
        }else{
            setIsLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faBalanceScale}
                        title={'Unidad de Medidas'} />
                </div>

                <div className="bg-gris p-3">
                    <button className="btn btn-outline-success btn-lg ml-2"
                        onClick={() => { history.push('/add-unidad-medida') }}>
                        <FontAwesomeIcon icon={faPlus} /> Crear
                    </button>

                    <table className="table table-striped mt-3 text-center">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Abreviacion</th>
                                <th scope="col">Estado</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>

                            {medidas.map((medida, index) => (
                                <ItemUnidadMedida
                                    key={index}
                                    medida={medida}
                                    deleteUnidadMedida={deleteUnidadMedida}
                                    setIsLoad={setIsLoad}/>
                            ))}

                        </tbody>
                    </table>

                </div>

            </div>
        </Fragment>
    );
}

export default withRouter(UnidadMedidas);