import {
    GET_MARCAS, GET_MARCA, EDIT_MARCA, DELETE_MARCA, ADD_MARCA
} from './Types';

export const GetMarcas = (res) => {
    return{
        type: GET_MARCAS,
        payload: res.data
    }
}

export const AddMarca =  (res) => {
    return {
        type: ADD_MARCA,
        payload: res.data
    }
}

export const EditaMarca = (res) => {

    return {
        type: EDIT_MARCA,
        payload: res.data
    }
}


export const GetMarca = (res) => {
    return {
        type: GET_MARCA,
        payload: res.data
    }
} 

export const DeleteMarca = (id) => {
    return {
        type: DELETE_MARCA,
        payload: id
    }
}