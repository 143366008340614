import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo';

// services
import { editByIdDepartamentos } from '../../Services/departamentoService';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faLayerGroup, faPercent,faEdit} from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';

// formik
import { useFormik } from 'formik';
import * as Yup from 'yup';

function EditDepartamento ({ history,location })  {

    const departamento = location.state.departamento

    const formik = useFormik({
        initialValues: {
            nombre: departamento.nombre,
            tasaIva: departamento.tasaIva
        },
        validationSchema: Yup.object({
            nombre: Yup.string().required("Obligatorio"),
            tasaIva: Yup.number().required("Obligatorio").min(0, "El IVA debe ser mayor a cero"),
        }),
        onSubmit: values => editDepartamento(values)
    });

    const editDepartamento = (values) => {

        // creo el departamento actualizado
        const departamentoUpdated = {
            ...values, idDepartamento: departamento.idDepartamento
        }

        editByIdDepartamentos(departamentoUpdated)
            .then(res => {
                history.goBack();
            })
            .catch(error => {
                Swal.fire({
                    text: "Error al editar el departamento, verifique los datos.",
                    type: 'error',
                    showConfirmButton: false,
                    timer: 1500
                });
                console.log(error.response.data);
            });

    }
        return (
            <Fragment>
                <div className="container-fluid mt-3">
                    <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                        <BotonBack />
                        <Subtitulo
                            icon={faLayerGroup}
                            title={'Departamentos'} />
                    </div>

                    <div className="bg-gris p-4">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="inputNombre">Nombre</label>
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroupPrepend">
                                            <FontAwesomeIcon icon={faLayerGroup} />
                                        </span>
                                        <input type="text"
                                            id="inputNombre"
                                            name="nombre"
                                            className="form-control"
                                            placeholder="Ej. Lacteos, Gaseosas..."
                                            value={formik.values.nombre}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleChange}
                                        />
                                    </div>
                                    {formik.touched.nombre && formik.errors.nombre ?
                                    <small className="form-text text-danger">
                                        {formik.errors.nombre}
                                    </small>
                                    : null}
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="inputIva">Tasa IVA</label>
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroupPrepend">
                                            <FontAwesomeIcon icon={faPercent} />
                                        </span>
                                        <input type="number"
                                            step="any"
                                            id="inputIva"
                                            name="tasaIva"
                                            className="form-control"
                                            placeholder="Ej. %21.0"
                                            value={formik.values.tasaIva}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleChange}
                                        />
                                    </div>
                                    {formik.touched.tasaIva && formik.errors.tasaIva ?
                                    <small className="form-text text-danger">
                                        {formik.errors.tasaIva}
                                    </small>
                                    : null}
                                </div>
                            </div>
                           

                            <div className="text-center">
                                <button type="submit" className="btn btn-outline-danger">
                                    <FontAwesomeIcon icon={faEdit} /> Editar Departamento</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>
        );
}

export default withRouter (EditDepartamento);