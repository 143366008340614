import { GET_OFERTAS, ADD_OFERTA, EDIT_OFERTA, GET_OFERTA, DELETE_OFERTA } from './Types';

export const GetOfertas = (res) => {
    return{
        type: GET_OFERTAS,
        payload: res.data
    }
}

export const AddOferta =  (res) => {
    return {
        type: ADD_OFERTA,
        payload: res.data
    }
}

export const EditaOferta = (res) => {

    return {
        type: EDIT_OFERTA,
        payload: res.data
    }
}


export const GetOferta = (res) => {
    return {
        type: GET_OFERTA,
        payload: res.data
    }
} 

export const DeleteOferta = (id) => {
    return {
        type: DELETE_OFERTA,
        payload: id
    }
}