import React, { Fragment, useState } from 'react';

// router
import { withRouter } from 'react-router-dom';

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faEdit, faShoppingCart, faDollarSign } from '@fortawesome/free-solid-svg-icons'

// services
import { saveStockSucursal } from '../../Services/stockSucursalService';

// Sweer Alert
import Swal from 'sweetalert2';

// Modals
import ModalArticulo from './ModalArticulo/ModalArticulo'

//Formik
import { useFormik } from 'formik';
import * as Yup from 'yup';


const AddStockSucursal = ({ history }) => {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));

    const [articulo, setArticulo] = useState({nombre:''});

    const formik = useFormik({
        initialValues: {
            cantidad: 0,
            precioFinal: 0.0
        },
        validationSchema: Yup.object({
            cantidad: Yup.number().required("Obligatorio.").min(0, "El numero debe ser positivo."),
            precioFinal: Yup.number().required("Obligatorio.").min(0, "El numero debe ser positivo.")
        }),
        onSubmit: values => saveStockSucursalSubmit(values)
    })

    const saveStockSucursalSubmit = (values) => {

        let stockSucursal = {
            id: {
                articulo: articulo,
                sucursal: token.empleado.sucursal
            },
            cantidad: values.cantidad,
            precioFinal: values.precioFinal,
            fechaCreacion: new Date()
        }

        saveStockSucursal(stockSucursal)
            .then(res => {
                Swal.fire({
                    position: "top",
                    toast: true,
                    title: 'Articulo agregado a sucursal.',
                    type: 'success',
                    showConfirmButton: false,
                    timer: 1500
                })
                history.goBack();
            })
            .catch(error => {
                Swal.fire({
                    title: 'Error al añadir el articulo a la sucursal.',
                    type: 'error',
                    showConfirmButton: false,
                    timer: 2000
                })
            });

    }

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faBuilding}
                        title={'Stock Sucursales'} />
                </div>

                <div className="bg-gris p-4">

                        <form onSubmit={formik.handleSubmit}>
                            <div className="form-column">
                                <div className="form-group col-md-6" style={{marginRight: "auto", marginLeft:"auto",display:"block"}}>
                                    <label htmlFor="inputArticuloNombre">Articulo</label>
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroupPrepend">
                                            <FontAwesomeIcon icon={faBuilding} />
                                        </span>
                                        <input type="text"
                                            id="inputArticuloNombre"
                                            name="nombre"
                                            className="form-control"
                                            disabled
                                            value={articulo.nombre.trim()}
                                        />
                                        <ModalArticulo
                                            setCodigoArticulo={setArticulo} />
                                    </div>
                                </div>

                                <div className="form-group col-md-6" style={{marginRight: "auto", marginLeft:"auto",display:"block"}}>
                                    <label htmlFor="inputArticuloCantidad">Cantidad</label>
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroupPrepend">
                                            <FontAwesomeIcon icon={faShoppingCart} />
                                        </span>
                                        <input type="number"
                                            step="any"
                                            id="inputArticuloCantidad"
                                            name="cantidad"
                                            className="form-control"
                                            value={formik.values.cantidad}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            />
                                    </div>
                                    {formik.touched.cantidad && formik.errors.cantidad ?
                                        <small className="form-text text-primary font-weight-bold">
                                            {formik.errors.cantidad}
                                        </small>
                                        : null}
                                </div>

                                <div className="form-group col-md-6" style={{marginRight: "auto", marginLeft:"auto",display:"block"}}>
                                    <label htmlFor="inputArticuloPrecioFinal">Precio de sucursal</label>
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroupPrepend">
                                            <FontAwesomeIcon icon={faDollarSign} />
                                        </span>
                                        <input type="number"
                                            step="any"
                                            id="inputArticuloPrecioFinal"
                                            name="precioFinal"
                                            className="form-control"
                                            placeholder={articulo.costo ? "Costo: " + articulo.costo : ''}
                                            value={formik.values.precioFinal}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            />
                                    </div>
                                    {formik.touched.precioFinal && formik.errors.precioFinal ?
                                        <small className="form-text text-primary font-weight-bold">
                                            {formik.errors.precioFinal}
                                        </small>
                                        : null}
                                </div>

                            </div>
                            <div className="text-center">
                                <button type="submit" className="btn btn-outline-success">
                                    <FontAwesomeIcon icon={faEdit} /> Añadir Stock</button>
                            </div>
                        </form>
                    </div>
                </div>

        </Fragment>
    );
};

export default withRouter(AddStockSucursal);