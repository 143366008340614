import React, { Fragment, useEffect, useState } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard, faPlus } from '@fortawesome/free-solid-svg-icons'

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo';
import ItemTarjeta from './ItemTarjeta';

// redux
import { useSelector, useDispatch } from 'react-redux'

// actions
import { GetTarjetas, DeleteTarjeta } from '../../Actions/TarjetaAction';

// route
import { withRouter } from 'react-router-dom';

// services
import { getAllTarjetas  } from '../../Services/tarjetaService';


const Tarjetas = ({ history }) => {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    let rol = token.authorities[0];

    const [isLoad, setIsLoad] = useState(true);
    const tarjetas = useSelector(state => state.tarjetas.tarjetas);
    const dispatch = useDispatch();
    const deleteTarjeta = (id) => dispatch(DeleteTarjeta(id));
    
    useEffect(() => {
        if (rol !== 'ROLE_ADMIN') history.goBack();
        if (isLoad) {
            getAllTarjetas()
                .then(res => {
                    dispatch(GetTarjetas(res));
                    setIsLoad(false);
                })
                .catch(err => console.log('error'));
        }else{
            setIsLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faCreditCard}
                        title={'Tarjetas'} />
                </div>

                <div className="bg-gris p-3">
                    <button className="btn btn-outline-success btn-lg ml-2"
                        onClick={() => { history.push('/add-tarjeta') }}>
                        <FontAwesomeIcon icon={faPlus} /> Crear
                    </button>

                    <table className="table table-striped mt-3 text-center">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Cuota S/Interes</th>
                                <th scope="col">Recargo</th>
                                <th scope="col">Descuento</th>
                                <th scope="col">Tasa</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>

                            {tarjetas.map((tarjeta) => (
                                <ItemTarjeta
                                    key={parseInt(tarjeta.idTarjeta)}
                                    tarjeta={tarjeta}
                                    deleteTarjeta={deleteTarjeta}
                                    setIsLoad={setIsLoad}/>
                            ))}

                        </tbody>
                    </table>

                </div>

            </div>
        </Fragment>
    );
}

export default withRouter(Tarjetas);