import { GET_PEDIDOS, ADD_PEDIDO, EDIT_PEDIDO, GET_PEDIDO, DELETE_PEDIDO } from './Types';

export const GetPedidos = (res) => {
    return{
        type: GET_PEDIDOS,
        payload: res.data
    }
}

export const AddPedido =  (res) => {
    return {
        type: ADD_PEDIDO,
        payload: res.data
    }
}

export const EditaPedido = (res) => {

    return {
        type: EDIT_PEDIDO,
        payload: res.data
    }
}


export const GetPedido = (res) => {
    return {
        type: GET_PEDIDO,
        payload: res.data
    }
} 

export const DeletePedido = (id) => {
    return {
        type: DELETE_PEDIDO,
        payload: id
    }
}