import React from 'react';

// icons
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faArrowsAlt } from '@fortawesome/free-solid-svg-icons'

// routes
import { withRouter } from 'react-router-dom';

// modals
import ModalDetalleVenta from './ModalDetalleVenta/ModalDetalleVenta';
import ModalDetalleMedioPago from './ModalDetalleMedioPago/ModalDetalleMedioPago';

// moment
import moment from 'moment';
import 'moment-timezone';

const ItemVenta = ({ venta, setIsLoad }) => {

    //let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    //let rol = token.authorities[0];


    const estado = (estado) => {
        if (estado === 'PAGADA') return <p className="text-success">PAGADA</p>;
        if (estado === 'PAGADA_PARCIALMENTE') return <p className="text-warning">PAGADA PARCIALMENTE</p>;
        if (estado === 'ADEUDADA') return <p className="text-primary">ADEUDADA</p>;
    }

    /*const HandleEstadoventa = (venta, estado) => {
        venta.estado = estado;
        editByIdventa(venta)
            .then(res => {
                // INVOCAMOS EL ACTION
                editaventa(res);
                setIsLoad(true);
            })
            .catch(res => {
                console.log(res.response);
                Swal.fire({
                    title: res.response.data.mensaje,
                    type: 'error',
                    showConfirmButton: false,
                    timer: 2000
                })
                
            });
        console.log(venta);
    }*/

    const fechaHora = moment(venta.fecha).tz("America/Argentina/Buenos_Aires").format();
    // .replace('.000+0000','')

    return (
        <tr>
            <th scope="row">{venta.idVenta}</th>
            <td>${(venta.total).toFixed(2)}</td>
            <td>{estado(venta.estado)}</td>
            <td>{fechaHora.split('T')[0]} / {fechaHora.split('T')[1].replace('-03:00','')}</td> 
            <td>{venta.sucursal.nombre}</td>
            <td>{venta.tipoComprobante.nombre}</td>
            <td>{venta.cliente.idCliente.persona.apellido}, {venta.cliente.idCliente.persona.nombre}</td>
            <td>
                <ModalDetalleMedioPago item={venta}/>
                <ModalDetalleVenta item={venta} />
            </td>
        </tr>
    );
}

export default withRouter(ItemVenta);