import React from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faMoneyBill } from '@fortawesome/free-solid-svg-icons'

// routes
import { withRouter } from 'react-router-dom';




const ItemCuentaCorriente = ({ cuentaCorriente, history, setIsLoad }) => {


    const isDateNull = (fecha) => {
        if(fecha === null) return '-';
        else {
            let fechaFinal = fecha.split('T')[0];
            return fechaFinal;
        }
            
    }


    return (
        <tr key={parseInt(cuentaCorriente.idCuentaCorriente)}>
            <th scope="row">{cuentaCorriente.idCuentaCorriente}</th>
            <td>{cuentaCorriente.proveedor.nombre}</td>
            <td>${cuentaCorriente.debo}</td>
            <td>{isDateNull(cuentaCorriente.fechaUltimoPago)}</td>
            <td>{isDateNull(cuentaCorriente.fechaProximoPago)}</td>
            <td>
                <button className="btn text-success"
                    onClick={() => { history.push(`/ingreso-pago-proveedor`,{cuentaCorriente: cuentaCorriente}) }}>
                    <FontAwesomeIcon icon={faMoneyBill} /> Pagos
                </button>
                <button className="btn text-danger"
                    onClick={() => { history.push(`/edit-cuenta-corriente-proveedores/${cuentaCorriente.idCuentaCorriente}`,{cuentaCorriente:cuentaCorriente}) }}>
                    <FontAwesomeIcon icon={faEdit} /> Editar
                </button>
            </td>
        </tr>
    );
}

export default withRouter(ItemCuentaCorriente);