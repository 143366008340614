import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo'

import { useDispatch } from 'react-redux';

//Font & Alerts
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPlus, faUserCircle, faUserEdit, faAt, faMobileAlt,
    faIdCard, faMapMarkedAlt, faBirthdayCake, faIdCardAlt, faUserTie,
        } from '@fortawesome/free-solid-svg-icons';

//Actions
import { AddEmpleado } from '../../Actions/EmpleadoAction';
import { GetCargos } from '../../Actions/CargoAction';
import { GetSucursales } from '../../Actions/SucursalAction';

//Services
import { getAllCargos } from '../../Services/cargoService';
import { getAllSucursales } from '../../Services/sucursalService';
import { saveEmpleado } from '../../Services/empleadoService';

//Formik
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';




function AddEmpleados({ history }) {

    const [cargos, setCargos] = useState([]);
    const [sucursales, setSucursales] = useState([]);
    const [isLoad, setIsLoad] = useState(true);

     const dispatch = useDispatch();

    useEffect(() => {
        if (isLoad) {
            getAllCargos()
                .then(res => {
                    dispatch(GetCargos(res));
                    setCargos(res.data);
                })
                .catch(error => console.log(error.response.data));
            getAllSucursales()
                .then(res => {
                    dispatch(GetSucursales(res));
                    setSucursales(res.data);
                })
                .catch(error => console.log(error.response.data));
            setIsLoad(false);
        }
        else {
            setIsLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formik = useFormik({
        initialValues: {
            nombre: '',
            apellido: '',
            dni: '',
            cuilCuit: '',
            fechaNacimiento: '',
            correo: '',
            telefono: '',
            direccion: '',
            cargo: '',
            sucursal: ''
        },
        validationSchema: Yup.object({
            nombre: Yup.string()
                .required("Obligatorio."),
            apellido: Yup.string(),
            dni: Yup.number()
                .required("Obligatorio."),
            cuilCuit: Yup.string(),
            fechaNacimiento: Yup.date(),
            correo: Yup.string().email("Formato invalido."),
            telefono: Yup.number(),
            direccion: Yup.string(),
            cargo: Yup.object().required("Obligatorio"),
            sucursal: Yup.object().required("Obligatorio")
        }),
        onSubmit: values => addEmpleadoSubmit(values)
    })

    const addEmpleadoSubmit = ({nombre,apellido,correo,telefono,dni,direccion,cuilCuit,fechaNacimiento,cargo,sucursal}) => {

        // creamos la persona
        let persona = {
            nombre,
            apellido,
            dni,
            cuilCuit,
            fechaNacimiento,
            correo,
            telefono,
            direccion
        }

        //creamos el empleado
        let empleadoNew = {
            idEmpleado: { persona: persona },
            cargo,
            sucursal
        }

        // guardamos el epleado
        saveEmpleado(empleadoNew)
            .then(res => {
                dispatch(AddEmpleado(res));
                history.goBack();
            })
            .catch(error => {
                Swal.fire({
                    text: 'Error al crear el empleado, verifique los datos.',
                    type: 'error',
                    showConfirmButton: false,
                    timer: 1500
                });
            });
    }

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faUserTie}
                        title={'Empleados'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputNombre">Nombre</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faUserCircle} />
                                    </span>
                                    <input type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        className="form-control"
                                        placeholder="Ej. Jose Manuel"
                                        value={formik.values.nombre}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}   
                                    />
                                </div>
                                {formik.touched.nombre && formik.errors.nombre ?
                                    <small className="form-text text-danger">
                                        {formik.errors.nombre}
                                    </small>
                                    : null
                                }
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputApellido">Apellido</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faUserEdit} />
                                    </span>
                                    <input type="text"
                                        className="form-control"
                                        id="inputApellido"
                                        name="apellido"
                                        placeholder="Ej Gutierrez..."
                                        value={formik.values.apellido}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}   
                                    />
                                </div>
                            </div>
                            
                        </div>

                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputDni">DNI</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faIdCard} />
                                    </span>
                                    <input type="text"
                                        id="inputDni"
                                        name="dni"
                                        className="form-control"
                                        placeholder="Ej. 4234890"
                                        value={formik.values.dni}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}   
                                    />
                                </div>
                                {formik.touched.dni && formik.errors.dni ?
                                    <small className="form-text text-danger">
                                        {formik.errors.dni}
                                    </small>
                                    : null
                                }
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputCuilCuit">CUIL/CUIT</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faIdCardAlt} />
                                    </span>
                                    <input type="text"
                                        className="form-control"
                                        id="inputCuilCuit"
                                        name="cuilCuit"
                                        placeholder="Ej. 20341234562"
                                        value={formik.values.cuilCuit}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}  
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputFechaNacimiento">Fecha Nacimiento</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faBirthdayCake} />
                                    </span>
                                    <input type="date"
                                        className="form-control"
                                        id="inputFechaNacimiento"
                                        name="fechaNacimiento"
                                        value={formik.values.fechaNacimiento}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}  
                                    />
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputCorreo">Correo Electronico</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faAt} />
                                    </span>
                                    <input type="text"
                                        id="inputCorreo"
                                        name="correo"
                                        className="form-control"
                                        placeholder="Ej. perez@email.com"
                                        value={formik.values.correo}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}   
                                    />
                                </div>
                            </div>

                        </div>


                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputTelefono">Telefono</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faMobileAlt} />
                                    </span>
                                    <input type="text"
                                        className="form-control"
                                        id="inputTelefono"
                                        name="telefono"
                                        placeholder="Ej. +54381234098"
                                        value={formik.values.telefono}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}   
                                    />
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputDireccion">Direccion</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faMapMarkedAlt} />
                                    </span>
                                    <input type="text"
                                        id="inputDireccion"
                                        name="direccion"
                                        className="form-control"
                                        placeholder="Ej. Pje. La Paz 123"
                                        value={formik.values.direccion}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}  
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputCargo">Cargos</label>
                                <div>
                                    <Select
                                        placeholder={"Seleccionar un cargo"}
                                        options={cargos}
                                        onChange={value=>formik.setFieldValue('cargo',value)}
                                        getOptionValue={(values) => values.idcargo}
                                        getOptionLabel={(values) => values.nombre}
                                    >
                                    </Select>
                                </div>
                                {formik.touched.cargo && formik.errors.cargo ?
                                    <small className="form-text text-danger">
                                        {formik.errors.cargo}
                                    </small>
                                : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputSucursal">Sucursales</label>
                                <div>
                                    <Select
                                        placeholder={"Seleccionar una sucursal"}
                                        options={sucursales}
                                        onChange={value=>formik.setFieldValue('sucursal',value)}
                                        getOptionValue={(values) => values.idsucursal}
                                        getOptionLabel={(values) => values.nombre}
                                    >
                                    </Select>
                                </div>
                                {formik.touched.sucursal && formik.errors.sucursal ?
                                    <small className="form-text text-danger">
                                        {formik.errors.sucursal}
                                    </small>
                                : null}
                            </div>
                        </div>

                        <div className="text-center">
                            <button type="submit" className="btn btn-outline-success">
                                <FontAwesomeIcon icon={faPlus} /> Crear Empleado</button>
                        </div>

                    </form>
                </div>
            </div>
        </Fragment>
    );
}

export default withRouter(AddEmpleados);