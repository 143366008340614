import React, { Fragment, useState } from 'react';

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo'

// redux
import { useDispatch } from 'react-redux';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBalanceScale, faPlus, faSpellCheck } from '@fortawesome/free-solid-svg-icons'

// router
import { withRouter } from 'react-router-dom';

// actions
import { AddUnidadMedidas } from '../../Actions/UnidadMedidaAction'

// services
import { saveUnidadMedida } from '../../Services/unidadMedidaService';

function AddUnidadMedida({ history }) {

    const [nombre, setNombre] = useState('');
    const [abreviacion, setAbreviacion] = useState('');

    const dispatch = useDispatch();

    const addUnidadMedidaSubmit = (e) => {
        e.preventDefault();
        // validaciones

        // creamos el departamento
        let medida = {
            nombre: nombre,
            abreviacion: abreviacion,
            activo: true
        }

        saveUnidadMedida(medida)
            .then(res => {
                dispatch(AddUnidadMedidas(res));
                history.goBack();
            })
            .catch(error => console.log('error al guardar'));
    }

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faBalanceScale}
                        title={'Unidad de Medidas'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={addUnidadMedidaSubmit}>
                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputNombre">Nombre</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faBalanceScale} />
                                    </span>
                                <input type="text"
                                    id="inputNombre"
                                    name="nombre"
                                    className="form-control"
                                    placeholder="Ej. Kilogramo, Litro..."
                                    onChange={e => setNombre(e.target.value)} />
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputAbreviacion">Abreviacion</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faSpellCheck} />
                                    </span>
                                <input type="text"
                                    className="form-control"
                                    id="inputAbreviacion"
                                    name="abreviacion"
                                    placeholder="Ej. kg, lts, u..."
                                    onChange={e => setAbreviacion(e.target.value)} />
                                </div>
                            </div>
                        </div>


                        <div className="text-center">
                            <button type="submit" className="btn btn-outline-success">
                                <FontAwesomeIcon icon={faPlus} /> Crear Medida</button>
                        </div>


                    </form>
                </div>



            </div>
        </Fragment>
    );
}

export default withRouter(AddUnidadMedida);