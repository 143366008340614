import React, { Fragment, useState, useEffect, useRef } from 'react';

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBarcode, faPlus, faBars, faCopyright,
    faDollarSign, faCartPlus, faFileAlt, faKeyboard, faMinus, faTrashAlt, faSortNumericDown, faPercentage,
} from '@fortawesome/free-solid-svg-icons'

// components
import ModalArticulo from './ArticuloModal/ModalArticulo';

// services
import { getAllFamilias } from '../../Services/familiaService';
import { getAllMarcas } from '../../Services/marcaService';
import { saveArticulosCompuestos, getByIdArticulos } from '../../Services/articuloService';

// routes
import { withRouter } from 'react-router-dom';

// Sweet alert
import Swal from 'sweetalert2';

const AddArticulosCompuestos = ({ history }) => {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    let role = token.authorities[0];

    // atributos de articulo
    const [articulo, setArticulo] = useState({
        nombre: '',
        costo: '',
        precioFinal: 0,
        codigo: '',
        porcentajeGanancia: 0,
        descripcion: '',
        familiaId: '',
        marcaId: '',
        puntos: 0,
    });

    const [itemsCompra, setItemsCompra] = useState([]);

    const [items, setItems] = useState([]);
    const [codigoArticulo, setCodigoArticulo] = useState();
    const [cantidadArticulo, setCantidadArticulo] = useState(1);

    const [codigosBarra, setCodigosBarra] = useState([]);

    // select familias, marcas, unidad medidas
    const [familias, setFamilias] = useState([]);
    const [marcas, setMarcas] = useState([]);

    const inputTotalRef = useRef(null);

    useEffect(() => {

        if (role !== 'ROLE_ADMIN') history.goBack();

        // get familias
        getAllFamilias()
            .then(res => setFamilias(res.data))
            .catch(err => console.log('error'));

        // get marcas
        getAllMarcas()
            .then(res => setMarcas(res.data))
            .catch(err => console.log('error'));


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleArticulo = (e) => {
        setArticulo({
            ...articulo,
            [e.target.name]: e.target.value
        })
    }

    const handleCodigosBarra = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            let codigoBarra = { codigo: e.target.value };
            setCodigosBarra([
                ...codigosBarra,
                codigoBarra
            ]);
            e.target.value = '';
        }
    }

    const deleteCodigoBarra = (e, codigo) => {
        e.preventDefault();
        let codigosBarraFilter = codigosBarra.filter(function (cv) {
            return cv.codigo !== codigo;
        });
        setCodigosBarra(codigosBarraFilter);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            
            // codigo y cantidad

            // validar que el articulo no existe en los detalles
            let isPresent = false;
            items.forEach(item => {
                if (parseInt(item.idArticulo, 10) === parseInt(codigoArticulo, 10) ||
                    parseInt(item.codigo, 10) === parseInt(codigoArticulo, 10)) isPresent = true;
            });

            // si ya se encuentra presento salgo de la funcion
            if (isPresent) {
                setCodigoArticulo('')
                setCantidadArticulo(1);
                inputTotalRef.current.focus()
                Swal.fire({
                    toast: true,
                    position: 'top',
                    title: 'El artículo ya se ingresó.',
                    type: 'info',
                    showConfirmButton: false,
                    timer: 1500
                })
                return;
            }

            getByIdArticulos(codigoArticulo)
                .then(async data => {
                    
                    // datos para calcular precio articulo
                    let articulo = data.data;

                    // items para mostra detalle en pantalla
                    let item = {
                        idArticulo: articulo.idArticulo,
                        codigo: articulo.codigo,
                        articulo: articulo.nombre,
                        costo: articulo.costo,
                        marca: articulo.marca.nombre,
                        cantidad: parseFloat(cantidadArticulo, 10),
                    }

                    // items para guardar en la base de datos
                    let itemCompra = {
                        articulo: articulo,
                        cantidad: item.cantidad
                    }

                    // ingresamos items que se guardaran en la bd
                    setItemsCompra([...itemsCompra, itemCompra]);
                    // ingresamos items de  que se mostraran en pantalla
                    setItems([...items, item]);

                    setCodigoArticulo('');
                    setCantidadArticulo(1);
                    inputTotalRef.current.focus()
                })
                .catch(error => {
                    setCodigoArticulo('');
                    setCantidadArticulo(1);
                    inputTotalRef.current.focus()
                    Swal.fire({
                        toast:true,
                        position: 'top',
                        title: 'El artículo no existe.',
                        text: 'No se encontró en la base de datos.',
                        type: 'info',
                        timer: 2000,
                        showConfirmButton: false
                    })
                });
        }

    }

    // elimina items de la lista de articulos
    const deleteItem = (articulo) => {
        let itemsDelete = items.filter((item) => {
            return parseInt(item.idArticulo, 10) !== parseInt(articulo.idArticulo, 10);
        });

        let itemsCompraDelete = itemsCompra.filter((item) => {
            return parseInt(item.articulo.idArticulo, 10) !== parseInt(articulo.idArticulo, 10);
        });

        setItemsCompra(itemsCompraDelete);
        setItems(itemsDelete);
    }

    const addArticuloSubmit = () => {

        // validaciones

        // filtro la familia seleccionada mediante su id
        let familia = familias.filter(function (flia) {
            return flia.idFamilia === parseInt(articulo.familiaId, 10);
        });

        // filtro la marca seleccionada mediante su id
        let marca = marcas.filter(function (mca) {
            return mca.idMarca === parseInt(articulo.marcaId, 10);
        });


        // creo nuevo articulo
        const articuloNew = {
            nombre: articulo.nombre,
            costo: articulo.costo,
            precioFinal: articulo.precioFinal,
            codigo: articulo.codigo,
            porcentajeGanancia: articulo.porcentajeGanancia,
            descripcion: articulo.descripcion,
            puntos: articulo.puntos,
            familia: familia[0],
            marca: marca[0],
            codigosBarra: codigosBarra,
        }


        let articuloRequest = {
            articuloCompuesto: articuloNew,
            articulos: itemsCompra,
            sucursal: token.empleado.sucursal
        }

        
        // guardamos el articulo
        saveArticulosCompuestos(articuloRequest)
            .then(res => {                
                history.goBack();
            })
            .catch(error => {
                //console.log(error.response);
                Swal.fire({
                    text: error.response.data,
                    type: 'error',
                    showConfirmButton: false,
                    timer: 2000
                });
            });
    }

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faBarcode}
                        title={'Articulos Compuestos'} />
                </div>

                <div className="bg-gris p-4">

                    <div className="form-row">

                        <div className="form-group col-md-4">
                            <label htmlFor="inputNombre">Nombre</label>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faCartPlus} />
                                </span>
                                <input type="text"
                                    id="inputNombre"
                                    name="nombre"
                                    className="form-control"
                                    placeholder="Ej. Lacteos, Gaseosas..."
                                    onChange={e => handleArticulo(e)} />
                            </div>
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="inputcosto">Costo</label>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faDollarSign} />
                                </span>
                                <input type="text"
                                    className="form-control"
                                    step="any"
                                    id="inputCosto"
                                    name="costo"
                                    placeholder="Ej. $100, $200,..."
                                    onChange={e => handleArticulo(e)} />
                            </div>
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="inputPrecioFinal">Precio Final</label>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faDollarSign} />
                                </span>
                                <input
                                    type="numbre"
                                    step="any"
                                    id="inputPrecioFinal"
                                    name="precioFinal"
                                    className="form-control"
                                    onChange={(e) => handleArticulo(e)} />
                            </div>

                        </div>

                    </div>

                    <div className="form-row">

                        <div className="form-group col-md-4">
                            <label htmlFor="inputCodProducto">Código del producto</label>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faKeyboard} />
                                </span>
                                <input type="text"
                                    className="form-control"
                                    id="inputCodProducto"
                                    name="codigo"
                                    placeholder="100"
                                    onChange={e => handleArticulo(e)} />
                            </div>
                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor="inputPorcentajeGanancia">Porcentaje de ganancia</label>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faPercentage} />
                                </span>
                                <input type="text"
                                    className="form-control"
                                    id="inputPorcentajeGanancia"
                                    name="porcentajeGanancia"
                                    onChange={e => handleArticulo(e)} />
                            </div>
                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor="inputDescripcion">Descripción</label>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faFileAlt} />
                                </span>
                                <input type="text"
                                    className="form-control"
                                    id="inputDescripcion"
                                    name="descripcion"
                                    placeholder="Ej. producto envasado"
                                    onChange={e => handleArticulo(e)} />
                            </div>
                        </div>
                    </div>

                    <div className="form-row">
                        
                        <div className="form-group col-md-6">
                            <label htmlFor="inputFamilia">Familia</label>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faBars} />
                                </span>
                                <select id="inputFamilia" className="custom-select" name="familiaId"
                                    onChange={(e) => handleArticulo(e)}>
                                    <option value={null}>-- Seleccione una Familia --</option>
                                    {familias.map((familia, index) => (
                                        <option key={index} value={familia.idFamilia}>{familia.nombre}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="form-group col-md-6">
                            <label htmlFor="inputMarca">Marca</label>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faCopyright} />
                                </span>
                                <select id="inputMarca" className="custom-select" name="marcaId"
                                    onChange={(e) => handleArticulo(e)}>
                                    <option value={null}>-- Seleccione una Marca --</option>
                                    {marcas.map((marca, index) => (
                                        <option key={index} value={marca.idMarca}>{marca.nombre}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                    </div>

                    <div className="form-row">

                        <div className="form-group col-md-6">
                            <label htmlFor="inputDescripcion">Puntos</label>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faSortNumericDown} />
                                </span>
                                <input type="text"
                                    className="form-control"
                                    id="inputPuntos"
                                    name="puntos"
                                    placeholder="Ej. 100, 200"
                                    onChange={e => handleArticulo(e)} />
                            </div>
                        </div>


                        <div className="form-group col-md-6">
                            <label htmlFor="inputCodBarra">Codigos de Barra</label>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faBarcode} />
                                </span>
                                <input type="number"
                                    className="form-control"
                                    id="inputCodBarra"
                                    name="codigoBarra"
                                    placeholder="77555511999"
                                    onKeyPress={handleCodigosBarra} />
                            </div>
                            <ul className="list-group">
                                {codigosBarra.map((cb) => {
                                    return (
                                        <li id={"cod" + cb.codigo} key={parseInt(cb.codigo)} className="list-group-item d-flex justify-content-between align-items-center">
                                            {cb.codigo}
                                            <button className="btn btn-primary"
                                                onClick={(e) => deleteCodigoBarra(e, cb.codigo)}>
                                                <FontAwesomeIcon icon={faMinus} />
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>

                    </div>

                    <div className="d-flex bd-highlight">
                        <div className="p-2 bd-highlight">
                            <ModalArticulo
                                setCodigoArticulo={setCodigoArticulo}
                                inputTotalRef={inputTotalRef}
                            />
                        </div>
                        <div className="p-2 flex-grow-1 bd-highlight">
                            <input className="form-control form-control-lg"
                                style={{ height: 45 }}
                                type="text"
                                placeholder="Ingrese un articulo"
                                value={codigoArticulo || ''}
                                ref={inputTotalRef}
                                onChange={(e) => setCodigoArticulo(e.target.value)}
                                onKeyPress={(e) => handleKeyPress(e)} />
                        </div>
                        <div className="p-2 flex-grow-1 bd-highlight">
                            <input className="form-control form-control-lg"
                                style={{ height: 45 }}
                                type="text"
                                placeholder="cantidad"
                                value={cantidadArticulo}
                                onChange={(e) => setCantidadArticulo(e.target.value)}
                                onKeyPress={(e) => handleKeyPress(e)} />
                        </div>

                    </div>

                    <table className="table">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Producto</th>
                                <th scope="col">Precio</th>
                                <th scope="col">Marca</th>
                                <th scope="col">Cantidad</th>
                                <th scope="col">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(items) ?
                                items.map((item) => {
                                    return (
                                        <tr key={parseInt(item.idArticulo, 10)}>
                                            <th scope="row"><h4>{item.codigo}</h4></th>
                                            <td><h4>{item.articulo}</h4> </td>
                                            <td><h4>{item.costo}</h4> </td>
                                            <td><h4>{item.marca}</h4> </td>
                                            <td><h4>{item.cantidad}</h4> </td>
                                            <td>
                                                <button className="btn btn-outline-primary"
                                                    onClick={() => deleteItem(item)}>
                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })
                                :
                                null
                            }


                        </tbody>
                    </table>

                    <div className="text-center">
                        <button className="btn btn-outline-success btn-lg"
                            onClick={() => addArticuloSubmit()}>
                            <FontAwesomeIcon icon={faPlus} /> Crear artículo compuesto
                            </button>
                    </div>
                </div>



            </div>
        </Fragment>
    );
}

export default withRouter(AddArticulosCompuestos);