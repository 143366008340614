import React, { Fragment, useEffect, useState } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faUsers } from '@fortawesome/free-solid-svg-icons'

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo';
import ItemUsuario from './ItemUsuario';

// route
import { withRouter } from 'react-router-dom';

// services
import { getAllUsuarios  } from '../../Services/usuarioService';

const Usuarios = ({ history }) => {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    let rol = token.authorities[0];

    const [isLoad, setIsLoad] = useState(true);
    const [usuarios, setUsuarios] = useState([]);
    

    useEffect(() => {
        if (rol !== 'ROLE_ADMIN') history.goBack();
        if (isLoad) {
            getAllUsuarios()
                .then(res => {
                    setUsuarios(res.data);
                    setIsLoad(false);
                })
                .catch(err => console.log('error'));
        }else{
            setIsLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faUsers}
                        title={'Usuarios'} />
                </div>

                <div className="bg-gris p-3">
                    <button className="btn btn-outline-success btn-lg ml-2"
                        onClick={() => { history.push('/add-usuario') }}>
                        <FontAwesomeIcon icon={faPlus} /> Crear
                    </button>

                    <table className="table table-striped mt-3 text-center">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Correo</th>
                                <th scope="col">Nombre de usuario</th>
                                <th scope="col">Rol</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {usuarios.map((usuario) => {

                                return <ItemUsuario
                                    key={parseInt(usuario.idUsuario)}
                                    usuario={usuario}
                                    setIsLoad={setIsLoad}/>
                            })}
                            
                        </tbody>
                    </table>

                </div>

            </div>
        </Fragment>
    );
}

export default withRouter(Usuarios);