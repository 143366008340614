import React from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTruck } from '@fortawesome/free-solid-svg-icons'

// routes
import { withRouter } from 'react-router-dom';


// services
import { editByIdPedido } from '../../Services/pedidoService';

// modals
import ModalDetallePedido from './ModalDetallePedido/ModalDetallePedido';

// Sweet alert
import Swal from 'sweetalert2';

const ItemPedidoSucursal = ({ pedido, setIsLoad, editaPedido }) => {

    let payload = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));


    const activo = (estado) => {
        if (estado === 'CANCELADA') return <p className="text-primary">CANCELADO</p>;
        if (estado === 'ENVIADO') return <p className="text-info">ENVIADO</p>;
        if (estado === 'RECIBIDO') return <p className="text-success">RECIBIDO</p>;
    }

    const HandleEstadoPedido = (pedido, estado) => {
        pedido.estado = estado;
        editByIdPedido(pedido)
            .then(res => {
                // INVOCAMOS EL ACTION
                editaPedido(res);
                setIsLoad(true);
            })
            .catch(res => {
                Swal.fire({
                    title: res.response.data.mensaje,
                    type: 'error',
                    showConfirmButton: false,
                    timer: 2000
                })                
            });
    }

    return (
        <tr>
            <th scope="row">{pedido.idPedido}</th>
            <td>{pedido.sucursalSalida.nombre}</td>
            <td>{pedido.sucursalDestino.nombre}</td>
            <td>{(pedido.fechaEnvio).split('T')[0]}</td>
            <td>{(pedido.fechaEntrega)?pedido.fechaEntrega.split('T')[0]:'-'}</td>
            <td>{activo(pedido.estado)}</td>
            <td>
                {(parseInt(payload.empleado.sucursal.idSucursal) === parseInt(pedido.sucursalSalida.idSucursal)) ?
                    <button className="btn text-primary"
                        onClick={() => HandleEstadoPedido(pedido, 'CANCELADA')}>
                        <FontAwesomeIcon icon={faTruck} /> CANCELAR
                    </button>
                    :
                    null
                }
                {(parseInt(payload.empleado.sucursal.idSucursal) === parseInt(pedido.sucursalDestino.idSucursal)) ?
                    <button className="btn text-success"
                        onClick={() => HandleEstadoPedido(pedido, 'RECIBIDO')}>
                        <FontAwesomeIcon icon={faCheck} /> RECIBIR
                    </button>
                    :
                    null
                }      
                <ModalDetallePedido item={pedido}/>
            </td>
        </tr>
    );
}

export default withRouter(ItemPedidoSucursal);