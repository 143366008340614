import { GET_MEDIOS_PAGOS, ADD_MEDIO_PAGO, EDIT_MEDIO_PAGO, GET_MEDIO_PAGO, DELETE_MEDIO_PAGO } from './Types';

export const GetMedioPagos = (res) => {
    return{
        type: GET_MEDIOS_PAGOS,
        payload: res.data
    }
}

export const AddMedioPago =  (res) => {
    return {
        type: ADD_MEDIO_PAGO,
        payload: res.data
    }
}

export const EditaMedioPago = (res) => {

    return {
        type: EDIT_MEDIO_PAGO,
        payload: res.data
    }
}


export const GetMedioPago = (res) => {
    return {
        type: GET_MEDIO_PAGO,
        payload: res.data
    }
} 

export const DeleteMedioPago = (id) => {
    return {
        type: DELETE_MEDIO_PAGO,
        payload: id
    }
}