import axios from 'axios';
import {url} from './global';


// PAGINATION
export const getAllCuentasCorrientesPage = async (page, data) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.post(`${url}/cuentas-corrientes-clientes/page/${page}`, data,{headers});
    return res;
}
// END PAGINATION

export const getByIdCuentasCorriente = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/cuentas-corrientes/${id}`,{headers});
    return res;
}

export const getCuentaCorrienteByDni = async (dni) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/cuentas-corrientes-dni/${dni}`,{headers});
    return res;
}

export const editByIdCuentasCorriente = async (cuenta) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/cuentas-corrientes-c/${cuenta.idCuentaCorriente}`, cuenta,{headers});
    return res;
}

export const ventaCuentasCorrienteByCliente = async (dni, cuenta) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/cuentas-corrientes-venta/${dni}`, cuenta ,{headers});
    return res;
}

export const deleteByIdCuentasCorriente = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.delete(`${url}/cuentas-corrientes/${id}`,{headers});
    return res;
}

export const saveCuentasCorriente = async (cuenta) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/cuentas-corrientes-clientes`,cuenta,{headers});
    return res;
}

// SAVE PAGOS
export const savePago = async (pago) => {
    
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/cuentas-corrientes-c-pago`, pago ,{headers});
    return res;
}

// GET PAGOS BY VENTA
export const getPagosByVenta = async (venta) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/pagos-venta/${venta}`,{headers});
    return res;
}