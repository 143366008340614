const validationsConfig = {
    nombre: {
      required: true,
      minLength: 3,
      nameForMessage: "Nombre",
    },
    porcentajeMarcacion: {
      required: true,
      nameForMessage: "Porcentaje de Marcacíon",
    },
  };
  
  export default validationsConfig;