import React from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

// services
import { deleteByIdArticulos } from '../../Services/articuloService';

// Sweet alert
import Swal from 'sweetalert2';

// router
import { withRouter } from 'react-router-dom';

const ItemArticulo = ({ articulo, history, setIsLoad }) => {


    const deleteArticuloSubmit = (articulo) => {
        Swal.fire({
            title: 'Estas seguro de eliminar?',
            text: "Un articulo eliminado no se puede recuperar!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Eliminar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                // consulta a la api para eliminar
                articulo.activo = false;
                deleteByIdArticulos(articulo.idArticulo)
                    .then(res => {
                        Swal.fire(
                            'Eliminado!',
                            'El articulo fue eliminado.',
                            'success'
                        );
                        setIsLoad(true);
                    })
                    .catch(error => {
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Hubo un error vuelve a intentar!'
                        });
                        console.log(error.response);
                    })
            }
        })
    }


    return (
        <tr>
            <th scope="row">{articulo.idArticulo}</th>
            <td>{articulo.nombre}</td>
            <td>{articulo.codigo}</td>
            <td>{(articulo.pesable)?"SI":"NO"}</td>
            <td>${(articulo.costo).toFixed(2)}</td>
            <td>${(articulo.precioFinal).toFixed(2)}</td>
            <td>{articulo.porcentajeGanancia}%</td>
            <td>{articulo.familia.nombre}</td>
            <td>
                <button className="btn text-danger"
                        onClick={ () =>{history.push(`edit-articulo/${articulo.idArticulo}`, {articulo: articulo})}}>
                    <FontAwesomeIcon icon={faEdit}/> Editar
                </button>
                <button className="btn text-primary"
                        onClick={() => { deleteArticuloSubmit(articulo)}}>
                    <FontAwesomeIcon icon={faTrash}/> Eliminar
                </button>
            </td>
        </tr>
    );
}

export default withRouter(ItemArticulo);