const getErrorMessageInValidation = (nameOfInput, errorsOfInput, validationsConfig) => {
  const validationConfigInput = validationsConfig[nameOfInput];
  let mensajeError = 'El campo tiene un error';
  let nameForMesage = validationConfigInput.nameForMessage;
  switch (errorsOfInput.type) {
    case "required":
      mensajeError = `El campo ${nameForMesage} es requerido`;
      break;
    case "minLength":
      mensajeError = `El campo ${nameForMesage} debe tener como mínimo ${validationConfigInput.minLength} caracteres`;
      break;
    case "maxLength":
      mensajeError = `El campo ${nameForMesage} debe tener como máximo ${validationConfigInput.maxLength} caracteres`;
      break;
    case "max":
      mensajeError = `El campo ${nameForMesage} puede ser como máximo ${validationConfigInput.max}`;
      break;
    case "min":
      mensajeError = `El campo ${nameForMesage} puede ser como mínimo ${validationConfigInput.min}`;
      break;
    case "pattern":
      mensajeError = `${validationConfigInput.messageForErrorOfPatter}`;
      break;
    case "isNull":
      mensajeError = `En el campo ${nameForMesage} debe elegir alguna opción`;
      break;
    default:
      mensajeError = `El campo ${nameForMesage} tiene un error`;
  }
  return mensajeError;
};

export default getErrorMessageInValidation;