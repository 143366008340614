import { GET_CLIENTES, ADD_CLIENTE, EDIT_CLIENTE, GET_CLIENTE, DELETE_CLIENTE } from './Types';

export const GetClientes = (res) => {
    return{
        type: GET_CLIENTES,
        payload: res.data
    }
}

export const AddCliente =  (res) => {
    return {
        type: ADD_CLIENTE,
        payload: res.data
    }
}

export const EditaCliente = (res) => {

    return {
        type: EDIT_CLIENTE,
        payload: res.data
    }
}


export const GetCliente = (res) => {
    return {
        type: GET_CLIENTE,
        payload: res.data
    }
} 

export const DeleteCliente = (id) => {
    return {
        type: DELETE_CLIENTE,
        payload: id
    }
}