import axios from 'axios';
import {url, url_login} from './global';

export const isLogin = () => {
    if(sessionStorage.getItem('token')) return true;
    else return false;
}

export const setLogin = (token) => {
    sessionStorage.setItem('token', token);
}

export const token = () => {
    return sessionStorage.getItem('token');
}

export const signOut = () => {
    sessionStorage.clear();
}

export const login = async (username,password) => {

    let params = new URLSearchParams();
    params.set("grant_type","password");
    params.set("username",username);
    params.set("password",password);

    let headers = {
        "Content-Type" : "application/x-www-form-urlencoded",
        "Authorization" : "Basic d2ViY2xpZW50OmFkZWN2ZW50YXM="
    }

    let res = await axios.post(`${url_login}/oauth/token`, params, {headers: headers});
    return res;
}

export const register = async (usuario) => {

    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    
    let res = await axios.post(`${url}/usuario/registrar`, usuario,{headers});
    return res;
}

export const updateRole = async (usuario) => {

    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    
    let res = await axios.put(`${url}/usuario/${usuario.id.usuario.idUsuario}`, usuario,{headers});
    return res;
}

export const addRole = async (usuarioRole) => {

    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }

    let res = await axios.post(`${url}/usuarios-roles/`, usuarioRole, {headers});
    return res;
}

export const deleteById = async (id) => {
    
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }

    let res = await axios.delete(`${url}/usuarios/${id}`,{headers});
    return res;
}

export const getAllUsuarios = async () => {
    
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }

    let res = await axios.get(`${url}/usuarios`,{headers});
    return res;
}

export const getAllUsuariosPage = async (page) => {
    
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }

    let res = await axios.get(`${url}/usuarios/page/${page}`,{headers});
    return res;
}

export const getAllUsuariosNoActivos = async () => {
    
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }

    let res = await axios.get(`${url}/usuarios-bajas`,{headers});
    return res;
}



