import React from 'react';

// router
import { withRouter } from 'react-router-dom';

const ItemHistorialStock = ({stock}) => {

    return (
        <tr>
            <th scope="row">{stock.articulo.codigo}</th>
            <td>{stock.cantidadActual} - {stock.cantidadNueva}</td>
            <td>{stock.fechaModificacion.split('T')[0]}</td>
            <td>{stock.motivo}</td>
        </tr>
    );
}

export default withRouter(ItemHistorialStock);