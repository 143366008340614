import axios from 'axios';
import {url} from './global';


export const getAllSucursales = async () => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/sucursales`,{headers});
    return res;
}

export const getByIdSucursal = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/sucursales/${id}`,{headers});
    return res;
}

export const editByIdSucursal = async (sucursal) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/sucursales/${sucursal.idSucursal}`, sucursal,{headers});
    return res;
}

export const deleteByIdSucursal = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.delete(`${url}/sucursales/${id}`,{headers});
    return res;
}

export const saveSucursal = async (sucursal) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/sucursales`,sucursal,{headers});
    return res;
}
