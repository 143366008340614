import axios from 'axios';
import {url} from './global';


export const getAllMediosPagos = async () => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/medios-pagos`,{headers});
    return res;
}

export const getByIdMediosPagos = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/medios-pagos/${id}`,{headers});
    return res;
}

export const editByIdMediosPagos = async (medioPago) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/medios-pagos/${medioPago.idMedioPago}`, medioPago ,{headers});
    return res;
}

export const deleteByIdMediosPagos = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.delete(`${url}/medios-pagos/${id}`,{headers});
    return res;
}

export const saveMediosPagos = async (medioPago) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/medios-pagos`,medioPago ,{headers});
    return res;
}
