import {
    GET_UMEDIDAS, GET_UMEDIDA, ADD_UMEDIDA, EDIT_UMEDIDA, DELETE_UMEDIDA
} from '../Actions/Types';

// cada reducer tiene su propio state
const initialState = {
    medidas: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_UMEDIDAS:
            return {
                ...state,
                medidas: action.payload
            }
        case GET_UMEDIDA:
            return {
                ...state,
                medida: action.payload
            }
        case ADD_UMEDIDA:
            return {
                ...state,
                medidas: [...state.medidas, action.payload]
            }
        case EDIT_UMEDIDA:
            return {
                ...state,
                medidas: (state.medidas.map(
                    medida => medida.idUnidadMedida === action.payload.idUnidadMedida
                        ? (medida = action.payload)
                        : medida
                ))
            }
        case DELETE_UMEDIDA:
            return {
                ...state,
                medidas: state.medidas.filter(medida => medida.idUnidadMedida !== action.payload)
            }
        default:
            return state;
    }
}
