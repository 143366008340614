import axios from 'axios';
import {url} from './global';

// GET ALL EMPLEADOS PAGINATION
export const getAllEmpleadosPagination = async (page, data) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.post(`${url}/empleados/page/${page}`, data,{headers});
    return res;
}
// END

// GET ALL EMPLEADOS PAGINATION BY SUCURSAL
export const getAllEmpleadosPaginationBySucursal = async (page, data) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.post(`${url}/empleados/sucursal/${page}`, data,{headers});
    return res;
}
// END

export const getByIdEmpleado = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/empleados/${id}`,{headers});
    return res;
}

export const editByIdEmpleado = async (empleado) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/empleados/${empleado.idEmpleado.persona.idPersona}`, empleado,{headers});
    return res;
}

export const deleteByIdEmpleado = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.delete(`${url}/empleados/${id}`,{headers});
    return res;
}

export const saveEmpleado = async (empleado) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/empleados`,empleado,{headers});
    return res;
}

export const getAllEmpleados = async () => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/empleados`,{headers});
    return res;
}

export const getAllEmpleadosBySucursal = async (sucursal) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/empleados/sucursal/${sucursal}`,{headers});
    return res;
}