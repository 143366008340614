import React, { Fragment, useState } from 'react';

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarcode, faPlus, faFileExcel,} from '@fortawesome/free-solid-svg-icons'

// services
import { saveArticulosImportar} from '../../Services/articuloService';

// routes
import { withRouter } from 'react-router-dom';

// Sweet alert
import Swal from 'sweetalert2';


const AddArticulosImportar = ({ history }) => {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    const [file, setFile] = useState();

    const addArticuloSubmit = e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file',file)

        saveArticulosImportar(formData, token.empleado.sucursal.idSucursal)
            .then(data => {                
                Swal.fire({
                    title: "Importación exitosa",
                    text: "Se importaron los articulos correctamente.",
                    type: 'success',
                    showConfirmButton: false,
                    timer: 2000
                });
            })
            .catch(error => {
                Swal.fire({
                    title: "Error",
                    text: "No se pudieron importar los articulos, verifique los datos de su archivo",
                    type: 'error',
                    showConfirmButton: false,
                    timer: 2000
                });
            })
        
    }
   

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faBarcode}
                        title={'Importar Articulos'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={addArticuloSubmit} encType="multipart/form-data">
                        <div className="form-row justify-content-center">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputFile">Seleccione un archivo</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faFileExcel} />
                                    </span>
                                    <input type="file"
                                        id="inputFile"
                                        name="fileCSV"
                                        className="form-control"
                                        placeholder="Ej. Lacteos, Gaseosas..."
                                        onChange={e => setFile(e.target.files[0])} />
                                </div>
                            </div>
                            

                        </div>



                        <div className="text-center">
                            <button type="submit" className="btn btn-outline-success">
                                <FontAwesomeIcon icon={faPlus} /> Importar</button>
                        </div>
                    </form>
                </div>



            </div>
        </Fragment>
    );
}

export default withRouter(AddArticulosImportar);