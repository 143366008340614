import React, { Fragment, useEffect, useState } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

// alert swal
import Swal from 'sweetalert2'

// services
import { getAllStockSucursalPage } from '../../../Services/stockSucursalService';

import ItemArticuloModalOferta from './ItemArticuloModalOferta';

// router
import { withRouter } from 'react-router-dom';

const ModalArticulosOferta = ({ setCodigoArticulo, setArticulo }) => {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));

    const [articulos, setArticulos] = useState([]);
    const [isLoad, setIsLoad] = useState(true);

    // pagination
    const [page, setPage] = useState(0);
    const [totalRegister, setTotalRegister] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [query, setQuery] = useState("");

    useEffect(() => {

        let data = {
            query,
            sucursal: token.empleado.sucursal.idSucursal
        }

        getAllStockSucursalPage(page, data)
            .then(res => {
                setArticulos(res.data.content);
                setTotalRegister(res.data.totalElements);
                setTotalPage(res.data.totalPages);
                setIsLoad(false);
            })
            .catch(error => {
                console.log(error.response)
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);

    const onChangeArticulo = e => {

        let valor = e.target.value;

        let data = {
            query: valor,
            sucursal: token.empleado.sucursal.idSucursal
        }

        setQuery(e.target.value);

        if (e.key === 'Enter') {

            getAllStockSucursalPage(0, data)
                .then(res => {
                    setArticulos(res.data.content);
                    setTotalRegister(res.data.totalElements);
                    setTotalPage(res.data.totalPages);
                    setIsLoad(false);
                })
                .catch(error => {
                    console.log(error.response)
                });
        }

    }

    const nextPage = () => {

        if (page === totalPage) {
            Swal.fire({
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000
            })
            return;
        }

        let data = {
            query,
            sucursal: token.empleado.sucursal.idSucursal
        }

        getAllStockSucursalPage(page + 1, data)
            .then(res => {
                setPage(page + 1);
                setTotalPage(res.data.totalPages);
                setTotalRegister(res.data.totalElements);
                setArticulos(res.data.content);
                setIsLoad(false);
            })
            .catch(error => {
                console.log(error);
            });

    }

    const previousPage = () => {
        if (page === 0) {
            Swal.fire({
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000
            })
            return;
        }

        let data = {
            query,
            sucursal: token.empleado.sucursal.idSucursal
        }

        getAllStockSucursalPage(page - 1, data)
            .then(res => {
                setPage(page - 1);
                setTotalPage(res.data.totalPages);
                setTotalRegister(res.data.totalElements);
                setArticulos(res.data.content);
                setIsLoad(false);
            })
            .catch(error => {
                console.log(error);
            });


    }

    return (
        <Fragment>

            <button type="button" className="btn btn-outline-info"
                data-toggle="modal" data-target="#modalArticuloOferta">
                <FontAwesomeIcon icon={faSearch} />
            </button>

            <div className="modal fade w-100" id="modalArticuloOferta" tabIndex="-1" role="dialog" aria-labelledby="modalArticuloOfertaTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalArticuloOfertaTitle">Articulos</h5>
                        </div>
                        <div className="modal-body">
                            <div className="input-group">
                                <input type="text" className="form-control"
                                    placeholder="Buscar..."
                                    onKeyPress={(e) => { onChangeArticulo(e) }} />
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="addon-wrapping"><FontAwesomeIcon icon={faSearch} /></span>
                                </div>
                            </div>
                            {/* Inicio de paginador */}
                            <div className="d-flex flex-row bd-highlight">
                                <div className="p-2 bd-highlight">
                                    <button className="btn btn-outline-info"
                                        onClick={() => previousPage()} aria-label="Previous">
                                        <span aria-hidden="true">&laquo; Anterior</span>
                                    </button>
                                </div>
                                <div className="p-2 bd-highlight mt-1">
                                    Registros: {totalRegister} | Pagina actual: {page + 1} | Total páginas : {totalPage}
                                </div>
                                <div className="p-2 bd-highlight">
                                    <button className="btn btn-outline-info"
                                        onClick={() => nextPage()} aria-label="Next">
                                        <span aria-hidden="true">Siguiente &raquo;</span>
                                    </button>
                                </div>
                            </div>
                            <table className="table mt-3 text-center">
                                <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Precio</th>
                                        <th scope="col">Stock</th>
                                        <th scope="col">Seleccionar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {articulos.map((articulo) => (
                                        <ItemArticuloModalOferta
                                            key={parseInt(articulo.articulo.idArticulo)}
                                            articulo={articulo}
                                            setArticulo={setArticulo}/>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    );

}

export default withRouter(ModalArticulosOferta);