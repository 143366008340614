import React, { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSearch, faBoxes, faList } from '@fortawesome/free-solid-svg-icons'
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo';
import ItemCompras from './ItemCompras';
import { withRouter } from 'react-router-dom';
import { getAllCompras, getAllComprasBySucursal } from '../../Services/compraService';
import Swal from 'sweetalert2'

const Compra = ({ history }) => {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    let rol = token.authorities[0];

    const [isLoad, setIsLoad] = useState(true);
    const [compras, setCompras] = useState([]);

    // pagination
    const [page, setPage] = useState(0);
    const [totalRegister, setTotalRegister] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [query, setQuery] = useState("");

    useEffect(() => {
        if (isLoad) {
            if (rol === 'ROLE_ADMIN') {
                let data = { query }
                getAllCompras(page, data)
                    .then(res => {                        
                        setCompras(res.data.content);
                        setTotalRegister(res.data.totalElements);
                        setTotalPage(res.data.totalPages);
                        setIsLoad(false);
                    })
                    .catch(err => console.log(err.response));
            } else {
                let data = {
                    query, sucursal: token.empleado.sucursal.idSucursal
                }
                getAllComprasBySucursal(page, data)
                    .then(res => {                        
                        setCompras(res.data.content);
                        setTotalRegister(res.data.totalElements);
                        setTotalPage(res.data.totalPages);
                        setIsLoad(false);
                    })
                    .catch(err => console.log('error'));
            }
        } else {
            setIsLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);

    const nextPage = () => {
        if (page === totalPage - 1) {
            Swal.fire({
                toast: true,
                position: 'top',
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000
            })
            return;
        }

        if (rol === 'ROLE_ADMIN') {
            let data = { query }
            getAllCompras(page + 1, data)
                .then(res => {                    
                    setCompras(res.data.content);
                    setTotalRegister(res.data.totalElements);
                    setPage(page + 1);
                    setTotalPage(res.data.totalPages);
                    setIsLoad(false);
                })
                .catch(err => console.log('error'));
        } else {
            let data = {
                query, sucursal: token.empleado.sucursal.idSucursal
            }
            getAllComprasBySucursal(page + 1, data)
                .then(res => {                    
                    setCompras(res.data.content);
                    setTotalRegister(res.data.totalElements);
                    setPage(page + 1)
                    setTotalPage(res.data.totalPages);
                    setIsLoad(false);
                })
                .catch(err => console.log('error'));
        }
    }

    const previousPage = () => {
        if (page === 0) {
            Swal.fire({
                toast: true,
                position: 'top',
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000
            })
            return;
        }

        if (rol === 'ROLE_ADMIN') {
            let data = { query }
            getAllCompras(page - 1, data)
                .then(res => {                    
                    setCompras(res.data.content);
                    setTotalRegister(res.data.totalElements);
                    setPage(page - 1)
                    setTotalPage(res.data.totalPages);
                    setIsLoad(false);
                })
                .catch(err => console.log('error'));
        } else {
            let data = {
                query, sucursal: token.empleado.sucursal.idSucursal
            }
            getAllComprasBySucursal(page, data)
                .then(res => {                    
                    setCompras(res.data.content);
                    setTotalRegister(res.data.totalElements);
                    setTotalPage(res.data.totalPages);
                    setIsLoad(false);
                })
                .catch(err => console.log('error'));
        }

    }

    const handleBuscador = (e) => {
        setQuery(e.target.value);
        if (e.key === 'Enter') {            
            // verificar si la query isEmpty hacer otra consulta

            if (rol === 'ROLE_ADMIN') {
                let data = { query: e.target.value }
                getAllCompras(page, data)
                    .then(res => {    
                        setPage(0)                    
                        setCompras(res.data.content);
                        setTotalRegister(res.data.totalElements);
                        setTotalPage(res.data.totalPages);
                        setIsLoad(false);
                    })
                    .catch(err => console.log('error'));
            } else {
                let data = {
                    query: e.target.value, sucursal: token.empleado.sucursal.idSucursal
                }
                getAllComprasBySucursal(page, data)
                    .then(res => {                        
                        setCompras(res.data.content);
                        setTotalRegister(res.data.totalElements);
                        setTotalPage(res.data.totalPages);
                        setIsLoad(false);
                    })
                    .catch(err => console.log('error'));
            }

        }
    }

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faBoxes}
                        title={'Compras'} />
                </div>

                <div className="d-flex bd-highlight">

                    <div className="p-2 flex-grow-1 bd-highlight">
                        <div className="input-group-prepend input-group-lg">
                            <span className="input-group-text" id="inputGroupPrepend">
                                <FontAwesomeIcon icon={faSearch} />
                            </span>
                            <input type="text"
                                className="form-control form-lg"
                                id="inputNombre"
                                name="precio"
                                placeholder="Buscar"
                                onKeyPress={(e) => { handleBuscador(e) }}
                            />
                        </div>
                    </div>

                    <div className="p-2 bd-highlight">
                                <button className="btn btn-outline-warning btn-lg"
                                    onClick={() => { history.push('/compras-informes') }}>
                                    <FontAwesomeIcon icon={faList} /> Informes
                                </button>
                            </div>

                    <div className="p-2 bd-highlight">
                        <button className="btn btn-outline-success btn-lg"
                            onClick={() => { history.push('/add-compras') }}>
                            <FontAwesomeIcon icon={faPlus} /> Crear
                        </button>
                    </div>

                    
                </div>

                <div className="bg-gris p-3">

                    <div className="d-flex flex-row bd-highlight">
                        <div className="p-2 bd-highlight">
                            <button className="btn btn-outline-info"
                                onClick={() => previousPage()} aria-label="Previous">
                                <span aria-hidden="true">&laquo; Anterior</span>
                            </button>
                        </div>
                        <div className="p-2 bd-highlight mt-1">
                            Registros: {totalRegister} | Pagina actual: {page + 1} | Total páginas : {totalPage}
                        </div>
                        <div className="p-2 bd-highlight">
                            <button className="btn btn-outline-info"
                                onClick={() => nextPage()} aria-label="Next">
                                <span aria-hidden="true">Siguiente &raquo;</span>
                            </button>
                        </div>
                    </div>



                    <table className="table table-striped mt-3 text-center">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Proveedor</th>
                                <th scope="col">Total</th>
                                <th scope="col">Estado</th>
                                <th scope="col">Fecha</th>
                                <th scope="col">Sucursal</th>
                                <th scope="col">Comprobante</th>
                                <th scope="col">Nº Comprobante</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>

                            {compras.map((compra) => (
                                <ItemCompras
                                    key={parseInt(compra.idCompra)}
                                    compra={compra}
                                    setIsLoad={setIsLoad} />
                            ))}

                        </tbody>
                    </table>

                </div>

            </div>
        </Fragment>
    );
}

export default withRouter(Compra);