import axios from 'axios';
import {url} from './global';

// PAGINATION
export const getAllStockSucursalPage = async (page, data) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.post(`${url}/stock-sucursal/page/${page}`, data,{headers});
    return res;
}

// END PAGINATION


// ARTICULOS CON LISTA DE PRECIO APLICADA
export const getAllStockSucursalArticuloVenta = async (page, data) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }

    let res = await axios.post(`${url}/stock-sucursal/articulos/${page}`, data ,{headers});
    return res;
}

export const getArticuloVenta = async (sucursal, data) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }

    let res = await axios.post(`${url}/stock-sucursal-articulo/${sucursal}`, data ,{headers});
    return res;
}
// END ARTICULO-LISTAPRECIO

export const saveStockSucursal = async (stockSucursal) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.post(`${url}/stock-sucursal`, stockSucursal, {headers});
    return res;
}


export const getAllStockSucursal = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/stock-sucursal/${id}`,{headers});
    return res;
}

export const getArticuloByIdStockSucursalAndIdArticulo = async (idSucursal, idArticulo) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/stock-sucursal/${idSucursal}/${idArticulo}`,{headers});
    return res;
}

export const getAllArticulosBySucursal = async (idSucursal) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/stock-sucursal/${idSucursal}`,{headers});
    return res;
}

export const editByIdStockSucursal = async (stockSucursal) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/stock-sucursal/${stockSucursal.id.sucursal.idSucursal}/${stockSucursal.id.articulo.idArticulo}`, stockSucursal,{headers});
    return res;
}

export const deleteArticuloByIdStockSucursalAndIdArticulo = async (idSucursal, idArticulo) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.delete(`${url}/stock-sucursal/${idSucursal}/${idArticulo}`,{headers});
    return res;
}

export const getHistorialStockByArticulo = async (idSucursal,idArticulo) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/stock-historial/sucursal/${idSucursal}/articulo/${idArticulo}`,{headers});
    return res;
}



