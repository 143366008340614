import React, { Fragment, useEffect, useState } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalculator, faPlus } from '@fortawesome/free-solid-svg-icons'

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo';
import ItemListaPrecio from './ItemListaPrecio';

// redux
import { useDispatch } from 'react-redux'

// actions
import { DeleteListasPrecio } from '../../Actions/ListaPrecioAction';

// route
import { withRouter } from 'react-router-dom';

// services
import { getAllListasPrecios } from '../../Services/listaPrecioService';


const ListasPrecios = ({ history }) => {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    let rol = token.authorities[0];

    const [isLoad, setIsLoad] = useState(true);
    const [listasPrecios, setListasPrecios] = useState([]);
    const dispatch = useDispatch();
    const deleteListaPrecio = (id) => dispatch(DeleteListasPrecio(id));


    useEffect(() => {
        if (rol !== 'ROLE_ADMIN') history.goBack();
        if (isLoad) {
            getAllListasPrecios()
                .then(res => {
                    setListasPrecios(res.data);
                    setIsLoad(false);
                })
                .catch(err => console.log('error'));
        } else {
            setIsLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faCalculator}
                        title={'Listas de Precios'} />
                </div>

                <div className="bg-gris p-3">
                    <button className="btn btn-outline-success btn-lg ml-2"
                        onClick={() => { history.push('/add-lista-precio') }}>
                        <FontAwesomeIcon icon={faPlus} /> Crear
                    </button>

                    <table className="table table-striped mt-3 text-center">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Porcentaje Marcacion</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>

                            {listasPrecios.map((lista) => (
                                <ItemListaPrecio
                                    key={parseInt(lista.idListaPrecio)}
                                    lista={lista}
                                    deleteListaPrecio={deleteListaPrecio}
                                    setIsLoad={setIsLoad} />
                            ))}

                        </tbody>
                    </table>

                </div>

            </div>
        </Fragment>
    );
}

export default withRouter(ListasPrecios);