import React from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

// routes
import { withRouter } from 'react-router-dom';

// Sweet alert
//import Swal from 'sweetalert2';

// services
//import { deleteByIdFamilias } from '../../Services/familiaService';

const ItemFamilia = ({ familia, history, setIsLoad }) => {


    return (
        <tr key={parseInt(familia.idFamilia)}>
            <th scope="row">{familia.idFamilia}</th>
            <td>{familia.nombre}</td>
            <td>{familia.ganancia}%</td>
            <td>
                <button className="btn text-danger"
                    onClick={() => { history.push(`/edit-familia/${familia.idFamilia}`, {familia: familia}) }}>
                    <FontAwesomeIcon icon={faEdit} /> Editar
                </button>
            </td>
        </tr>
    );
}

export default withRouter(ItemFamilia);