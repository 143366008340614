import React, { Fragment, useEffect, useState } from "react";

import { Modal, Button } from "react-bootstrap";

// redux
import { useDispatch } from "react-redux";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheck, faArrowLeft, faMoneyBill, faDollarSign, faPlusCircle,
  faMinusCircle, faCreditCard, faPlus, faTrashAlt} from "@fortawesome/free-solid-svg-icons";

// // services
import { getAllMediosPagos } from "../../../Services/medioPagoService";
import { getAllTarjetas } from "../../../Services/tarjetaService";
import { getCuentaCorrienteByDni } from "../../../Services/cuentaCorrienteClienteService";
import { saveVentas } from "../../../Services/ventaService";
import { createComprobanteAfip} from "../../../Services/reporteService";

// actions
import { GetMedioPagos } from "../../../Actions/MedioPagoAction";
import { GetTarjetas } from "../../../Actions/TarjetaAction";

// router
import { withRouter } from "react-router-dom";

// sweet alert
import Swal from "sweetalert2";

// css
import "./FinalizarVentaModal.css";
//import { Ticket } from "../../../Helpers/ticket";


const FinalizarVentaModal = ({ ventaDatos, show, handleShow, handleClose, reset, isLoad }) => {

	let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
	const dispatch = useDispatch();

	const [mediosPagos, setMediosPagos] = useState([]);
	const [medioPago, setMedioPago] = useState(1);

	const [tarjetas, setTarjetas] = useState([]);
	const [tarjeta, setTarjeta] = useState(null);

	const [total, setTotal] = useState(0);
	const [saldo, setSaldo] = useState(0);
	const [monto, setMonto] = useState(0);
	const [recargoDescuento, setRecargoDescuento] = useState(0);

	const [cobrosParcializados, setCobrosParcializados] = useState([]);

	// datos de la trajeta de credito
	const [nombreTitular, setNombreTitular] = useState("");
	const [numeroTarjeta, setNumeroTarjeta] = useState("");
	const [numeroLote, setnumeroLote] = useState("");
	const [cantidadCuotasTarjeta, setCantidadCuotasTarjeta] = useState(1);
	
	const [isCC, setIsCC] = useState(false);
	const [cuit, setCuit] = useState('');

	//Custom Swal
    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

	useEffect(() => {
		if (isLoad) {

			// cargo los medios de pagos
			getAllMediosPagos()
				.then((res) => {
				dispatch(GetMedioPagos(res));
				setMediosPagos(res.data);
				})
				.catch((error) => console.log(error.response));

			// cargos las tarjetas
			getAllTarjetas()
				.then((res) => {
				dispatch(GetTarjetas(res));
				setTarjetas(res.data);
				})
				.catch((error) => console.log(error.response));
			
		} 
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoad]);


	const handleShowFirst = () => {
		setCuit('')
		setMedioPago(1);
		setCobrosParcializados([])
		setSaldo(ventaDatos.total);
		setTotal(ventaDatos.total);
		setMonto(ventaDatos.total);
		if (ventaDatos.cliente.idCliente.persona.dni !== 99999999){
			getCuentaCorrienteByDni(ventaDatos.cliente.idCliente.persona.dni)
				.then(async data => await setIsCC(true))
				.catch(async error => await setIsCC(false))
		}
		handleShow();
	}

	const agregarCobroParcializado = () => {

		//Preveer de ingresar mas dinero
		if (parseFloat(saldo) <= 0) {
		Toast.fire({
			title: "Ya se ingreso un monto suficiente.",
			type: "warning",
		});
		return;
		}

		// validar que el medio de pago no esta ingresado
		let isPresent = false;
		cobrosParcializados.forEach((item) => {
		if (parseInt(item.medioPago.idMedioPago, 10) === parseInt(medioPago, 10))
			isPresent = true;
		});

		// if (parseInt(medioPago) === 4) {
		// 	getCuentaCorrienteByDni(ventaDatos.cliente.idCliente.persona.dni)
		// 		.then((data) => setIsCC(true))
		// 		.catch((error) => setIsCC(false))
		// 	}
	

		//Valirdar que ingreso un monto y un metodo de pago
		if (isNaN(parseFloat(monto))) {
		Toast.fire({
			title: "Ingrese un monto válido.",
			type: "warning",
		});
		return;
		}

		if (isNaN(parseInt(medioPago))) {
		Toast.fire({
			title: "Seleccione un método de pago",
			type: "warning",
		});
		return;
		}

		if (!isCC && parseInt(medioPago) === 4) {
		Toast.fire({
			title: "El cliente no posee una cuenta corriente.",
			type: "warning",
		});
		return;
		}

		// filtramos el medio de pago seleccionado
		let mediopagoSelected = mediosPagos.filter(function (mp) {
		return mp.idMedioPago === parseInt(medioPago, 10);
		});

		//No puede ingresarse 2 veces efectivo o CC
		if (isPresent && (parseInt(mediopagoSelected[0].idMedioPago, 10) === 1 ||
				parseInt(mediopagoSelected[0].idMedioPago, 10) === 4)) 
		{
			Toast.fire({
				title: "No puede ingresar dos veces este medio de pago",
				type: "warning",
			});
			return;
		}

		// VALIDACION RECARGO/DESCUENTO
		if (isNaN(parseFloat(recargoDescuento))) {
		Toast.fire({
			title: "Debe ingresar un recargo o descuento valido",
			type: "warning",
		});
		return;
		}

		// formamos el item de cobros parcializados
		let itemCobroParcializado = {
			monto: parseFloat(monto),
			descuento: 0, // (parseFloat(recargoDescuento) < 0) ? recargoDescuento * (-1) : 0,
			recargo: 0, // (parseFloat(recargoDescuento) > 0) ? recargoDescuento : 0,
			medioPago: mediopagoSelected[0],
		};


		if (parseInt(mediopagoSelected[0].idMedioPago) === 2) {
		// filtramos la tarjeta seleccionada si selecciono la opcion
			if (!isNaN(parseFloat(tarjeta))) {
				let tarjetaSelected = tarjetas.filter(function (t) {
				return t.idTarjeta === parseInt(tarjeta, 10);
				});
				itemCobroParcializado.tarjeta = tarjetaSelected[0];
				itemCobroParcializado.tarjetaDato = {
					lote: numeroLote,
					cupon: 0,
					numeroTarjeta,
					nombreTitular,
					cantidadCuotas: cantidadCuotasTarjeta,
				};
			} else {
				Toast.fire({
				title: "Debe ingresar una tarjeta valida valido",
				type: "warning",
				});
				return;
			}
		}

		// CHEQUE
		if (parseInt(mediopagoSelected[0].idMedioPago) === 3) {
			itemCobroParcializado.cheque = {
				monto: itemCobroParcializado.monto,
		};
		}

		// si es mayor que 0 entonces hay recargo
		if (parseFloat(itemCobroParcializado.recargo) > 0) {
			
			let recargo = parseFloat(monto * parseFloat(itemCobroParcializado.recargo / 100));
			itemCobroParcializado.monto = monto;
			setTotal(total + recargo);
			
			if (saldo + recargo - itemCobroParcializado.monto < 0)
				setSaldo(0);
			else 
				setSaldo(saldo + recargo - itemCobroParcializado.monto);


		} else if (parseFloat(itemCobroParcializado.descuento) < 0) {
			
			let descuento = parseFloat(monto * parseFloat(itemCobroParcializado.descuento / 100));
			itemCobroParcializado.monto = monto;
			setTotal(total - descuento);
			
			if (saldo - descuento - itemCobroParcializado.monto < 0)
				setSaldo(0);
			else
				setSaldo(saldo - descuento - itemCobroParcializado.monto);	
    	}

		// agrego item a la tabla de cobro parcializados
		setCobrosParcializados([...cobrosParcializados, itemCobroParcializado]);

		// reiniciamos todos los valores
		setnumeroLote("");
		setNombreTitular("");
		setNumeroTarjeta("");
		setTarjeta(null);
		setMedioPago(1);
		setCantidadCuotasTarjeta(1);
  
	};

	const deleteItemCobroParcializado = (cb,key) => {

		//determinamos si hubo un descuento o un recargo para volver a la normalidad el total
		if (parseFloat(cb.recargo) > 0) { // si > 0 hubo un recargo
			let recargo = parseFloat(cb.monto * parseFloat(cb.recargo / 100));
			setTotal(total - recargo);
			setSaldo((saldo + parseFloat(cb.monto)) - recargo);
		} else { // sino descuento
			let descuento = parseFloat(cb.monto * parseFloat(cb.descuento / 100));
			setTotal(total + descuento);
			setSaldo((saldo + parseFloat(cb.monto)) + descuento);
		}

		let itemsDelete = cobrosParcializados.filter((cp,index) => {
			return (parseInt(cp.medioPago.idMedioPago) !== parseInt(cb.medioPago.idMedioPago) || key !== index);
		});

		setCobrosParcializados(itemsDelete);
	}

	const finalizarVenta = async () => {
		
		// VALIDACION
		if (cobrosParcializados.length <= 0) {
			Toast.fire({
				title: 'No se ingreso ninguna forma de pago.',
				type: 'info'
			})
			return;
		}

		// VERIFICO SI LA SUMA DE PARCIALIZADOS Y EL TOTAL COINDICEN
		let sumaParcializados = 0.0;
		cobrosParcializados.map(cobros => sumaParcializados += Number(cobros.monto));

		if(Number(sumaParcializados).toFixed(2) !== Number(total).toFixed(2)) {
			Toast.fire({
				title: 'La suma de los pagos debe ser igual al total de la venta.',
				type: 'info'
			})
			return;
		}

		// completamos los datos de la venta
		let venta = {
			total: parseFloat(total.toFixed(2)), 
			numeroComprobante: ventaDatos.numeroComprobante,
			numeroComprobanteAfip: -1,
			tipoComprobante: ventaDatos.tipoComprobante,
			sucursal: token.empleado.sucursal, 
			cliente: ventaDatos.cliente, 
			empleado: token.empleado, 
			items: ventaDatos.itemsFactura,
			facturada: false
		}

		let dataVentaRequest = {
			venta,
			medioPagos: cobrosParcializados
		}

		try {
			if (cuit !== '')  {
				await createComprobanteAfip(venta,cuit)
					.then(async res => {
						await console.log(res.data)
						dataVentaRequest.venta.facturada = true
					})
					.catch(err => console.log(err))
			}
		} catch (error) {
			console.log(error.message)
			console.log('No se pudo crear la factura electronica.')
		}

		try {
			await saveVentas(dataVentaRequest)
				.then(async res => {
					Swal.fire({
						toast: true,
						position: 'top',
						type: 'success',
						title: 'Venta realizada.',
						timer: 1500
					})
					await handleClose();
					await reset();
				})
				.catch(error => {
					Swal.fire({
						type: 'error',
						title: 'No se pudo proceder con la venta.',
						timer: 2500,
					});

				})

		} catch (error) {
			console.error(error.message)
		}


		// try { await Ticket(venta)
		// 			.then(async res => {
		// 				console.log('Imprimiendo ticket.')

		// 			})
		// 			.catch(error => {console.log(error)})
			
		// } catch (error) {
			
		// }
		//Ingresamos la venta en la BD

	}


	return (
		<Fragment>
			<Button onClick={handleShowFirst} variant="outline-danger" size="lg" className="widthCobrar">
				Cobrar <FontAwesomeIcon icon={faMoneyBill} /> 
			</Button>
			<Modal
				
				backdrop="static"
				show={show}
				onHide={handleClose}
				size="xl"
				contentClassName="centrado"
			>
				<Modal.Header closeButton>
					<Modal.Title>Formas de pago</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<h3>Saldo: ${ventaDatos.total}</h3>

					{/* MEDIOS DE PAGO, MONTO, DESCUENTO / RECARGO */}
					<div className="form-row">
						<div className="form-group col-md">
							<label htmlFor="inputActivo">Medio de pago</label>
							<div className="input-group-prepend">
								<span className="input-group-text" id="inputGroupPrepend">
								<FontAwesomeIcon icon={faMoneyBill} />
								</span>
								<select
								id="inputComprobante"
								className="custom-select"
								name="comprobante"
								value={medioPago}
								onChange={(e) => setMedioPago(e.target.value)}
								>
								{mediosPagos.map((mp, index) => (
									<option key={index} value={mp.idMedioPago}>
									{mp.nombre}
									</option>
								))}
								</select>
							</div>
						</div>

						<div className="form-group col-md">
							<label htmlFor="inputActivo">Monto</label>
							<div className="input-group-prepend">
								<span className="input-group-text" id="inputGroupPrepend">
								<FontAwesomeIcon icon={faDollarSign} />
								</span>
								<input
								type="number"
								step="any"
								className="form-control"
								id="inputMonto"
								name="monto"
								placeholder="Monto"
								//ref={montoRef}
								defaultValue={parseFloat(monto).toFixed(2)}
								onChange={(e) => setMonto(e.target.value)}
								/>
							</div>
						</div>

						<div className="form-group col-md">
							<label htmlFor="inputActivo">Recargos/Descuentos +/-</label>
							<div className="input-group-prepend">
								<span className="input-group-text" id="inputGroupPrepend">
								<FontAwesomeIcon icon={faPlusCircle} /> /{" "}
								<FontAwesomeIcon icon={faMinusCircle} />
								</span>
								<input
								type="number"
								step="any"
								className="form-control"
								id="inputPrecio"
								name="precio"
								placeholder="recargo o descuento"
								defaultValue={recargoDescuento}
								onChange={(e) => setRecargoDescuento(e.target.value)}
								/>
							</div>
						</div>
					</div>

					{parseInt(medioPago, 10) === 2 ? (

					// SI EL MEDIO DE PAGO ES TARJETA
					<div className="fade-in-image">
						<div className="form-row">
							<div className="form-group col-md">
								<label htmlFor="inputActivo">Tarjetas</label>
								<div className="input-group-prepend">
									<span className="input-group-text" id="inputGroupPrepend">
									<FontAwesomeIcon icon={faCreditCard} />
									</span>
									<select
									id="inputComprobante"
									className="custom-select"
									name="comprobante"
									value={tarjeta}
									onChange={(e) => setTarjeta(e.target.value)}
									>
									<option value={null}>-- SELECCIONE TARJETA --</option>
									{tarjetas.map((t, index) => (
										<option key={index} value={t.idTarjeta}>
										{t.nombre}
										</option>
									))}
									</select>
								</div>
							</div>

							<div className="form-group col-md">
								<label htmlFor="inputActivo">Cantidad de cuotas</label>
								<div className="input-group-prepend">
									<span className="input-group-text" id="inputGroupPrepend">
									<FontAwesomeIcon icon={faCreditCard} />
									</span>
									<input
									type="number"
									className="form-control"
									id="inputCantidadCuotas"
									name="precio"
									value={cantidadCuotasTarjeta}
									onChange={(e) => setCantidadCuotasTarjeta(e.target.value)}
									/>
								</div>
							</div>

							<div className="form-group col-md">
								<label htmlFor="inputActivo">Nombre del titular</label>
								<div className="input-group-prepend">
									<span className="input-group-text" id="inputGroupPrepend">
									<FontAwesomeIcon icon={faCreditCard} />
									</span>
									<input
									type="text"
									className="form-control"
									id="inputNombreTitular"
									name="precio"
									placeholder=""
									value={nombreTitular}
									onChange={(e) => setNombreTitular(e.target.value)}
									/>
								</div>
							</div>

							<div className="form-group col-md">
								<label htmlFor="inputActivo">Numero tarjeta</label>
								<div className="input-group-prepend">
									<span className="input-group-text" id="inputGroupPrepend">
									<FontAwesomeIcon icon={faCreditCard} />
									</span>
									<input
									type="text"
									className="form-control"
									id="inputNTarjeta"
									name="precio"
									placeholder="Ingrese los ultimos 4 dígitos."
									value={numeroTarjeta}
									onChange={(e) => setNumeroTarjeta(e.target.value)}
									/>
								</div>
							</div>

							<div className="form-group col-md">
								<label htmlFor="inputActivo">Nro autorización / lote</label>
								<div className="input-group-prepend">
									<span className="input-group-text" id="inputGroupPrepend">
									<FontAwesomeIcon icon={faCreditCard} />
									</span>
									<input
									type="text"
									className="form-control"
									id="inputNAutorizacion"
									name="precio"
									placeholder=""
									value={numeroLote}
									onChange={(e) => setnumeroLote(e.target.value)}
									/>
								</div>
							</div>
						</div>

						<div className="row justify-content-center">
							{tarjetas
							? tarjetas.map((t, index) => {
								return (
									<div class="alert alert-warning col-md mr-1" role="alert">
										<h4>{t.nombre}</h4>
										<p> Cantidad de cuotas sin interes {t.cuotasSinInteres}. </p>
										<p> Maxima financiacion de {t.cuotasMaximaFinanciacion}{" "} cuotas.</p>
										<p> {parseFloat(t.recargo) > 0 ? `Recargo de ${t.recargo}%` : `Descuento de ${t.descuento}%`} </p>
									</div>
								);
								})
							: null}
						</div>
					</div>
					) : null}

					<div className="p-2 row justify-content-center">
						<button className="btn btn-outline-info" onClick={agregarCobroParcializado}>
							<FontAwesomeIcon icon={faPlus} /> Agregar
						</button>
					</div>

					{/* MEDIOS DE PAGOS INGRESADOS */}
					<div className="p-2">
						<table className="table centrado">
							<thead className="thead-dark">
								<tr>
								<th scope="col">Forma de Pago</th>
								<th scope="col">Monto</th>
								<th scope="col">Eliminar</th>
								</tr>
							</thead>

							<tbody>
								{cobrosParcializados
								? cobrosParcializados.map((cb, index) => {
									return (
										<tr key={parseInt(index)}>
										<td>{cb.medioPago.nombre}</td>
										<td>${parseFloat(cb.monto).toFixed(2)}</td>
										<td>
											<button 
												className="btn btn-outline-primary"
												onClick={() => {deleteItemCobroParcializado(cb,index);}}>
												<FontAwesomeIcon icon={faTrashAlt} />
											</button>
										</td>
										</tr>
									);
									})
								: null}
							</tbody>
						</table>
					</div>
				
				</Modal.Body>
				
				<Modal.Footer>

									
					<div className="input-group input-group-lg" >
						<div className="input-group-prepend">
							<span className="input-group-text" id="inputGroup-sizing-sm">
								*Para factura electronica ingrese CUIT (factura A o B) o DNI (factura B o C):
							</span>
						</div>
						<input
							aria-describedby="inputGroup-sizing-sm"
							aria-label="Small"
							type="text"
							className="form-control"
							id="inputCuit"
							name="cuit"
							value={cuit}
							onChange={(e) => setCuit(e.target.value)}
						/>
					
						<Button variant="outline-warning" onClick={handleClose} size="lg">
							<FontAwesomeIcon icon={faArrowLeft} /> Volver
						</Button>
						<Button variant="outline-success" onClick={finalizarVenta} size="lg">
							<FontAwesomeIcon icon={faCheck} />	Realizar venta
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
};

export default withRouter(FinalizarVentaModal);
