import React, { Fragment, useEffect, useState } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSitemap, faSearch } from '@fortawesome/free-solid-svg-icons'

// components
import BotonBack from '../Utiles/BotonBack';
import ItemCargo from './ItemCargo';
import Subtitulo from '../Utiles/Subtitulo';

// redux
import { useSelector, useDispatch } from 'react-redux'

// actions
import { GetCargos, DeleteCargo } from '../../Actions/CargoAction';

// route
import { withRouter } from 'react-router-dom';

// services
import { getAllCargos } from '../../Services/cargoService';

const Cargos = ({ history }) => {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    let rol = token.authorities[0];

    const [isLoad, setIsLoad] = useState(true);
    const cargos = useSelector(state => state.cargos.cargos);
    const dispatch = useDispatch();
    const eliminarCargo = (id) => dispatch(DeleteCargo(id));

    useEffect(() => {
        if (rol !== 'ROLE_ADMIN') history.goBack();
        if (isLoad) {
            getAllCargos()
                .then(res => {
                    dispatch(GetCargos(res));
                    setIsLoad(false);
                })
                .catch(err => console.log('error'));
        }else{
            setIsLoad(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faSitemap}
                        title={'Cargos'} />
                </div>

                <div className="bg-gris p-3">
                    <div className="d-flex bd-highlight">
                        <div className="p-2 bd-highlight">
                            <button className="btn btn-outline-success btn-lg"
                                onClick={() => { history.push('/add-cargo') }}>
                                <FontAwesomeIcon icon={faPlus} /> Crear
                            </button>
                        </div>
                        <div className="p-2 flex-grow-1 bd-highlight">
                            <div className="input-group-prepend input-group-lg">
                               
                                <input type="text"
                                    className="form-control form-lg"
                                    id="inputNombre"
                                    name="precio"
                                    placeholder="Buscar"
                                />
                                 <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faSearch} />
                                </span>
                            </div>
                        </div>
                    </div>

                    <table className="table table-striped mt-3 text-center">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Codigo</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>

                            {cargos.map((cargo) => (
                                <ItemCargo
                                key={parseInt(cargo.idCargo)}
                                    cargo={cargo}
                                    eliminarCargo={eliminarCargo}
                                    setIsLoad={setIsLoad}/>
                            ))}

                        </tbody>
                    </table>

                </div>

            </div>
        </Fragment>
    );
}

export default withRouter(Cargos);