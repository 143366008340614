import React, { Fragment, useEffect, useState } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLayerGroup, faPlus } from '@fortawesome/free-solid-svg-icons'

// components
import BotonBack from '../Utiles/BotonBack';
import ItemDepartamento from './ItemDepartamento';
import Subtitulo from '../Utiles/Subtitulo';

// redux
import { useSelector, useDispatch } from 'react-redux'

// actions
import { mostrarDepartamentos, DeleteDepartamento } from '../../Actions/DepartamentoAction';

// route
import { withRouter } from 'react-router-dom';

// services
import { getAllDepartamentos } from '../../Services/departamentoService';

const Departamentos = ({ history }) => {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    let rol = token.authorities[0];

    const [isLoad, setIsLoad] = useState(true);
    const departamentos = useSelector(state => state.departamentos.departamentos);
    const dispatch = useDispatch();
    const eliminarDepartamento = (id) => dispatch(DeleteDepartamento(id));
    //const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (rol !== 'ROLE_ADMIN') history.goBack();
        /*getAllDepartamentos()
            .then(res => dispatch(mostrarDepartamentos(res)))
            .catch(err => console.log('error'));*/

        if (isLoad) {
            getAllDepartamentos()
                .then(res => {
                    dispatch(mostrarDepartamentos(res));
                    setIsLoad(false);
                })
                .catch(err => console.log('error'));
        }else{
            setIsLoad(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faLayerGroup}
                        title={'Departamentos'} />
                </div>

                <div className="bg-gris p-3">
                    <button className="btn btn-outline-success btn-lg ml-2"
                        onClick={() => { history.push('/add-departamento') }}>
                        <FontAwesomeIcon icon={faPlus} /> Crear
                    </button>

                    <table className="table table-striped mt-3 text-center">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">IVA</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>

                            {departamentos.map((departamento) => (
                                <ItemDepartamento
                                    key={parseInt(departamento.idDepartamento)}
                                    departamento={departamento}
                                    eliminarDepartamento={eliminarDepartamento}
                                    setIsLoad={setIsLoad}/>
                            ))}

                        </tbody>
                    </table>

                </div>

            </div>
        </Fragment>
    );
}

export default withRouter(Departamentos);