import React, { Fragment, useEffect, useState } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faBuilding } from '@fortawesome/free-solid-svg-icons'

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo';
import ItemSucursal from './ItemSucursal';

// redux
import { useDispatch } from 'react-redux'

// actions
import { GetSucursales, DeleteSucursal } from '../../Actions/SucursalAction';

// route
import { withRouter } from 'react-router-dom';

// services
import { getAllSucursales  } from '../../Services/sucursalService';


const Sucursales = ({ history }) => {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    let rol = token.authorities[0];

    const [isLoad, setIsLoad] = useState(true);
    const [sucursales, setSucursales] = useState([]);
    const dispatch = useDispatch();
    const deleteSucursal = (id) => dispatch(DeleteSucursal(id));
    
    useEffect(() => {
        if (rol !== 'ROLE_ADMIN') history.goBack();
        if (isLoad) {
            getAllSucursales()
                .then(res => {
                    dispatch(GetSucursales(res));
                    setSucursales(res.data);
                    setIsLoad(false);
                })
                .catch(err => console.log('error'));
        }else{
            setIsLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faBuilding}
                        title={'Sucursales'} />
                </div>

                <div className="bg-gris p-3">
                    <button className="btn btn-outline-success btn-lg ml-2"
                        onClick={() => { history.push('/add-sucursal') }}>
                        <FontAwesomeIcon icon={faPlus} /> Crear
                    </button>

                    <table className="table table-striped mt-3 text-center">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Direccion</th>
                                <th scope="col">Telefono</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>

                            {sucursales.map((sucursal) => (
                                <ItemSucursal
                                    key={parseInt(sucursal.idSucursal)}
                                    sucursal={sucursal}
                                    deleteSucursal={deleteSucursal}
                                    setIsLoad={setIsLoad}/>
                            ))}

                        </tbody>
                    </table>

                </div>

            </div>
        </Fragment>
    );
}

export default withRouter(Sucursales);