import React from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'

// routes
import { withRouter } from 'react-router-dom';

import ModalDetallePresupuesto from './ModalDetallePresupuesto/ModalDetallePresupuesto';
import ModalEnviarPresupuesto from './ModalEnviarPresupuesto/ModalEnviarPresupuesto';

//services
import { getPdfPresupuesto } from './../../Services/reporteService';

// Sweet alert
import Swal from 'sweetalert2';


const ItemPresupuesto = ({ presupuesto }) => {
   
    const handleImprimir = async (presupuesto) => {       
        try {
            const pdf = await getPdfPresupuesto(presupuesto);
            const contenido = encodeURI(pdf);
            let pdfWindow = window.open("");
            pdfWindow.document.write(
                `<iframe width='100%' height='100%' margin='0px' padding='0px' src='data:application/pdf;base64,${contenido}'>
                </iframe>`
            );            
        } catch (error) {
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Hubo un error vuelve a intentar!'
            });
        }
    }
    
    return (
        <tr>
            <th scope="row">{presupuesto.idPresupuesto}</th>
            <td>${(presupuesto.total).toFixed(2)}</td>
            <td>{(presupuesto.fechaCreacion).split('T')[0]}</td>
            <td>{(presupuesto.fechaVencimiento).split('T')[0]}</td>
            <td>{presupuesto.sucursal.nombre}</td>
            <td>{presupuesto.cliente.idCliente.persona.apellido}, {presupuesto.cliente.idCliente.persona.nombre}</td>
            <td>
                <ModalDetallePresupuesto item={presupuesto}/>

                <ModalEnviarPresupuesto presupuesto={presupuesto} />

                <button className="btn text-info"
                    onClick={() => handleImprimir(presupuesto)}>
                    <FontAwesomeIcon icon={faPrint} /> Imprimir
                </button>
            </td>
        </tr>
    );
}

export default withRouter(ItemPresupuesto);