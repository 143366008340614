import axios from 'axios';
import {url} from './global';


export const getAllTarjetas = async () => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/tarjetas`,{headers});
    return res;
}

export const getByIdTarjetas = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/tarjetas/${id}`,{headers});
    return res;
}

export const editByIdTarjetas = async (tarjeta) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/tarjetas/${tarjeta.idTarjeta}`, tarjeta,{headers});
    return res;
}

export const deleteByIdTarjetas = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.delete(`${url}/tarjetas/${id}`,{headers});
    return res;
}

export const saveTarjetas = async (tarjeta) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/tarjetas`,tarjeta,{headers});
    return res;
}
