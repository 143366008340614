const {REACT_APP_SERVER} = process.env;

// local
// export const url = 'http://localhost:8000/api';
// export const url_login = 'http://localhost:8000';
// export const url_node = 'http://localhost:3001/api/v1';

// produccion
export const url = `${REACT_APP_SERVER}:8000/api`;
export const url_login = `${REACT_APP_SERVER}:8000`;
export const url_node = `${REACT_APP_SERVER}:3001/api/v1`;


