import React, { Fragment, useState } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList } from '@fortawesome/free-solid-svg-icons'

// router
import { withRouter } from 'react-router-dom';

// models
import ItemHistorialStock from './ItemHistorialStock';

// services
import { getHistorialStockByArticulo } from '../../../Services/stockSucursalService';


const ModalHistorialStock= ({item}) => {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    let sucursal = token.empleado.sucursal.idSucursal;
    const [historial, setHistorial] = useState([]);

    const getHistorialStock = () => {
        getHistorialStockByArticulo(sucursal, item.articulo.idArticulo)
            .then(async res => {
                await setHistorial(res.data.content);
            })
            .catch(res => {
                console.log(res);
            })
    }
 
    return (
        <Fragment>

            <button type="button" className="btn text-info"
                data-toggle="modal" data-target={"#modalHistorialStock"+item.articulo.idArticulo}
                onClick={() => getHistorialStock()}>
                <FontAwesomeIcon icon={faList}/> Historial
            </button>

            <div className="modal fade" id={"modalHistorialStock"+item.articulo.idArticulo} tabIndex="-1" role="dialog" aria-labelledby="modalHistorialStockTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <h5 className="modal-title" id="modalHistorialStockTitle"><b>Historial Stock Articulo</b></h5>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <p className="col-md"><b>Articulo:</b></p>
                                <p className="col-md"><b>Stock:</b></p>
                            </div>
                            <div className="row">
                                <p className="col-md">{item.articulo.nombre}</p>
                                <p className="col-md">{item.cantidad}</p>
                            </div>
                            <table className="table table-striped mt-3 text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Variacion de Cantidad</th>
                                        <th scope="col">Fecha Modificacion</th>
                                        <th scope="col">Motivo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {historial.map((stock) => (
                                        <ItemHistorialStock
                                            key={parseInt(item.id)}
                                            stock={stock}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    );

}

export default withRouter(ModalHistorialStock);