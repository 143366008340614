import {
    GET_TARJETAS, GET_TARJETA, DELETE_TARJETA, ADD_TARJETA, EDIT_TARJETA
} from '../Actions/Types';

// cada reducer tiene su propio state
const initialState = {
    tarjetas: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_TARJETAS:
            return {
                ...state,
                tarjetas: action.payload
            }
        case GET_TARJETA:
            return {
                ...state,
                tarjeta: action.payload
            }
        case ADD_TARJETA:
            return {
                ...state,
                tarjetas: [...state.tarjetas, action.payload]
            }
        case EDIT_TARJETA:
            return {
                ...state,
                tarjetas: (state.tarjetas.map(
                    tarjeta => tarjeta.idTarjeta === action.payload.idTarjeta
                        ? (tarjeta = action.payload)
                        : tarjeta
                ))
            }
        case DELETE_TARJETA:
            return {
                ...state,
                tarjetas: state.tarjetas.filter(tarjeta => tarjeta.idTarjeta !== action.payload)
            }
        default:
            return state;
    }
}
