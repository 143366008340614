import React, { Fragment, useEffect, useState } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import { getAllArticulosPage } from '../../../Services/articuloService';
import ItemArticuloModalCompra from './ItemArticuloModalCompra';
import { withRouter } from 'react-router-dom';

const ModalArticulosCompra = ({ setCodigoArticulo }) => {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));

    const [articulos, setArticulos] = useState([]);
    const [isLoad, setIsLoad] = useState(true);

    // pagination
    const [page, setPage] = useState(0);
    const [totalRegister, setTotalRegister] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [query, setQuery] = useState("");

    useEffect(() => {

        let data = {
            query,
            sucursal: token.empleado.sucursal.idSucursal
        }

        getAllArticulosPage(page, data)
            .then(res => {                
                setArticulos(res.data.content);
                setTotalRegister(res.data.totalElements);
                setTotalPage(res.data.totalPages);
                setIsLoad(false);
            })
            .catch(error => {
                console.log(error.response)
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);

    const onChangeArticulo = e => {
        //e.preventDefault();
        let valor = e.target.value;

        let data = { query: valor}

        setQuery(e.target.value);
        
        if (e.key === 'Enter') {

            getAllArticulosPage(page, data)
                .then(res => {
                    setArticulos(res.data.content);
                    setPage(0);
                    setTotalRegister(res.data.totalElements);
                    setTotalPage(res.data.totalPages);
                    setIsLoad(false);
                })
                .catch(error => {
                    console.log(error.response)
                });
        }

    }

    const nextPage = () => {

        if (page === totalPage - 1) {
            Swal.fire({
                toast: true,
                position: 'top',
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000
            })
            return;
        }

        let data = {query}
        
        getAllArticulosPage(page + 1, data)
            .then(res => {
                setPage(page + 1);
                setTotalPage(res.data.totalPages);
                setTotalRegister(res.data.totalElements);
                setArticulos(res.data.content);
                setIsLoad(false);
            })
            .catch(error => {
                console.log(error);
            });

    }

    const previousPage = () => {
        if (page === 0) {
            Swal.fire({
                toast: true,
                position: 'top',
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000
            })
            return;
        }

        let data = {query}
        
        getAllArticulosPage(page - 1, data)
            .then(res => {
                setPage(page - 1);
                setTotalPage(res.data.totalPages);
                setTotalRegister(res.data.totalElements);
                setArticulos(res.data.content);
                setIsLoad(false);
            })
            .catch(error => {
                console.log(error);
            });


    }

    return (
        <Fragment>

            <button type="button" className="btn btn-outline-info btn-lg"
                data-toggle="modal" data-target="#exampleModalScrollable"
                style={{ width: 130, height: 45 }}>
                <FontAwesomeIcon icon={faSearch} />
            </button>

            <div className="modal fade w-100" id="exampleModalScrollable" tabIndex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalScrollableTitle">Articulos</h5>
                        </div>
                        <div className="modal-body">
                            <div className="input-group">
                                <input type="text" className="form-control"
                                    placeholder="Buscar..."
                                    onKeyPress={(e) => { onChangeArticulo(e) }} />
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="addon-wrapping"><FontAwesomeIcon icon={faSearch} /></span>
                                </div>
                            </div>
                            {/* Inicio de paginador */}
                            <div className="d-flex flex-row bd-highlight">
                                <div className="p-2 bd-highlight">
                                    <button className="btn btn-outline-info"
                                            onClick={() => previousPage()} 
                                            aria-label="Previous"
                                            type="button"
                                    >
                                        <span aria-hidden="true">&laquo; Anterior</span>
                                    </button>
                                </div>
                                <div className="p-2 bd-highlight mt-1">
                                    Registros: {totalRegister} | Pagina actual: {page + 1} | Total páginas : {totalPage}
                                </div>
                                <div className="p-2 bd-highlight">
                                    <button className="btn btn-outline-info"
                                            onClick={() => nextPage()} 
                                            aria-label="Next"
                                            type="button"
                                    >
                                        <span aria-hidden="true">Siguiente &raquo;</span>
                                    </button>
                                </div>
                            </div>
                            <table className="table mt-3 text-center">
                                <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Marca</th>
                                        <th scope="col">Seleccionar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {articulos.map((articulo) => (
                                        <ItemArticuloModalCompra
                                            key={parseInt(articulo.idArticulo)}
                                            articulo={articulo}
                                            setCodigoArticulo={setCodigoArticulo} />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    );

}

export default withRouter(ModalArticulosCompra);