import axios from 'axios';
import {url} from './global';

export const getAllListasPrecios = async () => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/listas-precios`,{headers});
    return res;
}

export const getByIdListasPrecios = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/listas-precios/${id}`,{headers});
    return res;
}

export const editByIdListasPrecios = async (listaPrecio) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/listas-precios/${listaPrecio.idListaPrecio}`, listaPrecio,{headers});
    return res;
}

export const deleteByIdListasPrecios = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.delete(`${url}/listas-precios/${id}`,{headers});
    return res;
}

export const saveListasPrecios = async (listaPrecio) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/listas-precios`,listaPrecio,{headers});
    return res;
}
